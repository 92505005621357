import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import Breadcrumbs from "../../components/Breadcrumbs";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import { createEmployee } from "../../services/createEmployee";
import { getLoggedInEmployee } from "../../services/getLoggedInEmployee";
import RolesListBox from "./RolesListBox";

const ROLES = ["User", "Admin"];

function CreateEmployee() {
    const [loggedInEmployee, setLoggedInEmployee] = useState(null);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [form, setForm] = useState({
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        role: 0,
    });

    useEffect(() => {
        getCurrentEmployee();
    }, []);

    const initializeForm = () => {
        setForm({
            first_name: "",
            last_name: "",
            email: "",
            password: "",
            role: 0,
        });
    };

    const getCurrentEmployee = async () => {
        const employee = await getLoggedInEmployee();
        if (employee.success) {
            setLoggedInEmployee(employee.data.employeeResponse);
            console.log(employee.data.employeeResponse);
        }
    };

    const onHandleChangeRole = (role) => {
        setForm({ ...form, role: role });
    };

    async function create() {
        if (
            form.first_name !== "" &&
            form.last_name !== "" &&
            form.email !== "" &&
            form.password !== ""
        ) {
            const employee = await createEmployee(form);
            console.log(employee);
            if (employee.data.success) {
                toast.success(employee.data.message);
                initializeForm();
            } else {
                toast.error(employee.data.message);
            }
        } else {
            toast.error(
                "First name, Last name, Email, and password can't be empty!"
            );
        }
    }

    return (
        <section>
            <Toaster />
            <div className="flex h-screen overflow-hidden">
                {/* Sidebar */}
                <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                {/* Content area */}
                <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                    {/*  Site header */}
                    <Header
                        sidebarOpen={sidebarOpen}
                        setSidebarOpen={setSidebarOpen}
                    />

                    <main>
                        <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                            <div className="flex flex-col items-start max-w-2xl">
                                <Breadcrumbs
                                    home="/employees"
                                    pages={[
                                        {
                                            name: "Create employee",
                                            href: `/employees/create`,
                                            current: true,
                                        },
                                    ]}
                                />

                                {/* Page header */}
                                <div className="sm:flex sm:justify-between sm:items-center mb-8 w-full mt-12">
                                    {/* Left: Title */}
                                    <div className="mb-4 sm:mb-0">
                                        <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">
                                            Create employee
                                        </h1>
                                    </div>
                                </div>

                                <div className="bg-white shadow-lg rounded-sm border border-slate-200 relative w-full">
                                    <header className="px-5 py-4 border-b border-slate-200">
                                        <h2 className="font-semibold text-slate-800">
                                            Details
                                        </h2>
                                    </header>

                                    <div className="space-y-5 px-5 py-4">
                                        <div>
                                            <label
                                                htmlFor="email"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                First Name
                                            </label>
                                            <div className="mt-1">
                                                <input
                                                    onChange={(e) =>
                                                        setForm({
                                                            ...form,
                                                            first_name:
                                                                e.target.value,
                                                        })
                                                    }
                                                    type="text"
                                                    value={form.first_name}
                                                    className="shadow-sm border border-slate-200 rounded-sm p-1 px-2 w-full max-w-sm focus:ring-0 focus:outline-none"
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <label
                                                htmlFor="email"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                Last Name
                                            </label>
                                            <div className="mt-1">
                                                <input
                                                    onChange={(e) =>
                                                        setForm({
                                                            ...form,
                                                            last_name:
                                                                e.target.value,
                                                        })
                                                    }
                                                    type="text"
                                                    value={form.last_name}
                                                    className="shadow-sm border border-slate-200 rounded-sm p-1 px-2 w-full max-w-sm focus:ring-0 focus:outline-none"
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <label
                                                htmlFor="email"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                Email
                                            </label>
                                            <div className="mt-1">
                                                <input
                                                    onChange={(e) =>
                                                        setForm({
                                                            ...form,
                                                            email: e.target.value,
                                                        })
                                                    }
                                                    type="text"
                                                    value={form.email}
                                                    className="shadow-sm border border-slate-200 rounded-sm p-1 px-2 w-full max-w-sm focus:ring-0 focus:outline-none"
                                                />
                                            </div>
                                        </div>
                                        {loggedInEmployee &&
                                            loggedInEmployee.role == 1 && (
                                                <div className="mt-1">
                                                    <RolesListBox
                                                        roles={ROLES}
                                                        role={form.role}
                                                        onChange={(role) =>
                                                            onHandleChangeRole(role)
                                                        }
                                                    />
                                                </div>
                                            )}
                                        <div>
                                            <label
                                                htmlFor="email"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                Password
                                            </label>
                                            <div className="mt-1">
                                                <input
                                                    onChange={(e) =>
                                                        setForm({
                                                            ...form,
                                                            password: e.target.value,
                                                        })
                                                    }
                                                    type="password"
                                                    value={form.password}
                                                    className="shadow-sm border border-slate-200 rounded-sm p-1 px-2 w-full max-w-sm focus:ring-0 focus:outline-none"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="w-full flex justify-end">
                                    <button
                                        onClick={() => create()}
                                        type="submit"
                                        className="z-50 mt-4 ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-sm text-white bg-teal-500 hover:bg-teal-600 focus:outline-none focus:ring-0"
                                    >
                                        Create
                                    </button>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </section>
    );
}
export default CreateEmployee;
