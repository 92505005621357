import { ChevronRightIcon, HomeIcon } from '@heroicons/react/24/solid'
import { useNavigate } from 'react-router-dom';

export default function Breadcrumbs(props) {

    const {home, pages} = props;
    const navigate = useNavigate();

    return (
        <nav className="flex items-center" aria-label="Breadcrumb">
            <div role="list" className="flex items-center space-x-4">
                <div>
                    <div>
                        <button onClick={() => navigate(home)} className="text-gray-400 hover:text-gray-500 cursor-pointer mt-1">
                            <HomeIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
                            <span className="sr-only">Home</span>
                        </button>
                    </div>
                </div>
                {pages.map((page) => (
                    <div key={page.name}>
                        <div className="flex items-center">
                            <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                            <button
                                onClick={() => navigate(page.href)}
                                className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700 cursor-pointer"
                                aria-current={page.current ? 'page' : undefined}
                            >
                                {page.name}
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        </nav>
    )
}