/* This example requires Tailwind CSS v2.0+ */
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useRef } from "react";

/* const LINK_NAME = "name";
const LINK_URL = "url"; */

export default function EditSubscriptionChecklistItem({
  open,
  setOpen,
  form,
  setForm,
  saveServiceItem,
  employees,
  setEmployees,
}) {
  /* const [newLink, setNewLink] = useState({ [LINK_NAME]: "", [LINK_URL]: "" }); */

  /* const handleChange = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    setNewLink({ ...newLink, [name]: value });
  };

  const addLink = () => {
    if (newLink[LINK_NAME] !== "" && isValidLink(newLink[LINK_URL])) {
      setForm({ ...form, links: [...form.links, newLink] });
      setNewLink({ [LINK_NAME]: "", [LINK_URL]: "" });
    } else {
      toast.error("Adding empty or invalid links is not allowed.");
    }
  };

  const deleteLink = (name) => {
    const newLinks = form.links.filter((link) => link[LINK_NAME] !== name);
    setForm({ ...form, links: newLinks });
  }; */

  const cancelButtonRef = useRef(null);

  useEffect(() => {
    if (form.search === "") {
      const arr = [...employees];
      // eslint-disable-next-line
      arr.map((service) => {
        service.is_showing = true;
      });
      setEmployees(arr);
    }

    const arr = [...employees];
    // eslint-disable-next-line
    arr.map((employee) => {
      if (
        employee.firstName
          .toLowerCase()
          .includes(form.search.toLocaleLowerCase()) ||
        employee.lastName
          .toLowerCase()
          .includes(form.search.toLocaleLowerCase())
      ) {
        employee.is_showing = true;
      } else {
        employee.is_showing = false;
      }
    });
    setEmployees(arr);
    // eslint-disable-next-line
  }, [form.search]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-30"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg w-full sm:p-6">
                <div className="text-center">
                  <div className="w-full flex justify-between">
                    <Dialog.Title
                      as="h3"
                      className="text-left w-full text-lg leading-6 font-medium text-gray-900"
                    >
                      Edit checklist item
                    </Dialog.Title>
                    <button onClick={() => setOpen(false)}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                  </div>
                  <div className="flex flex-col items-start justify-start w-full mt-4">
                    <label
                      htmlFor="service"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Service
                    </label>
                    <div className="w-full mt-1">
                      <input
                        onChange={(e) =>
                          setForm({ ...form, description: e.target.value })
                        }
                        type="text"
                        value={form.description}
                        className="shadow-sm border border-slate-200 rounded-sm p-1 px-2 w-full focus:ring-0 focus:outline-none"
                      />
                    </div>
                  </div>

                  <div className="flex flex-col items-start justify-start w-full mt-4">
                    <label
                      htmlFor="notes"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Notes
                    </label>
                    <div className="w-full mt-1">
                      <textarea
                        onChange={(e) =>
                          setForm({ ...form, notes: e.target.value })
                        }
                        type="text"
                        rows={3}
                        value={form.notes}
                        className="shadow-sm border border-slate-200 rounded-sm p-1 px-2 w-full focus:ring-0 focus:outline-none"
                      />
                    </div>
                  </div>
                  {/* <div className="flex flex-col items-start justify-start w-full mt-4">
                    <label
                      htmlFor="links"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Links
                    </label>
                    <div className="w-full mt-1">
                      <textarea
                        onChange={(e) =>
                          setForm({ ...form, links: e.target.value })
                        }
                        type="text"
                        rows={3}
                        value={form.links}
                        className="shadow-sm border border-slate-200 rounded-sm p-1 px-2 w-full focus:ring-0 focus:outline-none"
                      />
                    </div>
                    <small>
                      Links should be separated by commas without spaces.
                    </small>
                  </div> */}
                  {/* <LinkForm
                    newLink={newLink}
                    addLink={addLink}
                    handleChange={handleChange}
                    deleteLink={deleteLink}
                    LINK_NAME={LINK_NAME}
                    LINK_URL={LINK_URL}
                    inputLinksJSON={form.links ? form.links : []}
                    divWidth="w-full"
                    title="Links"
                  /> */}
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-teal-500 text-base font-medium text-white hover:bg-teal-600 focus:outline-none focus:ring-0 sm:col-start-2 sm:text-sm"
                    onClick={() => saveServiceItem()}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-0 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
