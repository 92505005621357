import React from "react";
function DashboardCard(props) {
  return (
    <div
      className={`flex flex-col col-span-full sm:col-span-6 xl:col-span-4 
    ${props?.backgroundColor} shadow-lg rounded-md border border-slate-200`}
    >
      <div className="px-5 pt-5 pb-5">
        <header className="flex justify-between items-start mb-4">
          {/* Icon */}
          {/* <img src={'./logo_black.png'} width="80" height="50" alt="Icon 01" /> */}
          {/* Menu button */}
          {/* <EditMenu align="right" className="relative inline-flex">
            <li>
              <Link className="font-medium text-sm text-slate-600 hover:text-slate-800 flex py-1 px-3" to="#0">Option 1</Link>
            </li>
            <li>
              <Link className="font-medium text-sm text-slate-600 hover:text-slate-800 flex py-1 px-3" to="#0">Option 2</Link>
            </li>
            <li>
              <Link className="font-medium text-sm text-rose-500 hover:text-rose-600 flex py-1 px-3" to="#0">Remove</Link>
            </li>
          </EditMenu> */}
        </header>
        <h2 className={`text-lg font-semibold ${props.titleColor} mb-2`}>
          {props.title}
        </h2>
        <div
          className={`text-xs font-semibold ${props.subTitleColor} uppercase mb-1`}
        >
          {props.subTitle}
        </div>
        <div className="flex items-start">
          <div className={`text-3xl font-bold ${props.totalColor} mr-2`}>
            {props.total}
          </div>
          <div className="text-sm font-semibold text-white px-1.5 bg-emerald-500 rounded-full"></div>
        </div>
      </div>
      <div className="grow">
        {props.showChart && props.chart}
      </div>
    </div>
  );
}

export default DashboardCard;
