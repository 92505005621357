import { useRef } from "react";
import { resetUserPassword } from "../../services/resetPasswords";
import { useParams, useNavigate } from "react-router-dom";

export default function ResetPassword() {
  const emailInput = useRef(null);
  const passwordInput = useRef(null);
  const confirmPasswordInput = useRef(null);
  const params = useParams();
  const navigate = useNavigate();

  console.log(params.id);

  async function createNewPassword() {
    if (passwordInput.current.value !== confirmPasswordInput.current.value) {
      alert("Passwords do not match.");
      return;
    }
    const res = await resetUserPassword({
      email: emailInput.current.value,
      newPassword: passwordInput.current.value,
      confirmPassword: confirmPasswordInput.current.value,
      userId: params.id,
    });

    if (res.success) {
      alert(
        "Password reset successful. You will be redirected to the login page."
      );
      navigate("/login");
    } else {
      alert("Something went wrong. Please contact us for assistance.");
    }
    console.log(res);
  }
  return (
    <section>
      <div className="flex h-screen overflow-hidden">
        {/* Sidebar */}
        {/* Content area */}
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          <header className="sticky top-0 bg-white border-b border-slate-200 z-30">
            <div className="px-4">
              <div className="flex items-center justify-between h-16 ">
                <img src="./logo_white.png" alt="logo" className="h-10" />
              </div>
            </div>
          </header>

          <main>
            <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
              <div className="w-full max-w-md space-y-8">
                <div>
                  <div className="w-full flex justify-center"></div>
                  <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-black">
                    Create new password
                  </h2>
                  <p className="mt-6 text-center text-md font-bold tracking-tight text-black">
                    Enter your email address and new password to reset your
                    password.
                  </p>
                  <br />
                </div>
                <div className="mt-8 space-y-6">
                  <input type="hidden" name="remember" defaultValue="true" />
                  <div className="-space-y-px rounded-md shadow-sm">
                    <div>
                      <label htmlFor="email-address" className="sr-only">
                        Email address
                      </label>
                      <input
                        id="email-address"
                        name="email"
                        type="email"
                        ref={emailInput}
                        autoComplete="email"
                        required
                        className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-teal-500 focus:border-teal-500 focus:z-10 sm:text-sm"
                        placeholder="Email address"
                      />
                    </div>
                    <div>
                      <label htmlFor="password" className="sr-only">
                        Password
                      </label>
                      <input
                        id="password"
                        name="password"
                        type="password"
                        ref={passwordInput}
                        autoComplete="current-password"
                        required
                        className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-teal-500 focus:border-teal-500 focus:z-10 sm:text-sm"
                        placeholder="Password"
                      />
                    </div>
                    <div>
                      <label htmlFor="confirmPassword" className="sr-only">
                        Confirm Password
                      </label>
                      <input
                        id="confirmPassword"
                        name="confirmPassword"
                        type="password"
                        ref={confirmPasswordInput}
                        autoComplete="current-password"
                        required
                        className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-teal-500 focus:border-teal-500 focus:z-10 sm:text-sm"
                        placeholder="ConfirmPassword"
                      />
                    </div>
                  </div>

                  <div>
                    <button
                      onClick={() => createNewPassword()}
                      className="group relative flex w-full justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium text-white bg-teal-500 hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
                    >
                      <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          className="h-5 w-5 text-black group-hover:text-green-400"
                        >
                          <path
                            fillRule="evenodd"
                            d="M12 1.5a5.25 5.25 0 00-5.25 5.25v3a3 3 0 00-3 3v6.75a3 3 0 003 3h10.5a3 3 0 003-3v-6.75a3 3 0 00-3-3v-3c0-2.9-2.35-5.25-5.25-5.25zm3.75 8.25v-3a3.75 3.75 0 10-7.5 0v3h7.5z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </span>
                      Create new Password
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </section>
  );
}
