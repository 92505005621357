import React, { useState } from "react";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import Breadcrumbs from "../../components/Breadcrumbs";
import { createState } from "../../services/createState";

function CreateStateForm() {

    const navigate = useNavigate();
    const params = useParams();
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const [form, setForm] = useState({
        id: params.id,
        name: '',
    })

    async function create() {
        if (form.name) {
            const task = await createState(form);
            console.log(task);
            alert(task.data.message);
            if (task.data.success) {
                navigate(`/services/${params.id}`);
            }
        } else {
            alert("Status can't be empty!")
        }
    }

    useEffect(() => {
        console.log(form);
    }, [form])

    return (
        <section>
            <div className="flex h-screen overflow-hidden">
                {/* Sidebar */}
                <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                {/* Content area */}
                <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                    {/*  Site header */}
                    <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

                    <main>
                        <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                            <div className="flex flex-col items-start max-w-2xl">

                                <Breadcrumbs home='/services' pages={[
                                    { name: 'Service', href: `/services/${params.id}`, current: true },
                                    { name: 'Create task', href: `/services/${params.id}/task/create`, current: true },
                                ]} />

                                {/* Page header */}
                                <div className="sm:flex sm:justify-between sm:items-center mb-8 w-full mt-12">
                                    {/* Left: Title */}
                                    <div className="mb-4 sm:mb-0">
                                        <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">
                                            Create Status
                                        </h1>
                                    </div>

                                </div>

                                <div className="bg-white shadow-lg rounded-sm border border-slate-200 relative w-full">
                                    <header className="px-5 py-4 border-b border-slate-200">
                                        <h2 className="font-semibold text-slate-800">
                                            Details
                                        </h2>
                                    </header>

                                    <div className="space-y-5 px-5 py-4">
                                        <div>
                                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                                Status
                                            </label>
                                            <div className="mt-1">
                                                <input
                                                    onChange={(e) => setForm({ ...form, name: e.target.value })}
                                                    type="text"
                                                    value={form.name}
                                                    className="shadow-sm border border-slate-200 rounded-sm p-1 px-2 w-full max-w-sm focus:ring-0 focus:outline-none"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="w-full flex justify-end">
                                    <button
                                        onClick={() => create()}
                                        type="submit"
                                        className="z-50 mt-4 ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-sm text-white bg-teal-500 hover:bg-teal-600 focus:outline-none focus:ring-0"
                                    >
                                        Create
                                    </button>
                                </div>

                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </section>
    );
}
export default CreateStateForm;