import { PlusIcon } from "@heroicons/react/24/solid";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { Toaster, toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import { createChecklistItem } from "../../services/createChecklistItem";
import { deleteChecklistItem } from "../../services/deleteChecklistItem";
import { GetChecklist } from "../../services/getChecklist";
import { getServiceTaskByJob } from "../../services/serviceTasks/getServiceTaskByJob";
import { updateTaskV2 } from "../../services/serviceTasks/updateTaskV2";
import { updateChecklistItem } from "../../services/updateChecklistItem";
import ChecklistFormSection from "../TaskForm/ChecklistFormSection";
import AddItemToJobModal from "./AddItemToJobModal";
import { PencilIcon } from "@heroicons/react/24/solid";
import DeleteModal from "../../utils/DeleteModal";

function EditTask() {
    const params = useParams();

    const [task, setTask] = useState({ name: "" });
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [checklist, setCheckList] = useState([]);
    const [inputCheckList, setInputCheckList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openAddItem, setOpenAddItem] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [removeChecklistItemId, setRemoveChecklistItemId] = useState(null);
    /*  const [serviceStatuses, setServiceStatuses] = useState([]); */

    /* const getServiceStatuses = async () => {
    const response = await getStates(params.id);
    setServiceStatuses(response.data.serviceStatuses);
  }; */

    /* const onHandleChangeStatus = (newStatus) => {
    //status 2 is complete
    setTask({ ...task, serviceState: newStatus });
    console.log(newStatus);
  }; */

    useEffect(() => {
        async function runOnLoad() {
            const task = await getServiceTaskByJob({
                jobId: params.jobId,
                taskId: params.taskId,
            });
            console.log(task);
            retrieveChecklist();
            setTask({
                name: task.data.name,
            });
            setLoading(false);
        }
        runOnLoad();
        /* getServiceStatuses(); */
    }, []);

    const retrieveChecklist = async () => {
        const checklist = await GetChecklist(params.taskId);
        if (!checklist.success) alert(checklist.message);
        const arr = [];
        checklist.data.map((value) => {
            arr.push({
                id: value.id,
                description: value.description,
                links: value.links ? JSON.parse(value.links) : [],
                isChecked: false,
                editing: false,
                updated: false,
            });
        });
        setCheckList(arr);
    };

    const onClickRemoveChecklistItem = (checklist_id) => {
        if (checklist.length === 1) {
            toast.error("At least one checklist item is required.");
            return;
        }
        setRemoveChecklistItemId(checklist_id);
        setIsDeleteModalOpen(true);
    };

    const removeChecklistItem = async () => {
        if (checklist.length === 1) {
            toast.error("At least one checklist item is required.");
            return;
        }
        const res = await deleteChecklistItem(params.taskId, removeChecklistItemId);
        if (res.success) {
            retrieveChecklist();
        } else {
            toast.error("Youn don't have permission to do this.");
        }
        setIsDeleteModalOpen(false);
    };

    const saveDetails = async () => {
        if (task.name === "") {
            toast.error("Name is required.");
            return;
        }
        const res = await updateTaskV2({
            jobId: params.jobId,
            taskId: params.taskId,
            name: task.name,
        });
        console.log(res);
        if (res.data.success) {
            setTask({ name: task.name });
            toast.success(res.data.message);
            setIsEditing(false);
            return;
        }
        toast.error(res.data.error);
    };

    const onClickAddItem = () => {
        /*  if (inputCheckList.length === 0) {
      setInputCheckList([...inputCheckList, { description: "" }]);
    } */
        setInputCheckList([]);
        setOpenAddItem(true);
    };

    const toggleEdit = () => {
        if (task.name === "") {
            toast.error("Name is required.");
            return;
        }
        setIsEditing(!isEditing);
    };

    return (
        <section>
            <AddItemToJobModal
                open={openAddItem}
                setOpen={setOpenAddItem}
                taskId={params.taskId}
                getNewChecklistItems={retrieveChecklist}
            />
            <DeleteModal
                isModalOpen={isDeleteModalOpen}
                setIsModalOpen={setIsDeleteModalOpen}
                onRemove={removeChecklistItem}
            />
            <Toaster />
            <div className="flex h-screen overflow-hidden">
                {/* Sidebar */}
                <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                {/* Content area */}
                <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                    {/*  Site header */}
                    <Header
                        sidebarOpen={sidebarOpen}
                        setSidebarOpen={setSidebarOpen}
                    />

                    {!loading ? (
                        <main>
                            <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                                <div className="flex flex-col items-start max-w-2xl">
                                    <Breadcrumbs
                                        home="/templates/jobs"
                                        pages={[
                                            {
                                                name: "Job template setup",
                                                href: `/templates/job/${params.jobId}`,
                                                current: true,
                                            },
                                            {
                                                name: "Edit job",
                                                href: `/templates/jobs/${params.jobId}/task/${params.taskId}`,
                                                current: true,
                                            },
                                        ]}
                                    />

                                    <h1 className="text-2xl md:text-3xl text-slate-800 font-bold p-4">
                                        Edit Job
                                        {/* <span className="text-slate-400 font-normal">
                                                    {" "}
                                                    {job.name}{" "}
                                                </span> */}
                                    </h1>

                                    {/* Page header */}
                                    {/* <div className="sm:flex sm:justify-between sm:items-center mb-8 w-full mt-12">

                                        <div className="mb-4 sm:mb-0">
                                            <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">
                                                Edit Jobs
                                                <span className="text-slate-400 font-normal">
                                                    {" "}
                                                    {task.name}{" "}
                                                </span>
                                            </h1>
                                        </div>
                                    </div> */}

                                    <div className="bg-white shadow-lg rounded-sm border border-slate-200 relative w-full pb-4">
                                        {/*  <header className="px-5 py-4 border-b border-slate-200">
                                            <h2 className="font-semibold text-slate-800">
                                                Details
                                            </h2>
                                        </header> */}

                                        <div className="space-y-5 px-5 py-4">
                                            <div className="font-medium flex justify-end">
                                                <button
                                                    onClick={() => toggleEdit()}
                                                    type="submit"
                                                    className="w-fit z-20 inline-flex border border-transparent shadow-sm text-sm font-medium rounded-sm outline-none"
                                                >
                                                    {isEditing ? (
                                                        <>Cancel</>
                                                    ) : (
                                                        <>
                                                            <PencilIcon className="h-4 w-4 mr-2" />
                                                            Edit Name
                                                        </>
                                                    )}
                                                </button>
                                            </div>
                                            <div>
                                                <label
                                                    htmlFor="email"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Name
                                                </label>
                                                <div className="mt-1">
                                                    <input
                                                        disabled={!isEditing}
                                                        type="text"
                                                        onChange={(e) =>
                                                            setTask({
                                                                ...task,
                                                                name: e.target.value,
                                                            })
                                                        }
                                                        value={task.name}
                                                        className={`w-full shadow-sm border border-slate-300 rounded-sm p-2 ${
                                                            !isEditing &&
                                                            "focus:outline-none focus:ring-0 bg-gray-200"
                                                        }`}
                                                    />
                                                </div>
                                                {isEditing && (
                                                    <div className="w-full flex justify-start">
                                                        <button
                                                            onClick={() =>
                                                                saveDetails()
                                                            }
                                                            className="z-50 mt-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-sm text-white bg-teal-500 hover:bg-teal-600 focus:outline-none focus:ring-0"
                                                        >
                                                            Save
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="bg-white shadow-lg rounded-sm border border-slate-200 relative mt-12 w-full">
                                        <header className="flex w-full justify-between items-center px-5 py-4 border-b border-slate-200">
                                            <h2 className="font-semibold text-slate-800">
                                                Checklist Items
                                                {/* <span className="font-light text-sm text-slate-700">
                                                    click & drag to re order
                                                </span> */}
                                            </h2>
                                            <button
                                                id="add-btn"
                                                onClick={() => onClickAddItem()}
                                                type="button"
                                                className="inline-flex items-center p-1 border border-transparent rounded-sm shadow-sm text-white bg-teal-500 hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
                                            >
                                                <PlusIcon className="w-5 h-5" />
                                            </button>
                                        </header>

                                        <ChecklistFormSection
                                            taskId={params.taskId}
                                            checklist={checklist}
                                            inputCheckList={inputCheckList}
                                            retrieveChecklist={retrieveChecklist}
                                            setCheckList={setCheckList}
                                            setInputCheckList={setInputCheckList}
                                            updateChecklistItem={updateChecklistItem}
                                            onClickRemoveChecklistItem={
                                                onClickRemoveChecklistItem
                                            }
                                            createChecklistItem={createChecklistItem}
                                        />
                                    </div>
                                </div>
                            </div>
                        </main>
                    ) : (
                        <div className="w-full mt-64 flex justify-center items-center max-w-9xl">
                            <motion.div
                                className="border border-t-gray-700 border-t-[4px] rounded-full border-gray-200 w-12 h-12"
                                initial={{ rotateZ: 0 }}
                                animate={{ rotateZ: 360 }}
                                exit={{ rotateZ: 0 }}
                                transition={{
                                    repeat: Infinity,
                                    ease: "linear",
                                    duration: 1,
                                }}
                            ></motion.div>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
}
export default EditTask;
