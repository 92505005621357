import { useState } from "react";

const EditNotes = ({ label, notes, saveNotes, setEditNotes }) => {
  const [inputNotes, setInputNotes] = useState(notes);
  return (
    <div className="w-full mt-8 mb-8">
      <label htmlFor="notes" className="block text-sm font-medium text-gray-500">
        {label}
      </label>
      <div className="mt-1 w-full">
        <textarea
          type="text"
          value={inputNotes}
          rows={"2"}
          onChange={(e) => {
            setInputNotes(e.target.value);
          }}
          className="shadow-sm border border-slate-200 rounded-sm p-4 w-full focus:ring-0 focus:outline-none"
        />
      </div>
      <div className="w-full flex justify-end my-4">
        <button
          onClick={() => setEditNotes(false)}
          className="btn bg-red-500 hover:bg-red-600 text-white h-10 mr-4"
        >
          Cancel
        </button>
        <button
          onClick={() => {
            saveNotes(inputNotes);
            setEditNotes(false);
          }}
          className="btn bg-teal-500 hover:bg-teal-600 text-white h-10"
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default EditNotes;
