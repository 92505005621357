export const formatTaxNumber = ({ clientType, taxNumber }) => {
    if (clientType == "INDIVIDUAL") {
      return taxNumber
        .replace(/-/g, "")
        .replace(/(?=[0-9]{9})([0-9]{3})([0-9]{2})([0-9]{4})/, "$1-$2-$3");
    } else {
      return taxNumber
        .replace(/-/g, "")
        .replace(/(?=[0-9]{9})([0-9]{2})([0-9]{7})/, "$1-$2");
    }
  };
