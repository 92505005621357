import React, { useState } from "react";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
// import DeleteButton from "../../components/actions/DeleteButton";
import { useEffect } from "react";
import { getServices } from "../../services/getServices";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
//import { deleteService } from "../../services/deleteService";
import { cloneService } from "../../services/cloneService";
import FirmFlowTable from "../../components/mytasks/FirmFlowTable";
import ReactTooltip from "react-tooltip";
import DeleteServiceModal from "./DeleteServiceModal";
import { DeleteService } from "../../services/service/deleteService";
import toast, { Toaster } from "react-hot-toast";

function Home() {
  const navigate = useNavigate();
  const [serviceIdToBeDeleted, setServiceIdToBeDeleted] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  // const [selectedItems, setSelectedItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);

  //Set initial list
  useEffect(() => {
    callApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const callApi = async (e) => {
    const res = await getServices();
    console.log(res.data);
    //Setting list, pagination labels, and client name
    res.data.map((item) => {
      item.updated = new Date(item.updated).toDateString().substring(4);
    });
    setList(res.data);
    // setPagination({ firstIndex: 1, lastIndex: res.data.length, totalResults: res.data.length });
    setLoading(false);
  };
  const _cloneService = async (service_id) => {
    console.log("test");
    const res = await cloneService(service_id);

    if (res.data.success) {
      callApi();
    }
  };

  // const handleSelectedItems = (selectedItems) => {
  //   setSelectedItems([...selectedItems]);
  // };

  const [tooltip, showTooltip] = useState(true);

  /*  const removeService = async (service_id) => {
    const res = await deleteService(service_id);

    if (res.success) {
      callApi();
    } else {
      alert(res.message);
    }
  }; */

  const onHandleRemoveService = (serviceId) => {
    setIsModalOpen(true);
    setServiceIdToBeDeleted(serviceId);
  };

  const onRemoveService = async () => {
    const response = await DeleteService(serviceIdToBeDeleted);
    if (response.success) {
      console.log(response);
      toast.success(response.data.message);
      callApi();
    } else {
      toast.error(response.data.message);
    }
    setIsModalOpen(false);
  };

  return (
    <section>
      <Toaster />
      <DeleteServiceModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        onRemoveService={onRemoveService}
      />
      <div className="flex h-screen overflow-hidden">
        {/* Sidebar */}
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        {/* Content area */}
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          {/*  Site header */}
          <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

          {!loading ? (
            <main>
              <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                {/* Page header */}
                <div className="sm:flex sm:justify-between sm:items-center mb-8">
                  {/* Left: Title */}
                  <div className="mb-4 sm:mb-0">
                    <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">
                      Service Templates
                    </h1>
                  </div>

                  {/* Right: Actions */}
                  <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end items-end gap-2">
                    {/* Add service button */}
                    <button
                      onClick={() => navigate("/templates/services/create")}
                      className="btn bg-teal-500 hover:bg-teal-600 text-white h-10"
                    >
                      <svg
                        className="w-4 h-4 fill-current opacity-50 shrink-0"
                        viewBox="0 0 16 16"
                      >
                        <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                      </svg>
                      <span className="xs:block ml-2">
                        Add Service Template
                      </span>
                    </button>
                    {/* Clone button */}
                    {/* <button onClick={() => clone()} className="btn bg-teal-500 hover:bg-teal-600 text-white h-10">
                      <svg className="w-4 h-4 fill-current opacity-50 shrink-0" viewBox="0 0 16 16">
                        <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                      </svg>
                      <span className="xs:block ml-2">Clone Service Template</span>
                    </button> */}
                  </div>
                </div>

                <FirmFlowTable
                  columns={[
                    {
                      Header: "Name",
                      accessor: "name",
                      Cell: (props) => (
                        <>
                          {tooltip && (
                            <ReactTooltip
                              type="light"
                              effect="solid"
                              place="top"
                              border={true}
                              borderColor={"rgb(220,220,220)"}
                              className="bg-gray-50 rounded-md"
                              multiline={false}
                            />
                          )}
                          <button
                            onMouseEnter={() => showTooltip(true)}
                            onMouseLeave={() => {
                              showTooltip(false);
                            }}
                            data-tip={`Template for ${props.row.original.name}`}
                            onClick={() =>
                              navigate(`/services/${props.row.original.id}`)
                            }
                            className="text-blue-500 hover:text-blue-700 rounded-full"
                          >
                            {props.row.original.name}
                          </button>
                        </>
                      ),
                    },
                    {
                      Header: "Department",
                      accessor: "department",
                    },
                    {
                      Header: "Update On",
                      accessor: "updated",
                    },
                    {
                      Header: "Actions",
                      accessor: "id",
                      Cell: (props) => (
                        <td
                          onMouseEnter={() => showTooltip(true)}
                          onMouseLeave={() => {
                            showTooltip(false);
                          }}
                          className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px"
                        >
                          {tooltip && (
                            <ReactTooltip
                              type="light"
                              effect="solid"
                              place="top"
                              border={true}
                              borderColor={"rgb(220,220,220)"}
                              className="bg-gray-50 rounded-md"
                              multiline={false}
                            />
                          )}
                          {/* Menu button */}
                          <button
                            data-tip="Edit"
                            onClick={() => navigate(`/services/${props.value}`)}
                            className="text-slate-400 hover:text-slate-500 rounded-full"
                          >
                            <span className="sr-only">Menu</span>
                            <svg
                              className="w-8 h-8 fill-current"
                              viewBox="0 0 32 32"
                            >
                              <circle cx="16" cy="16" r="2" />
                              <circle cx="10" cy="16" r="2" />
                              <circle cx="22" cy="16" r="2" />
                            </svg>
                          </button>
                          <button
                            data-tip="Duplicate"
                            onClick={() => _cloneService(props.value)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="currentColor"
                              className="w-7 h-7 text-slate-400 hover:text-slate-500 p-1 pt-0"
                            >
                              <path d="M7.5 3.375c0-1.036.84-1.875 1.875-1.875h.375a3.75 3.75 0 013.75 3.75v1.875C13.5 8.161 14.34 9 15.375 9h1.875A3.75 3.75 0 0121 12.75v3.375C21 17.16 20.16 18 19.125 18h-9.75A1.875 1.875 0 017.5 16.125V3.375z" />
                              <path d="M15 5.25a5.23 5.23 0 00-1.279-3.434 9.768 9.768 0 016.963 6.963A5.23 5.23 0 0017.25 7.5h-1.875A.375.375 0 0115 7.125V5.25zM4.875 6H6v10.125A3.375 3.375 0 009.375 19.5H16.5v1.125c0 1.035-.84 1.875-1.875 1.875h-9.75A1.875 1.875 0 013 20.625V7.875C3 6.839 3.84 6 4.875 6z" />
                            </svg>
                          </button>
                          <button
                            data-tip="Delete"
                            onClick={() => onHandleRemoveService(props.value)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="currentColor"
                              className="w-7 h-7 text-slate-400 hover:text-red-500 p-1 pt-0"
                            >
                              <path
                                fillRule="evenodd"
                                d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 01-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </button>
                        </td>
                      ),
                    },
                  ]}
                  data={list}
                />
              </div>
            </main>
          ) : (
            <div className="w-full mt-64 flex justify-center items-center max-w-9xl">
              <motion.div
                className="border border-t-gray-700 border-t-[4px] rounded-full border-gray-200 border-[4px] w-12 h-12"
                initial={{ rotateZ: 0 }}
                animate={{ rotateZ: 360 }}
                exit={{ rotateZ: 0 }}
                transition={{ repeat: Infinity, ease: "linear", duration: 1 }}
              ></motion.div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}
export default Home;
