import { ArrowsUpDownIcon, PencilIcon, TrashIcon } from "@heroicons/react/24/solid";
import React, { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import ChecklistItemsList from "../../components/clientServiceSubscription/tasks/checklistItems/ChecklistItemsList";
import EditNewItemModal from "../Jobs/EditNewItemModal";

const CreateNewChecklistItemFormSection = ({
    inputCheckList,
    job,
    setJob,
    setInputCheckList,
    updateChecklistItem,
    removeChecklistItem,
}) => {
    const [openEditItem, setOpenEditItem] = useState(false);
    const [checklistToEdit, setChecklistToEdit] = useState([]);

    // a little function to help us with reordering the result
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };
    const onDragEnd = async (result) => {
        // dropped outside the list
        console.log(job.checklistItems);
        if (!result.destination) {
            return;
        }

        const items = reorder(
            job.checklistItems,
            result.source.index,
            result.destination.index
        );

        const reorderedItems = items.map((item, index) => {
            return { ...item, order: index + 1 };
        });

        const newJob = {
            ...job,
            checklistItems: reorderedItems,
        };

        setJob(newJob);
    };

    const onClickEditItem = (value, index) => {
        const item = {
            ...value,
            order: index,
        };
        setChecklistToEdit(item);
        setOpenEditItem(true);
    };

    return (
        <div className="space-y-5 px-5 py-4">
            <EditNewItemModal
                open={openEditItem}
                setOpen={setOpenEditItem}
                checklistItemToEdit={checklistToEdit}
                setChecklistItemToEdit={setChecklistToEdit}
                job={job}
                setJob={setJob}
            />

            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                            {job.checklistItems.map((value, index) => {
                                return (
                                    <Draggable
                                        key={value.id}
                                        draggableId={value.id}
                                        index={index}
                                    >
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                <div
                                                    key={index}
                                                    className="relative flex items-center my-4 border border-slate-300 rounded-sm p-2  bg-gray-200"
                                                >
                                                    <ArrowsUpDownIcon className="h-4 w-4" />
                                                    <div className="ml-2 text-sm w-full">
                                                        <div className="flex w-full items-center">
                                                            <h1
                                                                htmlFor="comments"
                                                                className="w-full font-medium text-gray-700"
                                                            >
                                                                {value.description}
                                                            </h1>
                                                            <div className="w-full">
                                                                <ChecklistItemsList
                                                                    links={
                                                                        value.links
                                                                            ? value.links
                                                                            : []
                                                                    }
                                                                />
                                                            </div>

                                                            <button
                                                                id="edit-btn"
                                                                onClick={() =>
                                                                    onClickEditItem(
                                                                        value,
                                                                        index
                                                                    )
                                                                }
                                                                className="mx-2"
                                                            >
                                                                <PencilIcon className="w-4 h-4 mx-2" />
                                                            </button>
                                                            <button
                                                                id="delete-btn"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    removeChecklistItem(
                                                                        value.id
                                                                    );
                                                                }}
                                                                className="ml-2"
                                                            >
                                                                <TrashIcon className="w-4 h-4 mx-2" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>{" "}
                                            </div>
                                        )}
                                    </Draggable>
                                );
                            })}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            {inputCheckList.map((value, i) => {
                return (
                    <div className="relative flex items-start">
                        <input
                            type="text"
                            onChange={(e) => {
                                const arr = [...inputCheckList];
                                arr[i].description = e.target.value;
                                setInputCheckList(inputCheckList);
                            }}
                            className="shadow-sm border border-slate-200 rounded-sm p-1 px-2 w-full focus:ring-0 focus:outline-none"
                        />
                        <button
                            onClick={async () => {
                                if (value.description === "") return;
                                let newIndex = job.checklistItems.length;
                                console.log(`index ${i} length ${newIndex}`);
                                // create new checklist item
                            }}
                            type="button"
                            className="ml-2 inline-flex items-center p-1.5 border border-transparent rounded-sm shadow-sm text-white bg-teal-500 hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-5 w-5"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </button>
                        <button
                            id="cancel-btn"
                            onClick={() => {
                                const arr = [...inputCheckList];
                                arr.splice(i, 1);
                                setInputCheckList(arr);
                            }}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="w-6 h-6"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                                />
                            </svg>
                        </button>
                    </div>
                );
            })}
        </div>
    );
};

export default CreateNewChecklistItemFormSection;
