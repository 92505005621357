import axios from "axios";
import Cookies from "js-cookie";
import { FIRMFLOWURL } from "../url";
export const cloneClientServiceSubscription = (form) => {
  return new Promise(async (resolve) => {
    try {
      const token = Cookies.get("Bearer");
      const response = await axios.post(
        `${FIRMFLOWURL}api/ClientSubscription/clone/${form.clientServiceId}`,
        {
          year: form.serviceYear,
          interval: form.interval,
          clientServiceSubscriptionIntervalType: form.interval,
          startDate: form.start,
          endDate: form.end,
          /* assignedEmployees: form.assignedEmployees, */
          notes: form.notes,
          assignedByEmployee: form.assignedByEmployee,
          isEveryItemRenewable: form.isEveryItemRenewable,
          cloningMethod: form.cloningMethod,
          assignedEmployee: form.assignedEmployee
        },
        {
          headers: { Authorization: token },
        }
      );
      resolve({ success: true, ...response });
    } catch (err) {
      console.log(err);
      if (err.message.includes(401)) {
        Cookies.remove("Bearer");
        window.location.replace("/");
        return;
      }
      resolve({ success: false, message: err.message });
    }
  });
};
