import axios from "axios";
import Cookies from "js-cookie";
import { FIRMFLOWURL } from "../url";

export const updateClientServiceSubscriptionTaskChecklistItem = (
  checklistItem
) => {
  return new Promise(async (resolve) => {
    try {
      const token = Cookies.get("Bearer");
      const response = await axios.put(
        `${FIRMFLOWURL}api/ClientServiceSubscriptionTaskChecklistItem/${checklistItem.id}`,
        { ...checklistItem },
        {
          headers: { Authorization: token },
        }
      );
      console.log("respuesta: ", response);
      resolve({ ...response, success: true });
    } catch (err) {
      console.log(
        "error on updateClientServiceSubscriptionTaskChecklistItem",
        err
      );
      if (err.message.includes(401)) {
        Cookies.remove("Bearer");
        window.location.replace("/");
        return;
      }
      resolve({ success: false, message: err.message });
    }
  });
};
