import axios from "axios";
import Cookies from "js-cookie";
import { FIRMFLOWURL } from "../url";
export const updateTaskService = (jobId, job) => {
  console.log("updateTaskService", job);
  return new Promise(async (resolve) => {
    try {
      const token = Cookies.get("Bearer");
      const response = await axios.put(
        `${FIRMFLOWURL}api/ClientServiceSubscriptionTask/UpdateTask/${jobId}`,
        {
          ...job,
        },
        {
          headers: { Authorization: token },
        }
      );
      resolve({ success: true, ...response });
    } catch (err) {
      console.log(err);
      if (err.message.includes(401)) {
        Cookies.remove("Bearer");
        window.location.replace("/");
        return;
      }
      resolve({ success: false, message: err.response.data.title });
    }
  });
};
