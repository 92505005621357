const CheckboxForServices = ({
    onChange,
    name,
    message,
    value,
    checked,
  }) => {
    return (
      <div className="mt-5 sm:mt-6">
        <label
          htmlFor={name}
          className="text-left block text-sm font-medium text-gray-700"
        >
          <input
            onChange={onChange}
            type="checkbox"
            id={name}
            name={name}
            className="form-checkbox"
            value={value}
            checked={checked}
          ></input>
          <span className="text-sm font-medium ml-2">{message}</span>
        </label>
      </div>
    );
  };
  export default CheckboxForServices;