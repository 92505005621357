import axios from "axios";
import Cookies from "js-cookie";
import { FIRMFLOWURL } from "./url";
export const updateSubscriptionTask = (subscription_id, form) => {
  console.log("updateSubscriptionTaskCall", form);
  return new Promise(async (resolve) => {
    try {
      const token = Cookies.get("Bearer");
      const response = await axios.put(
        `${FIRMFLOWURL}api/ClientSubscription/${subscription_id}/task`,
        {
          ...form,
        },
        {
          headers: { Authorization: token },
        }
      );
      resolve({ success: true, ...response });
    } catch (err) {
      console.log(err);
      if (err.message.includes(401)) {
        Cookies.remove("Bearer");
        window.location.replace("/");
        return;
      }
      resolve({ success: false, message: err.response.data.title });
    }
  });
};
