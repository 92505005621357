const SERVICE_STATUSES = ["To Do", "In Progress", "Complete"];

const JobStatusReportTable = ({ jobs }) => {
    return (
        <div className="flex flex-row overflow-x-auto mb-12">
            {jobs.map((report, index) => {
                return (
                    <div
                        className="w-full mx-4 p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-8  dark:border-gray-700 min-w-fit min-h-full"
                        key={index}
                    >
                        <div className="flex items-center justify-between mb-4">
                            <h5
                                className={`text-xl font-bold inline-flex items-center rounded-full px-2.5 py-0.5 text-white ${
                                    SERVICE_STATUSES[report.status] === "To Do"
                                        ? "bg-red-500"
                                        : SERVICE_STATUSES[report.status] ==
                                          "In Progress"
                                        ? "bg-yellow-500"
                                        : "bg-teal-500"
                                }`}
                            >
                                {SERVICE_STATUSES[report.status]}
                            </h5>
                            <p className="text-sm font-medium text-green-600 hover:underline dark:text-green-500">
                                Total: {report.jobs.length}
                            </p>
                        </div>
                        <div className="flow-root">
                            <ul
                                className="divide-y divide-gray-200 dark:divide-gray-700"
                                key={index}
                            >
                                {report.jobs.length > 0 ? (
                                    report.jobs.map((job, index) => (
                                        <li className="py-3 sm:py-4" key={index}>
                                            <div className="flex items-center">
                                                <div className="flex-shrink-0"></div>
                                                <div className="flex-1 min-w-0 ms-4">
                                                    <p className="text-sm font-medium text-gray-900 truncate dark:text-gray-700">
                                                        <a
                                                            href={`/clients/service/${job.serviceId}/client/${job.clientId}`}
                                                            rel="noreferrer"
                                                        >
                                                            Client: {job.client}
                                                        </a>
                                                    </p>
                                                    <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                                        Service: {job.service}
                                                    </p>
                                                    <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                                        Job Name: {job.name}
                                                    </p>
                                                    <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                                        Year: {job.year}
                                                    </p>
                                                    <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                                        Assigned to:{" "}
                                                        {job.assignedEmployees !==
                                                            null ||
                                                        job.assignedEmployees
                                                            .length > 0
                                                            ? job.assignedEmployees
                                                                  .map(
                                                                      (employee) =>
                                                                          employee.fullName
                                                                  )
                                                                  .join(", ")
                                                            : "No employee assigned"}
                                                    </p>
                                                </div>
                                                {/* <div className="block-flex items-center text-base font-semibold text-gray-900 dark:text-gray-700">
                            Due date {moment(job.dueDate).format("MM-DD-YYYY")}
                          </div> */}
                                            </div>
                                        </li>
                                    ))
                                ) : (
                                    <li className="py-3 sm:py-4">
                                        No jobs assigned to this status.
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default JobStatusReportTable;
