import axios from "axios";
import Cookies from "js-cookie";
import { FIRMFLOWURL } from "./url";
export const getClientsSubscriptionStates = (id, status) => {
    return new Promise(async (resolve) => {
        try {
            const token = Cookies.get("Bearer");
            const response = await axios.get(
                `${FIRMFLOWURL}api/Analytics/GetClientsSubscriptionStates?serviceId=${id}&status=${status}`,
                {
                    headers: { Authorization: token },
                }
            );
            resolve({ ...response, success: true });
        } catch (err) {
            console.log(err);
            if (err.message.includes(401)) {
                Cookies.remove("Bearer");
                window.location.replace("/");
                return;
            }
            resolve({ success: false, message: err.message });
        }
    });
};

export const getMyAssignmentsReport = () => {
    return new Promise(async (resolve) => {
        try {
            const token = Cookies.get("Bearer");
            const response = await axios.get(
                `${FIRMFLOWURL}api/Analytics/MyAssignmentsReport`,
                {
                    headers: { Authorization: token },
                }
            );
            resolve({ ...response, success: true });
        } catch (err) {
            console.log(err);
            if (err.message.includes(401)) {
                Cookies.remove("Bearer");
                window.location.replace("/");
                return;
            }
            resolve({ success: false, message: err.message });
        }
    });
};

export const getUserJobs = () => {
    return new Promise(async (resolve) => {
        try {
            const token = Cookies.get("Bearer");
            const response = await axios.get(
                `${FIRMFLOWURL}api/Analytics/UserJobs`,
                {
                    headers: { Authorization: token },
                }
            );
            resolve({ ...response, success: true });
        } catch (err) {
            console.log(err);
            if (err.message.includes(401)) {
                Cookies.remove("Bearer");
                window.location.replace("/");
                return;
            }
            resolve({ success: false, message: err.message });
        }
    });
};

export const getUserServices = () => {
    return new Promise(async (resolve) => {
        try {
            const token = Cookies.get("Bearer");
            const response = await axios.get(
                `${FIRMFLOWURL}api/Analytics/UserServices`,
                {
                    headers: { Authorization: token },
                }
            );
            resolve({ ...response, success: true });
        } catch (err) {
            console.log(err);
            if (err.message.includes(401)) {
                Cookies.remove("Bearer");
                window.location.replace("/");
                return;
            }
            resolve({ success: false, message: err.message });
        }
    });
};

export const getUserChecklistItems = () => {
    return new Promise(async (resolve) => {
        try {
            const token = Cookies.get("Bearer");
            const response = await axios.get(
                `${FIRMFLOWURL}api/Analytics/UserChecklistItems`,
                {
                    headers: { Authorization: token },
                }
            );
            resolve({ ...response, success: true });
        } catch (err) {
            console.log(err);
            if (err.message.includes(401)) {
                Cookies.remove("Bearer");
                window.location.replace("/");
                return;
            }
            resolve({ success: false, message: err.message });
        }
    });
};

export const getFirmStatistics = () => {
    return new Promise(async (resolve) => {
        try {
            const token = Cookies.get("Bearer");
            const response = await axios.get(`${FIRMFLOWURL}api/Analytics/Firm`, {
                headers: { Authorization: token },
            });
            resolve({ ...response, success: true });
        } catch (err) {
            console.log(err);
            if (err.message.includes(401)) {
                Cookies.remove("Bearer");
                window.location.replace("/");
                return;
            }
            resolve({ success: false, message: err.message });
        }
    });
};
export const getRegisteredClients = () => {
    return new Promise(async (resolve) => {
        try {
            const token = Cookies.get("Bearer");
            const response = await axios.get(`${FIRMFLOWURL}api/Analytics/clients`, {
                headers: { Authorization: token },
            });
            resolve({ ...response, success: true });
        } catch (err) {
            console.log(err);
            if (err.message.includes(401)) {
                Cookies.remove("Bearer");
                window.location.replace("/");
                return;
            }
            resolve({ success: false, message: err.message });
        }
    });
};

export const getFirmAccountability = (data) => {
    return new Promise(async (resolve) => {
        try {
            const token = Cookies.get("Bearer");
            const response = await axios.post(
                `${FIRMFLOWURL}api/Analytics/Accountability`,
                data,
                {
                    headers: { Authorization: token },
                }
            );
            resolve({ ...response, success: true });
        } catch (err) {
            console.log(err);
            if (err.message.includes(401)) {
                Cookies.remove("Bearer");
                window.location.replace("/");
                return;
            }
            resolve({ success: false, message: err.message });
        }
    });
};

export const getServiceAccountability = (data) => {
    return new Promise(async (resolve) => {
        try {
            const token = Cookies.get("Bearer");
            const response = await axios.get(
                `${FIRMFLOWURL}api/Analytics/ServiceAccountability?startDate=${data.startDate}&endDate=${data.endDate}&employeeId=${data.employeeId}&serviceId=${data.serviceId}&departmentId=${data.departmentId}`,
                {
                    headers: { Authorization: token },
                }
            );
            resolve({ ...response, success: true });
        } catch (err) {
            console.log(err);
            if (err.message.includes(401)) {
                Cookies.remove("Bearer");
                window.location.replace("/");
                return;
            }
            resolve({ success: false, message: err.message });
        }
    });
};

export const getChecklistItemAccountability = (data) => {
    return new Promise(async (resolve) => {
        try {
            const token = Cookies.get("Bearer");
            const response = await axios.get(
                `${FIRMFLOWURL}api/Analytics/ChecklistItemAccountability?startDate=${data.startDate}&endDate=${data.endDate}&employeeId=${data.employeeId}&serviceId=${data.serviceId}&departmentId=${data.departmentId}`,
                {
                    headers: { Authorization: token },
                }
            );
            resolve({ ...response, success: true });
        } catch (err) {
            console.log(err);
            if (err.message.includes(401)) {
                Cookies.remove("Bearer");
                window.location.replace("/");
                return;
            }
            resolve({ success: false, message: err.message });
        }
    });
};
