import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/solid";
import { Fragment, useEffect, useState } from "react";

const SelectServiceInJobReportCombobox = ({
  label,
  services,
  selectedService,
  onChangeService,
}) => {
  const [query, setQuery] = useState("");
  useEffect(() => {
    console.log(services);
  }, []);

  const filteredServices =
    query === ""
      ? services
      : services.filter((service) => {
          return service.name.toLowerCase().includes(query.toLowerCase());
        });

  return (
    <div className="basic-single min-w-[180px] w-1/4">
      <label
        htmlFor="selectedService"
        className="text-left block text-base font-bold text-black"
      >
        {label}
      </label>
      <Combobox
        by="id"
        as="div"
        className="relative inline-block text-left w-full"
        value={selectedService}
        onChange={onChangeService}
        multiple
      >
        <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
          <Combobox.Input
            displayValue={selectedService.name}
            onChange={(event) => setQuery(event.target.value)}
            className="inline-flex truncate justify-between w-full rounded-md border border-gray-200 bg-white px-3 py-2 text-sm font-normal text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-0"
          />
          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </Combobox.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Combobox.Options className="absolute right-0 z-10 mt-2 w-fit origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <ul className="py-1 max-h-72 overflow-auto w-full">
              {filteredServices.map((service) => (
                <Combobox.Option
                  name="selectedService"
                  key={service.id}
                  value={service}
                  className="m-5 cursor-pointer"
                >
                  {({ active, selected }) => (
                    <div
                      className={`${
                        active ? "text-white bg-blue-600" : "text-gray-900"
                      } cursor-pointer select-none relative pl-8 pr-4`}
                    >
                      {selected && (
                        <span
                          className={`${
                            active ? "text-white" : "text-blue-600"
                          } absolute inset-y-0 left-0 flex items-center pl-1.5`}
                        >
                          <CheckIcon className="h-5 w-5 text-green-600" />
                        </span>
                      )}
                      <span
                        className={`${
                          selected ? "font-semibold" : "font-normal"
                        } block truncate`}
                      >
                        {service.name}
                      </span>
                    </div>
                  )}
                </Combobox.Option>
              ))}
            </ul>
          </Combobox.Options>
        </Transition>
      </Combobox>
    </div>
  );
};

export default SelectServiceInJobReportCombobox;
