export const getTimestamp = (field, employee) => {
  let carriageReturn = "\u000D\u000D";
  if (field == "" || field == null) {
    carriageReturn = "";
  }
  const timestamp =
    carriageReturn +
    new Date().toLocaleDateString() +
    " " +
    new Date().toLocaleTimeString() +
    " " +
    employee.firstName +
    employee.lastName +
    " - ";

  return timestamp;
};
