import axios from "axios"
import Cookies from "js-cookie"
import { FIRMFLOWURL } from "./url";
export const getClient = (id) => {
    return new Promise(async resolve => {
        try {
            const token = Cookies.get('Bearer');
            const firm = await axios.get(`${FIRMFLOWURL}api/Firm`,
                {
                    headers: { 'Authorization': token }
                }
            )
            const response = await axios.get(`${FIRMFLOWURL}api/Client/firm/${firm.data.id}/client/${id}`,
                {
                    headers: { 'Authorization': token }
                }
            )
            
            resolve({ ...response, success: true });
        } catch (err) {
            console.log(err)
            if(err.message.includes(401)){
                Cookies.remove('Bearer');
                window.location.replace('/');
                return;
            }
            resolve({ success: false, message: err.message });
        }
    })
}