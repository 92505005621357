import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import Select from "react-select";
import Header from "../../../components/Header";
import Sidebar from "../../../components/Sidebar";
import {
    getChecklistItemAccountability,
    getFirmAccountability,
    getServiceAccountability,
} from "../../../services/analytics";
import { getEmployees } from "../../../services/getEmployees";
import { getServices } from "../../../services/getServices";
import { getServiceDepartments } from "../../../services/service/getServiceDepartments";
import UpdateNotesInAccountabilityReportModal from "../UpdateNotesInAccountabilityReportModal";
import ChecklistAccountabilityTable from "./components/ChecklistAccountabilityTable";
import JobAccountabilityTable from "./components/JobAccountabilityTable";
import ServicesAccountabilityTable from "./components/ServicesAccountabilityTable";

const ASSIGNMENTS = [
    {
        label: "Jobs",
        value: "jobs",
    },
    {
        label: "Services",
        value: "services",
    },
    {
        label: "Checklist items",
        value: "checklist_items",
    },
];

function Accountability() {
    const defaultId = "00000000-0000-0000-0000-000000000000";
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [checklistItemsData, setChecklistItemsData] = useState([]);
    const [jobsData, setJobsData] = useState([]);
    const [servicesData, setServicesData] = useState([]);
    const [departments, setDepartments] = useState([
        {
            value: defaultId,
            label: "All",
            is_showing: true,
        },
    ]);
    const [selectedDepartment, setSelectedDepartment] = useState(departments[0]);
    const [services, setServices] = useState([
        {
            value: defaultId,
            label: "All",
            is_showing: true,
            department: null,
        },
    ]);
    const [service, setService] = useState(services[0]);

    const [employees, setEmployees] = useState([
        {
            value: defaultId,
            label: "All",
            is_showing: true,
        },
    ]);
    const [employee, setEmployee] = useState(employees[0]);
    // const [columns, setColumns] = useState([]);
    const employeeName = "All Employees";
    //const [tooltip, showTooltip] = useState(true);

    const [startDate, setStartDate] = useState(new Date(2021, 12, 1));
    const [endDate, setEndDate] = useState(new Date());
    const [isUpdateNotesModalOpen, setIsUpdateNotesModalOpen] = useState(false);
    const [currentNotes, setCurrentNotes] = useState({
        clientId: null,
        subscriptionId: null,
        notes: null,
    });
    const [selectedAssignment, setSelectedAssignment] = useState(ASSIGNMENTS[0]);

    useEffect(() => {
        fetchServices();
        fetchEmployees();
        fetchDepartments();
        fetchAccountability();
    }, []);
    /* useEffect(() => {

        if (service.id == defaultId) {
            return;
        }
        fetchAccountability(true);

    }, [service]); */

    /* useEffect(() => {
        //Fetch subscriptions on client change
        if (employee.id == defaultId) {
            return;
        }
        fetchAccountability(true);
        // eslint-disable-next-line
        // console.log(employee);
    }, [employee]); */

    useEffect(() => {
        console.log(startDate, endDate);
        fetchAccountability();
    }, [
        startDate,
        endDate,
        selectedDepartment,
        selectedAssignment,
        service,
        employee,
    ]);

    const fetchDepartments = async () => {
        const response = await getServiceDepartments();
        const departmentsArray = [];
        if (response.data.success) {
            response.data.departments.map((department) => {
                departmentsArray.push({
                    value: department.id,
                    label: department.name,
                    is_showing: true,
                });
            });
            setDepartments([departments[0], ...departmentsArray]);
        }
        console.log(response);
    };

    async function fetchServices() {
        Promise.all([getServices()]).then((data) => {
            const arr = [];
            // eslint-disable-next-line
            data[0].data.map((service, i) => {
                console.log(service);
                arr.push({
                    value: service.id,
                    label: service.name,
                    is_showing: true,
                    department: service.department,
                });
                if (arr.length > 0) {
                    // setService(arr[0]);
                    let tmp = [services[0], ...arr];
                    // console.log(tmp);
                    setServices(tmp);
                    console.log(service);
                }
            });
            // console.log(arr);
        });
    }

    async function fetchEmployees() {
        Promise.all([getEmployees()]).then((res) => {
            let data = res[0].data.employees;
            console.log(data);
            const arr = [];
            // eslint-disable-next-line
            data.map((emp, i) => {
                arr.push({
                    value: emp.employeeId,
                    label: `${emp.firstName} ${emp.lastName}`,
                    is_showing: true,
                });
                if (arr.length > 0) {
                    // setEmployee(arr[0]);
                    let tmp = [employees[0], ...arr];
                    // console.log(tmp);
                    setEmployees(tmp);
                }
            });
            // console.log(arr);
        });
    }

    const fetchJobsAccountability = async () => {
        const res = await getFirmAccountability({
            startDate,
            endDate,
            serviceId: service.value,
            employeeId: employee.value,
            departmentId: selectedDepartment.value,
        });
        if (res.success) {
            console.log("getfirmAcountability", res);
            var newJobs = res.data?.jobs;
            setJobsData(newJobs); //issue here, on search array isn't pulling all data.
        } else {
            alert("Error fetching data.");
        }
    };

    const fetchServicesAccountability = async () => {
        const data = {
            startDate: new Date(startDate).toLocaleDateString("en-US"),
            endDate: new Date(endDate).toLocaleDateString("en-US"),
            serviceId: service.value,
            employeeId: employee.value,
            departmentId: selectedDepartment.value,
        };
        const res = await getServiceAccountability(data);
        if (res.success) {
            console.log("getServiceAccountability", res);
            var newServices = res.data?.services;
            setServicesData(newServices);
        } else {
            alert("Error fetching data.");
        }
    };

    const fetchChecklistItemsAccountability = async () => {
        const data = {
            startDate: new Date(startDate).toLocaleDateString("en-US"),
            endDate: new Date(endDate).toLocaleDateString("en-US"),
            serviceId: service.value,
            employeeId: employee.value,
            departmentId: selectedDepartment.value,
        };
        const res = await getChecklistItemAccountability(data);
        if (res.success) {
            console.log("getChecklistItemAccountability", res);
            var newChecklistItems = res.data?.checklistItems;
            setChecklistItemsData(newChecklistItems);
        } else {
            alert("Error fetching data.");
        }
    };

    async function fetchAccountability(isSearch = false) {
        console.log(service, employee, selectedDepartment);
        console.log({
            startDate,
            endDate,
            serviceId: service.value,
            employeeId: employee.value,
            departmentId: selectedDepartment.value,
        });

        if (selectedAssignment.value === "jobs") {
            await fetchJobsAccountability();
        }
        if (selectedAssignment.value === "services") {
            await fetchServicesAccountability();
        }
        if (selectedAssignment.value === "checklist_items") {
            await fetchChecklistItemsAccountability();
        }
        setLoading(false);
    }

    const onHandleClickNotes = ({ jobId, notes }) => {
        const newNotes = {
            jobId: jobId,
            notes: notes,
        };
        console.log(newNotes);
        setCurrentNotes(newNotes);
        setIsUpdateNotesModalOpen(true);
    };

    const onChangeSelectedAssignment = (e) => {
        setSelectedAssignment(e);
    };

    return (
        <div className="flex h-screen overflow-hidden">
            <Toaster />
            <UpdateNotesInAccountabilityReportModal
                open={isUpdateNotesModalOpen}
                setOpen={setIsUpdateNotesModalOpen}
                notes={currentNotes}
                fetchAccountability={fetchAccountability}
            />
            {/* Sidebar */}
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

            {/* Content area */}
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                {/*  Site header */}
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                {!loading ? (
                    <main>
                        <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                            {/* Page header */}
                            <div className="sm:flex sm:justify-between sm:items-center mb-8">
                                {/* Left: Title */}
                                <div className="mb-4 sm:mb-0">
                                    <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">
                                        {employeeName} Assigned{" "}
                                        {selectedAssignment.label}
                                    </h1>
                                </div>
                                {/* Right: Actions */}

                                <div className="flex flex-col items-start lg:space-x-2 lg:flex-row">
                                    <div className="flex flex-col sm:flex-row sm:space-x-2 items-center">
                                        <div className="flex flex-col items-start">
                                            <h2 className="text-1xl md:text-1xl text-slate-800 font-bold">
                                                Select Assignment
                                            </h2>

                                            <Select
                                                onChange={onChangeSelectedAssignment}
                                                className="basic-single min-w-[180px] w-auto"
                                                classNamePrefix="select"
                                                isSearchable={true}
                                                defaultValue={ASSIGNMENTS[0]}
                                                options={ASSIGNMENTS}
                                                name="color"
                                            />
                                        </div>
                                        <div className="flex flex-col items-start">
                                            <h2 className="text-1xl md:text-1xl text-slate-800 font-bold">
                                                Assigned Date
                                            </h2>
                                            {services.length > 0 && (
                                                <input
                                                    className="form-select pr-1 border border-gray-300"
                                                    defaultValue={
                                                        new Date(startDate)
                                                            .toISOString()
                                                            .split("T")[0]
                                                    }
                                                    type="date"
                                                    onChange={(e) => {
                                                        console.log(
                                                            new Date(
                                                                Date.parse(
                                                                    e.target.value
                                                                )
                                                            )
                                                        );
                                                        setStartDate(
                                                            new Date(
                                                                Date.parse(
                                                                    e.target.value
                                                                )
                                                            )
                                                        );
                                                    }}
                                                />
                                            )}
                                        </div>

                                        <div className="flex flex-col items-start">
                                            <h2 className="text-1xl md:text-1xl text-slate-800 font-bold">
                                                Due Date
                                            </h2>
                                            {services.length > 0 && (
                                                <input
                                                    className="form-select pr-1 border border-gray-300"
                                                    defaultValue={
                                                        new Date(endDate)
                                                            .toISOString()
                                                            .split("T")[0]
                                                    }
                                                    type="date"
                                                    onChange={(e) => {
                                                        console.log(
                                                            new Date(
                                                                Date.parse(
                                                                    e.target.value
                                                                )
                                                            )
                                                        );
                                                        setEndDate(
                                                            new Date(
                                                                Date.parse(
                                                                    e.target.value
                                                                )
                                                            )
                                                        );
                                                    }}
                                                />
                                            )}
                                        </div>
                                    </div>

                                    <div className="flex flex-col sm:flex-row sm:space-x-2 items-center">
                                        <div className="flex flex-col items-start">
                                            <h2 className="text-1xl md:text-1xl text-slate-800 font-bold">
                                                Select Service
                                            </h2>
                                            {services.length > 0 && (
                                                <Select
                                                    onChange={(e) => setService(e)}
                                                    className="basic-single min-w-[180px] w-auto"
                                                    classNamePrefix="select"
                                                    isSearchable={true}
                                                    defaultValue={services[0]}
                                                    options={services}
                                                    name="color"
                                                />
                                            )}
                                        </div>
                                        <div className="flex flex-col items-start">
                                            <h2 className="text-1xl md:text-1xl text-slate-800 font-bold">
                                                Select Employee
                                            </h2>
                                            {employees.length > 0 && (
                                                <Select
                                                    onChange={(e) => setEmployee(e)}
                                                    className="basic-single min-w-[180px] w-auto"
                                                    classNamePrefix="select"
                                                    isSearchable={true}
                                                    defaultValue={employees[0]}
                                                    options={employees}
                                                    name="color"
                                                />
                                            )}
                                        </div>
                                        <div className="flex flex-col items-start">
                                            <h2 className="text-1xl md:text-1xl text-slate-800 font-bold">
                                                Select Department
                                            </h2>
                                            {departments.length > 0 && (
                                                <Select
                                                    onChange={(e) =>
                                                        setSelectedDepartment(e)
                                                    }
                                                    className="basic-single min-w-[180px] w-auto"
                                                    classNamePrefix="select"
                                                    isSearchable={true}
                                                    defaultValue={departments[0]}
                                                    options={departments}
                                                    name="departments"
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {selectedAssignment.value === "jobs" && (
                                <JobAccountabilityTable
                                    data={jobsData}
                                    onHandleClickNotes={onHandleClickNotes}
                                />
                            )}

                            {selectedAssignment.value === "services" && (
                                <ServicesAccountabilityTable
                                    data={servicesData}
                                    onHandleClickNotes={onHandleClickNotes}
                                />
                            )}

                            {selectedAssignment.value === "checklist_items" && (
                                <ChecklistAccountabilityTable
                                    data={checklistItemsData}
                                />
                            )}
                        </div>
                    </main>
                ) : (
                    <div className="w-full mt-64 flex justify-center items-center max-w-9xl">
                        <motion.div
                            className="border border-t-gray-700 border-t-[4px] rounded-full border-gray-200 w-12 h-12"
                            initial={{ rotateZ: 0 }}
                            animate={{ rotateZ: 360 }}
                            exit={{ rotateZ: 0 }}
                            transition={{
                                repeat: Infinity,
                                ease: "linear",
                                duration: 1,
                            }}
                        ></motion.div>
                    </div>
                )}
            </div>
        </div>
    );
}
export default Accountability;
