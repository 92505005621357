import { useEffect, useState } from "react";
import { getClientsByEfileStatuses } from "../../services/analytics/getClientsByEfileStatuses";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import SelectYearEfileStatusReportListBox from "../../components/analytics/SelectYearEfileStatusReportListBox";
import { getSubscriptionYears } from "../../services/analytics/getSubscriptionYears";

const EfileStatuses = [
  "Needs extension",
  "On Extension",
  "Accepted",
  "Rejected",
];

const EfileStatusReport = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [clientsByEfileStatuses, setClientsByEfileStatuses] = useState([]);
  const [currentTaxYear, setCurrentTaxYear] = useState(0);
  const [selectedYear, setSelectedYear] = useState(null);
  const [years, setYears] = useState([
    { name: currentTaxYear, year: currentTaxYear },
  ]);
 

  const getReport = async (year) => {
    const response = await getClientsByEfileStatuses(year);
    if (response.success) {
      console.log(response.data.clientsByEfileStatuses);
      setClientsByEfileStatuses(response.data.clientsByEfileStatuses);
    } else {
      console.log(response);
    }
  };

  const getReportFromThisYear = async () => {
    const currentTaxYear = new Date().getFullYear() - 1;
    setCurrentTaxYear(currentTaxYear);
    setSelectedYear({ name: currentTaxYear, year: currentTaxYear });
    getReport(currentTaxYear);
  };

  const getDifferentSubscriptionYears = async () => {
    const response = await getSubscriptionYears();
    if (response.success) {
      let newYears = [];
      response.data.years.map((year) => {
        newYears.push({ name: year, year: year });
      });
      newYears.push({ name: "All", year: 0 });
      setYears(newYears);
    } else {
      console.log(response)
    }

  };

  useEffect(() => {
    getReportFromThisYear();
    getDifferentSubscriptionYears();
  }, []);

  const onHandleChangeYear = (option) => {
    console.log(option.year);
    setSelectedYear(option);
    getReport(option.year);
  };
  return (
    <section>
      <div className="flex h-screen overflow-hidden">
        {/* Sidebar */}
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        {/* Content area */}
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          {/*  Site header */}
          <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

          <main>
            <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
              {/* Page header */}
              <div className="sm:flex sm:justify-between sm:items-center mb-8">
                {/* Left: Title */}
                <div className="mb-4 sm:mb-0">
                  <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">
                    E-File Status Report {selectedYear && selectedYear.name}
                  </h1>
                </div>
                <div className="flex flex-col sm:flex-row sm:space-x-2 items-center">
                  <div className="flex flex-col items-start">
                    {selectedYear !== null && (
                      <SelectYearEfileStatusReportListBox
                        label={"Select year"}
                        onChange={onHandleChangeYear}
                        years={years}
                        selectedYear={selectedYear}
                      />
                    )}
                  </div>
                </div>
                {/* Right: Actions */}
              </div>
            </div>

            <div className="flex flex-row overflow-x-auto mb-12">
              {clientsByEfileStatuses.map((report, index) => {
                return (
                  <div className="w-full mx-4 p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-8  dark:border-gray-700 min-w-fit min-h-full">
                    <div className="flex items-center justify-between mb-4">
                      <h5 className="text-xl font-bold leading-none text-gray-900 dark:text-gray-800">
                        {EfileStatuses[report.efileStatus]}
                      </h5>
                      <p className="text-sm font-medium text-green-600 hover:underline dark:text-green-500">
                        Total: {report.clients.length}
                      </p>
                    </div>
                    <div className="flow-root">
                      <ul
                        className="divide-y divide-gray-200 dark:divide-gray-700"
                        key={index}
                      >
                        {report.clients.length > 0 ? (
                          report.clients.map((client, index) => (
                            <li className="py-3 sm:py-4" key={index}>
                              <div className="flex items-center">
                                <div className="flex-shrink-0"></div>
                                <div className="flex-1 min-w-0 ms-4">
                                  <p className="text-sm font-medium text-gray-900 truncate dark:text-gray-700">
                                    <a
                                      href={`/clients/service/${client.serviceId}/client/${client.id}`}
                                      rel="noreferrer"
                                    >
                                      Client: {client.name}
                                    </a>
                                  </p>
                                  <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                    Service Name: {client.serviceName}
                                  </p>
                                  <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                    Service Year: {client.serviceYear}
                                  </p>
                                </div>
                                {/* <div className="block-flex items-center text-base font-semibold text-gray-900 dark:text-gray-700">
                            Due date {moment(job.dueDate).format("MM-DD-YYYY")}
                          </div> */}
                              </div>
                            </li>
                          ))
                        ) : (
                          <li className="py-3 sm:py-4">
                            No services assigned to this E file Status.
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                );
              })}
            </div>
          </main>
        </div>
      </div>
    </section>
  );
};
export default EfileStatusReport;
