import axios from "axios";
import Cookies from "js-cookie";
import { FIRMFLOWURL } from "./url";
export const createEmployee = (form) => {
  return new Promise(async (resolve) => {
    try {
      const token = Cookies.get("Bearer");

      const response = await axios.post(
        `${FIRMFLOWURL}api/Employee/firm/employee`,
        {
          firstName: form.first_name,
          lastName: form.last_name,
          email: form.email,
          password: form.password,
          role: form.role,
        },
        {
          headers: { Authorization: token },
        }
      );
      resolve({ ...response, success: true });
    } catch (err) {
      console.log(err);
      if (err.message.includes(401)) {
        Cookies.remove("Bearer");
        window.location.replace("/");
        return;
      }
      resolve({ success: false, message: err.message });
    }
  });
};
