import { PlusIcon, TrashIcon } from "@heroicons/react/24/solid";

const LinkForm = ({
  LINK_URL,
  LINK_NAME,
  newLink,
  addLink,
  deleteLink,
  handleChange,
  inputLinksJSON,
  divWidth,
  title,
}) => {
  return (
    <div className={`mt-1 ${divWidth}`}>
      <label
        htmlFor="links"
        className="flex flex-col items-start justify-start w-full mt-4"
      >
        {title}
      </label>
      <div className="flex flex-col items-start justify-start w-full mt-4">
        <label
          htmlFor={LINK_NAME}
          className="block text-sm font-medium text-gray-700"
        >
          Name
        </label>
        <input
          onChange={(e) => handleChange(e)}
          name={LINK_NAME}
          type="text"
          className="shadow-sm border border-slate-200 rounded-sm p-1 px-2 w-full focus:ring-0 focus:outline-none"
          value={newLink[LINK_NAME]}
        />
      </div>

      <div className="flex flex-col items-start justify-start w-full mt-4">
        <label
          htmlFor={LINK_URL}
          className="block text-sm font-medium text-gray-700"
        >
          URL
        </label>
        <input
          name={LINK_URL}
          onChange={(e) => handleChange(e)}
          type="text"
          className="shadow-sm border border-slate-200 rounded-sm p-1 px-2 w-full focus:ring-0 focus:outline-none"
          value={newLink[LINK_URL]}
        />
        <button
          onClick={() => addLink()}
          type="button"
          className="inline-flex items-center p-1 border border-transparent rounded-sm shadow-sm text-white bg-teal-500 hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 mt-4"
        >
          <PlusIcon className="h-5 w-5" /> Click here to add link
        </button>
        <div className="bg-white shadow-lg rounded-sm border border-slate-200 relative mt-12 w-1/2">
          {inputLinksJSON.map((link) => (
            <div className="flex w-full justify-between items-center px-5 py-4 border-b border-slate-200">
              <a href={link[LINK_URL]} target="_blank" rel="noreferrer">
                {link[LINK_NAME]}
              </a>
              <button
                id="add-btn"
                onClick={() => deleteLink(link[LINK_NAME])}
                type="button"
                className="inline-flex items-center p-1 border border-transparent rounded-sm shadow-sm text-black hover:text-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              >
                <TrashIcon className="h-5 w-5" />
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LinkForm;
