const Badge = ({ bgColor, label }) => {
  return (
    <span
      className="inline-flex items-center rounded-full px-2.5 py-0.5 text-md font-medium text-white"
      style={{
        background: `${bgColor}`,
      }}
    >
      {label}
    </span>
  );
};

export default Badge;
