/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";

export default function DialogConfirmationForRemovingClients({
    showDialog,
    onCancel,
    onConfirm,
    title,
    description,
    clientSubscriptions = null,
}) {
    useEffect(() => {}, [clientSubscriptions]);
    return (
        <Transition.Root show={showDialog} as={Fragment}>
            <Dialog as="div" className="relative z-30" onClose={onCancel}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                                <div>
                                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-yellow-100">
                                        <ExclamationCircleIcon
                                            className="h-6 w-6 text-red-600"
                                            aria-hidden="true"
                                        />
                                    </div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <Dialog.Title
                                            as="h3"
                                            className="text-lg font-medium leading-6 text-gray-900"
                                        >
                                            {title}
                                        </Dialog.Title>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500">
                                                {description}
                                            </p>
                                        </div>

                                        <div className="overflow-auto max-h-screen">
                                            {clientSubscriptions !== null && (
                                                <p className="text-sm text-gray-500">
                                                    "The following subscriptions will
                                                    be affected:"
                                                </p>
                                            )}
                                            {
                                                <ul className="list-decimal text-justify text-xs m-5">
                                                    {clientSubscriptions !== null &&
                                                        clientSubscriptions.map(
                                                            (item) => (
                                                                <li>
                                                                    Service Name:{" "}
                                                                    {
                                                                        <span className="font-semibold">
                                                                            {
                                                                                item.serviceName
                                                                            }
                                                                        </span>
                                                                    }{" "}
                                                                    - Interval:{" "}
                                                                    <span className="font-semibold">
                                                                        {
                                                                            item.interval
                                                                        }
                                                                    </span>
                                                                </li>
                                                            )
                                                        )}
                                                </ul>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-6 flex items-center justify-between">
                                    <button
                                        type="button"
                                        className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-500 px-2 py-2 text-base font-medium text-white shadow-sm hover:bg-orange-600 focus:outline-none focus:ring-0 sm:text-sm mx-2"
                                        onClick={() => {
                                            onCancel();
                                        }}
                                    >
                                        Close
                                    </button>
                                    <button
                                        type="button"
                                        className="inline-flex w-full justify-center rounded-md border border-transparent bg-teal-500 px-2 py-2 text-base font-medium text-white shadow-sm hover:bg-teal-600 focus:outline-none focus:ring-0 sm:text-sm mx-2"
                                        onClick={() => {
                                            onConfirm();
                                        }}
                                    >
                                        Confirm
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
