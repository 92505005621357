import React, { useEffect, useState } from "react";
import { Toaster, toast } from "react-hot-toast";
import Breadcrumbs from "../../components/Breadcrumbs";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import { getJobs } from "../../services/jobs/getJobs";
import { addServiceWithJobs } from "../../services/service/addServiceWithJobs";
import { getServiceDepartments } from "../../services/service/getServiceDepartments";
import { getServiceTasksByJob } from "../../services/serviceTasks/getServiceTasksByJob";
import CheckboxForServices from "../ServiceForm/CheckboxForServices";
import DepartmentListbox from "./DepartmentListBox";
import JobsListBox from "./JobsListBox";
import { getNewLoggedInEmployeeFunction } from "../../helpers/getLoggedInEmployeeFunction";

function CreateNewServiceWithJobs() {
  const [service, setService] = useState({
    name: "",
    isEfileStatusActive: false,
    IsEveryItemRenewable: false,
    department: null,
  });
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState({});
  const [jobs, setJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState({ name: null });
  const [loggedInEmployee, setLoggedInEmployee] = useState(null);

  useEffect(() => {
    getNewDepartments();
    getNewJobs();
    getNewLoggedInEmployeeFunction(setLoggedInEmployee);
  }, []);

  const getNewDepartments = async () => {
    const response = await getServiceDepartments();
    if (response.success) {
      setDepartments(response.data.departments);
      setSelectedDepartment(response.data.departments[0]);
    } else {
      toast.error(response.data.message);
    }
  };

  const getNewJobs = async () => {
    const response = await getJobs();
    if (response.success) {
      console.log(response.data.jobs);
      setJobs(response.data.jobs);
      /* setSelectedJob(response.data.jobs[0]); */
    } else {
      toast.error(response.data.message);
    }
  };

  const getNewTasks = async (jobId) => {
    const response = await getServiceTasksByJob(jobId);
    if (response.success) {
      setTasks(response.data);
    } else {
      toast.error(response.message);
    }
  };

  const IntialStates = () => {
    setService({
      name: "",
      isEfileStatusActive: false,
      IsEveryItemRenewable: false,
    });
    setTasks([]);
  };

 /*  const addNewJob = () => {
    setTasks([
      ...tasks,
      {
        id: tasks.length,
        name: "",
        editing: true,
        checklistItems: [{ id: 0, name: "", editing: true }],
      },
    ]);
  }; */

  const onHandleChangeEfileStatus = (e) => {
    setService({ ...service, isEfileStatusActive: e.target.checked });
  };

  const onHandleChangeIsEveryItemRenewable = (e) => {
    setService({ ...service, IsEveryItemRenewable: e.target.checked });
  };

  const onHandleChangeDepartment = (e) => {
    setSelectedDepartment(e);
  };

  const onHandleChangeJob = (e) => {
    setSelectedJob(e);
    getNewTasks(e.id);
  };

  const saveDetails = async () => {
    if(loggedInEmployee.role === 0){
      toast.error("You don't have permission to do this");
      return;
    }
    if (service.name == "") {
      toast.error("Service name can't be empty");
      return;
    }
    if (selectedDepartment == null) {
      toast.error("You need to choose a department");
      return;
    }
    if (selectedJob.name == null) {
      toast.error("You need to add at least one job.");
      return;
    }
    const newJobs = tasks
      .filter((job) => job.editing == false)
      .map((job) => {
        return {
          name: job.name,
          order: job.id,
          checklistItems: job.checklistItems,
        };
      });

    const servicesWithJobs = {
      serviceName: service.name,
      serviceIsEfileStatusActive: service.isEfileStatusActive,
      IsEveryItemRenewable: service.IsEveryItemRenewable,
      serviceDepartment: selectedDepartment.id,
      jobId: selectedJob.id,
      tasks: newJobs,
    };

    const response = await addServiceWithJobs(servicesWithJobs);
    if (response.data.success) {
      toast.success(response.data.message);
      IntialStates();
    } else {
      toast.error(response.data.message);
    }
    console.log(servicesWithJobs);
  };

  return (
    <section>
      <Toaster />
      <div className="flex h-screen overflow-hidden">
        {/* Sidebar */}
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        {/* Content area */}
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          {/*  Site header */}
          <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

          <main>
            <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
              <div className="flex flex-col items-start max-w-2xl">
                <Breadcrumbs
                  home="/departments"
                  pages={[
                    {
                      name: "Service",
                      href: `/templates/services/create`,
                      current: true,
                    },
                  ]}
                />

                {/* Page header */}
                <div className="sm:flex sm:justify-between sm:items-center mb-8 w-full mt-12">
                  {/* Left: Title */}
                  <div className="mb-4 sm:mb-0">
                    <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">
                      New Service
                    </h1>
                  </div>
                </div>

                <div className="bg-white shadow-lg rounded-sm border border-slate-200 relative w-full">
                  <header className="px-5 py-4 border-b border-slate-200">
                    <h2 className="font-semibold text-slate-800">Details</h2>
                  </header>

                  <div className="space-y-5 px-5 py-4">
                    <div>
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Service Template Name
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          onChange={(e) =>
                            setService({ ...service, name: e.target.value })
                          }
                          value={service.name}
                          className="shadow-sm border border-slate-200 rounded-sm p-1 px-2 w-full max-w-sm focus:ring-0 focus:outline-none"
                        />
                      </div>
                    </div>
                    <div className="mt-1">
                      <DepartmentListbox
                        label={"Department"}
                        departments={departments}
                        selectedDepartment={selectedDepartment}
                        onChangeDepartment={(e) => onHandleChangeDepartment(e)}
                      />
                    </div>
                    <div className="mt-1">
                      <JobsListBox
                        label={"Jobs"}
                        items={jobs}
                        selectedItem={selectedJob}
                        onChangeItem={(e) => onHandleChangeJob(e)}
                      />
                    </div>
                    <CheckboxForServices
                      name="Add E-File Status"
                      value={service.isEfileStatusActive}
                      message="Check this box to display E-File Status in subscriptions."
                      checked={service.isEfileStatusActive}
                      onChange={(e) => onHandleChangeEfileStatus(e)}
                    />
                    <CheckboxForServices
                      name="Make tasks renewable automatically"
                      value={service.IsEveryItemRenewable}
                      message="Check this box to renew the service automatically."
                      checked={service.IsEveryItemRenewable}
                      onChange={(e) => onHandleChangeIsEveryItemRenewable(e)}
                    />
                    {/*  <ServiceStatusesListBox
                        label="Service status *"
                        serviceStatuses={serviceStatuses}
                        selectedServiceStatus={service.serviceState}
                        onChange={onHandleChangeStatus}
                      /> */}
                  </div>
                </div>

                <div className="bg-white shadow-lg rounded-sm border border-slate-200 relative mt-12 w-full">
                  <header className="flex w-full justify-between items-center px-5 py-4 border-b border-slate-200">
                    <h2 className="font-semibold text-slate-800">
                      Tasks assigned to current Job
                    </h2>
                  </header>

                  {tasks.map((task) => (
                    <div className="m-2 text-sm w-full pl-4 py-2">
                      <div className="flex w-full items-center">
                        <h1
                          htmlFor="comments"
                          className="w-full font-medium text-gray-700"
                        >
                          {task.name}
                        </h1>
                      </div>
                    </div>
                  ))}

                  {/*   <CreateJobsForServiceSection
                    jobs={tasks}
                    setJobs={setTasks}
                  /> */}
                </div>
                <div className="w-full flex justify-end">
                  <button
                    onClick={() => saveDetails()}
                    className="z-50 mt-4 ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-sm text-white bg-teal-500 hover:bg-teal-600 focus:outline-none focus:ring-0"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </section>
  );
}
export default CreateNewServiceWithJobs;
