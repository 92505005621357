import { Dialog } from "@headlessui/react";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { FIRMFLOW_NAMES } from "../../../helpers/firmFlowNames";
import { getEmployees } from "../../../services/getEmployees";
import InputTextModal from "../modalGeneralComponents/InputTextModal";
import AssignedEmployeesListBox from "../updateClientServiceSubscriptionTaskModal/AssignedEmployeesListBox";
import ButtonModal from "../updateClientServiceSubscriptionTaskModal/ButtonModal";
import ErrorMessage from "../updateClientServiceSubscriptionTaskModal/ErrorMessage";

const AddNewClientServiceSubscriptionTask = ({
  open,
  setOpen,
  AddNewTask,
  clientServiceId,
}) => {
  const [employees, setEmployees] = useState([]);
  const [newClientServiceSubscriptionTask, setNewClientServiceSubsriptionTask] =
    useState({ name: "", assignedEmployees: [] });
  const [errors, setErrors] = useState({});

  let completeButtonRef = useRef(null);

  const isObjectEmpty = (objectName) => {
    return Object.keys(objectName).length === 0;
  };

  const cleanInputs = () => {
    setNewClientServiceSubsriptionTask({
      name: "",
      assignedEmployees: [],
      serviceStatus: {},
    });
    setErrors({});
  };

  /* const onHandleChangeServiceStatus = (newServiceStatus) => {
    if (isObjectEmpty(newServiceStatus)) {
      setErrors({
        ...errors,
        serviceStatus: "This field cannot be empty.",
      });
    } else {
      setErrors({});
    }
    setNewClientServiceSubsriptionTask({
      ...newClientServiceSubscriptionTask,
      serviceStatus: newServiceStatus,
    });
  };
 */
  const onHandleChange = (e) => {
    const fieldName = e.target.name;
    const fieldValue = e.target.value;
    setNewClientServiceSubsriptionTask({
      ...newClientServiceSubscriptionTask,
      [fieldName]: fieldValue,
    });
  };
  const onHandleCancel = () => {
    cleanInputs();
    setOpen(false);
  };
  const onHandleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm(newClientServiceSubscriptionTask)) {
      await AddNewTask(newClientServiceSubscriptionTask);
      cleanInputs();
    }
  };

  const onHandleChangeAssignedEmployees = (newAssignedEmployees) => {
    if (isObjectEmpty(newAssignedEmployees)) {
      setErrors({
        ...errors,
        assignedEmployees: "This field cannot be empty.",
      });
    } else {
      setErrors({});
    }
    setNewClientServiceSubsriptionTask({
      ...newClientServiceSubscriptionTask,
      assignedEmployees: newAssignedEmployees,
    });
  };

  const validateForm = (form) => {
    let validation = true;
   /*  if (isObjectEmpty(form.serviceStatus)) {
      setErrors({ ...errors, serviceStatus: "This field cannot be empty." });
      validation = false;
    } */
    if (isObjectEmpty(form.assignedEmployees)) {
      setErrors({
        ...errors,
        assignedEmployees: "This field cannot be empty.",
      });
      validation = false;
    }
    console.log(errors);
    return validation;
  };

  const getNewEmployees = async () => {
    const response = await getEmployees();
    setEmployees(response.data.employees);
  };

  /* const getServiceStatuses = async () => {
    const response = await getClientServiceSubscriptionStateItemsByServiceId(
      clientServiceId
    );
    setServiceStatuses(response.data.serviceStatuses);
  }; */
  useEffect(() => {
    getNewEmployees();
/*     getServiceStatuses();
 */  }, []);

  return (
    <AnimatePresence>
      {open && (
        <Dialog
          initialFocus={completeButtonRef}
          static
          as={motion.div}
          open={open}
          onClose={() => setOpen(false)}
          className="relative z-50"
        >
          {/* The backdrop, rendered as a fixed sibling to the panel container */}
          <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

          {/* Full-screen scrollable container */}
          <div className="fixed inset-0 w-screen overflow-y-auto">
            {/* Container to center the panel */}
            <div className="flex min-h-full items-center justify-center p-4">
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg w-full sm:p-6">
                <Dialog.Title className="text-center w-full text-lg leading-6 font-medium text-gray-900">
                  Add Custom {FIRMFLOW_NAMES.CLIENT_SERVICE_SUBSCRIPTON_TASK}
                </Dialog.Title>

                <form onSubmit={onHandleSubmit}>
                  <InputTextModal
                    required={true}
                    label="Name *"
                    name="name"
                    onChange={onHandleChange}
                    value={newClientServiceSubscriptionTask.name}
                  />

                  <AssignedEmployeesListBox
                    title="Assigned Employees *"
                    employees={employees}
                    assignedEmployees={
                      newClientServiceSubscriptionTask.assignedEmployees
                    }
                    onChange={onHandleChangeAssignedEmployees}
                  />
                  <ErrorMessage error={errors["assignedEmployees"]} />

                  {/* <ServiceStatusesListBox
                    label="Service Status *"
                    serviceStatuses={serviceStatuses}
                    selectedServiceStatus={
                      newClientServiceSubscriptionTask.serviceStatus
                    }
                    onChange={onHandleChangeServiceStatus}
                  />
                  <ErrorMessage error={errors["serviceStatus"]} /> */}

                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                    <ButtonModal
                      name="Save"
                      type="submit"
                      className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-teal-500 text-base font-medium text-white hover:bg-teal-600 focus:outline-none focus:ring-0 sm:col-start-2 sm:text-sm"
                    />
                    <ButtonModal
                      name="Cancel"
                      type="button"
                      onClick={onHandleCancel}
                      className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-0 sm:mt-0 sm:col-start-1 sm:text-sm"
                    />
                  </div>
                </form>
              </Dialog.Panel>
            </div>
          </div>
        </Dialog>
      )}
    </AnimatePresence>
  );
};

export default AddNewClientServiceSubscriptionTask;
