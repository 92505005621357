import axios from "axios";
import Cookies from "js-cookie";
import { FIRMFLOWURL } from "./url";
export const updateAllTaskSubscriptionCheckListItems = (
  subscription_id,
  task_id,
  isChecked
) => {
  return new Promise(async (resolve) => {
    try {
      const token = Cookies.get("Bearer");
      const response = await axios.put(
        `${FIRMFLOWURL}api/ClientSubscription/${subscription_id}/task/${task_id}/checklist/${isChecked}`,
        null,
        {
          headers: { Authorization: token },
        }
      );
      resolve(response);
    } catch (err) {
      console.log(err);
      if (err.message.includes(401)) {
        Cookies.remove("Bearer");
        window.location.replace("/");
        return;
      }
      resolve({ success: false, message: err });
    }
  });
};
