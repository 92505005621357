import axios from "axios"
import Cookies from "js-cookie"
import { FIRMFLOWURL } from "./url";
export const createChecklistItem = (form) => {
    return new Promise(async resolve => {
        try {
            const token = Cookies.get('Bearer');
            const response = await axios.post(`${FIRMFLOWURL}api/ServiceTaskChecklist/service-task/${form.task_id}`,
                {
                    description: form.description,
                    links: form.links
                },
                {
                    headers: { 'Authorization': token }
                }
            )
            resolve(response);
        } catch (err) {
            console.log(err)
            if(err.message.includes(401)){
                Cookies.remove('Bearer');
                window.location.replace('/');
                return;
            }
            resolve({ success: false, message: err });
        }
    })
}