import { ClipboardDocumentIcon } from "@heroicons/react/24/solid";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { toast, Toaster } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import Breadcrumbs from "../../components/Breadcrumbs";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import AddSubscriptionModal from "../../components/client/AddSubscriptionModal";
import CloneSubscriptionModal from "../../components/client/CloneSubscriptionModal";
import AssignEmployeToTaskWhenServiceStarts from "../../components/clientServiceSubscriptionTask/updateClientServiceSubscriptionTaskModal/AssignEmployeeToTaskWhenServiceStarts";
import FirmFlowTable from "../../components/mytasks/FirmFlowTable";
import { deleteSubscription } from "../../services/deleteSubscription";
import { getClient } from "../../services/getClient";
import { getLoggedInEmployee } from "../../services/getLoggedInEmployee";
import { getServices } from "../../services/getServices";
import { getSubscriptions } from "../../services/getSubscriptions";
import DeleteModal from "../../utils/DeleteModal";
import { getDueDateColor } from "./../../helpers/getDueDateColor";
import UpdateClientModal from "./UpdateClientModal";
import NewBadge from "../../components/NewBadge";

function ManageClients() {
    const params = useParams();
    const navigate = useNavigate();
    const [client, setClient] = useState(null);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [list, setList] = useState([]);
    const [services, setServices] = useState([
        { id: 1, name: "Add some services first" },
    ]);
    const [open, setOpen] = useState(false);
    const [openCloneSubscriptionModal, setOpenCloneSubscriptionModal] =
        useState(false);
    const [loading, setLoading] = useState(true);
    const [tooltip, showTooltip] = useState(true);
    const [clientServiceId, setClientServiceId] = useState(0);
    const [openEditClientModal, setOpenEditClientModal] = useState(false);
    const [loggedInEmployee, setLoggedInEmployee] = useState({});
    const [isStartServiceModalOpen, setIsStartServiceModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [subscriptionIdToDelete, setSubscriptionIdToDelete] = useState(null);

    //Set initial list
    useEffect(() => {
        callApi();
        getNewLoggedInEmployee();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getNewLoggedInEmployee = async () => {
        const currentEmployee = await getLoggedInEmployee();
        if (currentEmployee.success) {
            setLoggedInEmployee(currentEmployee.data.employeeResponse);
            console.log(currentEmployee);
        }
    };

    const callApi = async (e) => {
        //Get clients
        Promise.all([getClient(params.client_id), getServices()]).then((data) => {
            // eslint-disable-next-line
            if (data[0].success) {
                console.log(data[0].data);
                setClient(data[0].data);
            }
            if (data[1].success) {
                const arr = [...data[1].data];
                arr.map((service) => {
                    service.is_showing = true;
                });
                setServices(arr);
                console.log(arr);
            }
        });
    };

    useEffect(() => {
        //Fetch subscriptions on client change
        fetchClientSubscriptions();
        // eslint-disable-next-line
    }, [client]);

    async function fetchClientSubscriptions() {
        if (client.id === 0) return;
        const arr = [];
        const subscriptions = await getSubscriptions(client.id);
        console.log(subscriptions);
        subscriptions.data.clientServiceSubscriptions.map((sub) => {
            /* sub.subscriptionStartDate = new Date(sub.subscriptionStartDate)
        .toDateString()
        .substring(4);
      sub.subscriptionEndDate = new Date(sub.subscriptionEndDate)
        .toDateString()
        .substring(4); */
            sub.clientId = client.id;
            sub.color = getDueDateColor(sub.dueDateRange);
            arr.push(sub);
        });
        setList(arr);
        console.log("list before", arr);
        setLoading(false);
    }

    const newClientService = async () => {
        fetchClientSubscriptions();
        setIsStartServiceModalOpen(false);
        navigate(`/clients/service/${clientServiceId}/client/${client.id}`);
    };

    /* const onHandleUpdateServiceStatus = async (clientServiceSubscriptionId) => {
    const response = await updateClientServiceStatus(
      clientServiceSubscriptionId,
      1
    );
    console.log(response);
    if (response.success) {
      toast.success(response.data.message);

      fetchClientSubscriptions();
    } else {
      toast.error(response.data.message);
    }
  }; */

    const onHandleRenewService = async (clientServiceId) => {
        setClientServiceId(clientServiceId);
        setOpenCloneSubscriptionModal(true);
    };

    const onClickRemoveSubscription = (subscriptionId) => {
        setIsDeleteModalOpen(true);
        setSubscriptionIdToDelete(subscriptionId);
    };

    const removeSubscription = async () => {
        const res = await deleteSubscription(subscriptionIdToDelete);
        console.log(res);
        if (res.data.success) {
            callApi();
            toast.success(res.data.message);
        } else {
            toast.error(res.data.message);
        }
        setIsDeleteModalOpen(false);
    };

    const onHandleClickStartService = (clientId) => {
        setClientServiceId(clientId);
        setIsStartServiceModalOpen(true);
    };

    const onHandleClickEditClientModal = () => {
        setOpenEditClientModal(true);
    };

    return (
        <section>
            <Toaster />
            <div className="flex h-screen overflow-hidden">
                {/* Modal */}
                <DeleteModal
                    isModalOpen={isDeleteModalOpen}
                    setIsModalOpen={setIsDeleteModalOpen}
                    onRemove={removeSubscription}
                    subscriptionIdToDelete={subscriptionIdToDelete}
                />
                <AssignEmployeToTaskWhenServiceStarts
                    open={isStartServiceModalOpen}
                    setOpen={setIsStartServiceModalOpen}
                    loggedInEmployee={loggedInEmployee}
                    clientSubscriptionId={clientServiceId}
                    newClientService={newClientService}
                />
                <CloneSubscriptionModal
                    setOpen={setOpenCloneSubscriptionModal}
                    open={openCloneSubscriptionModal}
                    clientServiceId={clientServiceId}
                    refreshSubs={() => fetchClientSubscriptions()}
                />
                <AddSubscriptionModal
                    open={open}
                    setOpen={setOpen}
                    services={services}
                    setServices={setServices}
                    refreshSubs={() => fetchClientSubscriptions()}
                    client={client}
                />
                <UpdateClientModal
                    open={openEditClientModal}
                    setOpen={setOpenEditClientModal}
                    client={client}
                    setClient={setClient}
                />
                {/* Sidebar */}
                <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                {/* Content area */}
                <div
                    className={
                        "relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden"
                    }
                >
                    {/*  Site header */}
                    <Header
                        sidebarOpen={sidebarOpen}
                        setSidebarOpen={setSidebarOpen}
                    />

                    <main>
                        {!loading ? (
                            <div
                                className={
                                    "px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto"
                                }
                            >
                                <Breadcrumbs
                                    home="/clients"
                                    pages={[
                                        {
                                            name: `${client.clientName}`,
                                            href: ``,
                                            current: true,
                                        },
                                    ]}
                                />
                                {/* Page header */}
                                <div className="sm:flex sm:justify-between sm:items-center mb-8">
                                    {/* Left: Title */}
                                    <div className="mb-4 sm:mb-0">
                                        <h1 className="text-2xl md:text-3xl text-slate-800">
                                            <span className="font-bold">
                                                Manage Client:
                                            </span>{" "}
                                            {client.clientName}
                                        </h1>
                                        <p className="text-md md:text-md text-slate-800">
                                            <span className="font-bold">Email:</span>{" "}
                                            {client?.clientDetail?.clientEmail}
                                        </p>
                                        <p className="text-md md:text-md text-slate-800">
                                            <span className="font-bold">Type:</span>{" "}
                                            {client?.clientDetail?.clientType}
                                        </p>
                                        <p className="text-md md:text-md text-slate-800">
                                            <span className="font-bold">Phone:</span>{" "}
                                            {client?.phoneNumber}
                                        </p>
                                        <p className="text-md md:text-md text-slate-800">
                                            <span className="font-bold">Tax#:</span>{" "}
                                            {client?.taxNumber}
                                        </p>
                                        <p className="text-md md:text-md text-slate-800">
                                            <span className="font-bold">SSN:</span>{" "}
                                            {client?.clientDetail.ssn}
                                        </p>
                                        <p className="text-md md:text-md text-slate-800">
                                            <span className="font-bold">
                                                Address:
                                            </span>{" "}
                                            {client?.address?.street}{" "}
                                            {client?.address?.city},{" "}
                                            {client?.address?.stateProvince}{" "}
                                            {client?.address?.country},{" "}
                                            {client?.address?.postalCode}
                                        </p>
                                        <p className="text-md md:text-md text-slate-800">
                                            <span className="font-bold">
                                                Language:
                                            </span>{" "}
                                            {client?.clientDetail?.language}
                                        </p>
                                        <p className="text-md md:text-md text-slate-800">
                                            <span className="font-bold">
                                                Preferred method of contact:
                                            </span>{" "}
                                            {client?.clientDetail?.contactMethod}
                                        </p>
                                        <p className="text-md md:text-md text-slate-800">
                                            <span className="font-bold">
                                                Is Meeting Required?:
                                            </span>{" "}
                                            {client.clientDetail.isMeetingRequired
                                                ? "Yes"
                                                : "No"}
                                        </p>
                                        <p className="text-md md:text-md text-slate-800">
                                            {client?.clientDetail?.clientType ===
                                            "BUSINESS" ? (
                                                <>
                                                    <p className="text-md md:text-md text-slate-800">
                                                        <span className="font-bold">
                                                            Name of main point of
                                                            contact:
                                                        </span>{" "}
                                                        {
                                                            client?.clientDetail
                                                                ?.pointOfContactName
                                                        }{" "}
                                                    </p>
                                                    <p className="text-md md:text-md text-slate-800">
                                                        <span className="font-bold">
                                                            Email of main point of
                                                            contact:
                                                        </span>{" "}
                                                        {
                                                            client?.clientDetail
                                                                ?.pointOfContactEmail
                                                        }{" "}
                                                    </p>
                                                    <p className="text-md md:text-md text-slate-800">
                                                        <span className="font-bold">
                                                            Name of secondary point
                                                            of contact:
                                                        </span>{" "}
                                                        {
                                                            client?.clientDetail
                                                                ?.secondaryPointOfContactName
                                                        }{" "}
                                                    </p>
                                                    <p className="text-md md:text-md text-slate-800">
                                                        <span className="font-bold">
                                                            Email of secondary point
                                                            of contact:
                                                        </span>{" "}
                                                        {
                                                            client?.clientDetail
                                                                ?.secondaryPointOfContactEmail
                                                        }
                                                    </p>
                                                </>
                                            ) : client?.clientDetail?.clientType ===
                                              "INDIVIDUAL" ? (
                                                <>
                                                    <p className="text-md md:text-md text-slate-800">
                                                        <span className="font-bold">
                                                            Spouse Name:
                                                        </span>{" "}
                                                        {
                                                            client?.clientDetail
                                                                ?.spouseName
                                                        }{" "}
                                                    </p>
                                                    <p className="text-md md:text-md text-slate-800">
                                                        <span className="font-bold">
                                                            Spouse Email:
                                                        </span>{" "}
                                                        {
                                                            client?.clientDetail
                                                                ?.spouseEmail
                                                        }
                                                    </p>
                                                    <p className="text-md md:text-md text-slate-800">
                                                        <span className="font-bold">
                                                            Spouse Tax#:
                                                        </span>{" "}
                                                        {
                                                            client?.clientDetail
                                                                ?.spouseTaxNumber
                                                        }
                                                    </p>
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                        </p>
                                        {loggedInEmployee.role == 1 && (
                                            <button
                                                onClick={() =>
                                                    onHandleClickEditClientModal()
                                                }
                                                className="btn bg-teal-500 hover:bg-teal-600 text-white h-10"
                                            >
                                                Edit Client
                                            </button>
                                        )}
                                    </div>

                                    {/* Right: Actions */}
                                    <div className="grid grid-flow-col sm:auto-cols-max justify-between sm:justify-end items-end gap-2">
                                        {/* subscribe button */}
                                        <button
                                            onClick={() => setOpen(true)}
                                            className="btn bg-teal-500 hover:bg-teal-600 text-white h-10"
                                        >
                                            Subscribe Service to Client
                                        </button>
                                    </div>
                                </div>

                                {/* Table */}

                                <FirmFlowTable
                                    columns={[
                                        {
                                            Header: "Service",
                                            accessor: "serviceName",
                                            Cell: (props) => (
                                                <>
                                                    {tooltip && (
                                                        <ReactTooltip
                                                            type="light"
                                                            effect="solid"
                                                            place="top"
                                                            border={true}
                                                            borderColor={
                                                                "rgb(220,220,220)"
                                                            }
                                                            className="bg-gray-50 rounded-md"
                                                            multiline={false}
                                                        />
                                                    )}
                                                    <button
                                                        onMouseEnter={() =>
                                                            showTooltip(true)
                                                        }
                                                        onMouseLeave={() => {
                                                            showTooltip(false);
                                                        }}
                                                        data-tip="Go to service"
                                                        onClick={() =>
                                                            navigate(
                                                                `/clients/service/${props.row.original.clientServiceSubscriptionId}/client/${client.id}`
                                                            )
                                                        }
                                                        className="text-gray hover:text-blue-500 rounded-full"
                                                    >
                                                        {props.row.original.isNew ===
                                                        true ? (
                                                            <span>
                                                                <NewBadge label="New" />{" "}
                                                                {
                                                                    props.row
                                                                        .original
                                                                        .serviceName
                                                                }
                                                            </span>
                                                        ) : (
                                                            props.row.original
                                                                .serviceName
                                                        )}
                                                    </button>
                                                </>
                                            ),
                                        },
                                        {
                                            Header: "Service Year",
                                            accessor: "serviceYear",
                                            Cell: (props) => (
                                                <a
                                                    className="text-gray hover:text-blue-500 rounded-full"
                                                    href={`/clients/service/${props.row.original.clientServiceSubscriptionId}/client/${client.id}`}
                                                >
                                                    {props.value}
                                                </a>
                                            ),
                                        },
                                        {
                                            Header: "Client",
                                            accessor: "clientName",
                                            Cell: (props) => (
                                                <a
                                                    className="text-gray hover:text-blue-500 rounded-full"
                                                    href={`/clients/service/${props.row.original.clientServiceSubscriptionId}/client/${client.id}`}
                                                >
                                                    {props.value}
                                                </a>
                                            ),
                                        },
                                        {
                                            Header: "Assigned Date",
                                            accessor: "subscriptionStartDate",
                                            Cell: (props) => (
                                                <a
                                                    className="text-gray hover:text-blue-500 rounded-full"
                                                    href={`/clients/service/${props.row.original.clientServiceSubscriptionId}/client/${client.id}`}
                                                >
                                                    {new Date(props.value)
                                                        .toDateString()
                                                        .substring(4)}
                                                </a>
                                            ),
                                        },
                                        {
                                            Header: "Due Date",
                                            accessor: "subscriptionEndDate",
                                            Cell: (props) => (
                                                <a
                                                    className={`inline-flex items-center rounded-full px-2.5 py-0.5 text-md font-medium text-white `}
                                                    style={{
                                                        background: `${props.row.original.color}`,
                                                    }}
                                                    href={`/clients/service/${props.row.original.clientServiceSubscriptionId}/client/${client.id}`}
                                                >
                                                    {new Date(props.value)
                                                        .toDateString()
                                                        .substring(4)}
                                                </a>
                                            ),
                                        },
                                        {
                                            Header: "Assigned to",
                                            accessor: "assignedEmployee",
                                            Cell: (props) => (
                                                <a
                                                    className="text-gray hover:text-blue-500 rounded-full"
                                                    href={`/clients/service/${props.row.original.clientServiceSubscriptionId}/client/${client.id}`}
                                                >
                                                    {props.value}
                                                </a>
                                            ),
                                        },
                                        {
                                            Header: "Status",
                                            accessor: "status",
                                            Cell: (props) => (
                                                <td>
                                                    {
                                                        <a
                                                            className={`inline-flex items-center rounded-full px-2.5 py-0.5 text-sm font-medium text-white ${
                                                                props.value ===
                                                                "To Do"
                                                                    ? "bg-red-500"
                                                                    : props.value ==
                                                                      "In Progress"
                                                                    ? "bg-yellow-500"
                                                                    : "bg-teal-500"
                                                            }`}
                                                            href={`/clients/service/${props.row.original.clientServiceSubscriptionId}/client/${client.id}`}
                                                        >
                                                            {props.value}
                                                        </a>
                                                    }
                                                    {props.row.original.status ==
                                                        "To Do" && (
                                                        <button
                                                            className="mx-2 inline-flex items-center p-1.5 border border-transparent rounded-sm shadow-sm text-white bg-teal-500 hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
                                                            onClick={() =>
                                                                onHandleClickStartService(
                                                                    props.row
                                                                        .original
                                                                        .clientServiceSubscriptionId
                                                                )
                                                            }
                                                        >
                                                            Start
                                                        </button>
                                                    )}
                                                </td>
                                            ),
                                        },
                                        {
                                            Header: "Actions",
                                            accessor: "clientServiceSubscriptionId",
                                            Cell: (props) => (
                                                <td
                                                    onMouseEnter={() =>
                                                        showTooltip(true)
                                                    }
                                                    onMouseLeave={() => {
                                                        showTooltip(false);
                                                    }}
                                                >
                                                    {tooltip && (
                                                        <ReactTooltip
                                                            type="light"
                                                            effect="solid"
                                                            place="top"
                                                            border={true}
                                                            borderColor={
                                                                "rgb(220,220,220)"
                                                            }
                                                            className="bg-gray-50 rounded-md"
                                                            multiline={false}
                                                        />
                                                    )}
                                                    <button
                                                        data-tip="View"
                                                        onClick={() => {
                                                            /*  navigate(
                                `/clients/manage/${props.row.original.clientId}/subscription/${props.value}`
                              ); */
                                                            navigate(
                                                                `/clients/service/${props.value}/client/${client.id}`
                                                            );
                                                        }}
                                                        className="text-slate-400 hover:text-slate-500 rounded-full m-4"
                                                    >
                                                        <span className="sr-only">
                                                            Menu
                                                        </span>
                                                        <svg
                                                            className="w-8 h-8 fill-current"
                                                            viewBox="0 0 32 32"
                                                        >
                                                            <circle
                                                                cx="16"
                                                                cy="16"
                                                                r="2"
                                                            />
                                                            <circle
                                                                cx="10"
                                                                cy="16"
                                                                r="2"
                                                            />
                                                            <circle
                                                                cx="22"
                                                                cy="16"
                                                                r="2"
                                                            />
                                                        </svg>
                                                    </button>
                                                    <button
                                                        className="m-4"
                                                        data-tip="Clone"
                                                        onClick={() =>
                                                            onHandleRenewService(
                                                                props.value
                                                            )
                                                        }
                                                    >
                                                        <ClipboardDocumentIcon className="h-5 w-5" />
                                                    </button>

                                                    {loggedInEmployee.role == 1 && (
                                                        <button
                                                            className="m-4"
                                                            data-tip="Delete"
                                                            onClick={() =>
                                                                onClickRemoveSubscription(
                                                                    props.value
                                                                )
                                                            }
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 24 24"
                                                                fill="currentColor"
                                                                className="w-7 h-7 text-slate-400 hover:text-red-500 p-1 pt-0"
                                                            >
                                                                <path
                                                                    fillRule="evenodd"
                                                                    d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 01-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z"
                                                                    clipRule="evenodd"
                                                                />
                                                            </svg>
                                                        </button>
                                                    )}
                                                </td>
                                            ),
                                        },
                                    ]}
                                    data={list}
                                />
                            </div>
                        ) : (
                            <div className="w-full mt-64 flex justify-center items-center max-w-9xl">
                                <motion.div
                                    className="border border-t-gray-700 border-t-[4px] rounded-full border-gray-200 w-12 h-12"
                                    initial={{ rotateZ: 0 }}
                                    animate={{ rotateZ: 360 }}
                                    exit={{ rotateZ: 0 }}
                                    transition={{
                                        repeat: Infinity,
                                        ease: "linear",
                                        duration: 1,
                                    }}
                                ></motion.div>
                            </div>
                        )}
                    </main>
                </div>
            </div>
        </section>
    );
}
export default ManageClients;
