import { PlusIcon } from "@heroicons/react/24/solid";
import React, { useState } from "react";
import { Toaster } from "react-hot-toast";
import { useParams } from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import { createChecklistItem } from "../../services/createChecklistItem";
import { updateChecklistItem } from "../../services/updateChecklistItem";
import CreateNewChecklistItemFormSection from "../TaskForm/CreateNewChecklistFormSection";
import AddNewItemToJobModal from "./AddNewItemToJobModal";
import toast from "react-hot-toast";
import { addServiceJobWithChecklistItems } from "../../services/serviceTasks/addServiceJobWithChecklistItems";

function CreateJobV2() {
    const params = useParams();

    const [job, setJob] = useState({ name: "", checklistItems: [] });
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [inputCheckList, setInputCheckList] = useState([]);
    const [openAddItem, setOpenAddItem] = useState(false);

    const removeChecklistItem = (checklistItemId) => {
        const newChecklist = job.checklistItems.filter(
            (item) => item.id !== checklistItemId
        );
        setJob({ ...job, checklistItems: newChecklist });
    };

    const InitializeForm = () => {
        setJob({ name: "", checklistItems: [] });
        setInputCheckList([]);
    };

    const onSave = async () => {
        if (job.name === "") {
            toast.error("Name is required.");
            return;
        }
        if (job.checklistItems.length === 0) {
            toast.error("At least one checklist item is required.");
            return;
        }

        const jobWithLinksInJson = { ...job };

        const checklistItemsWithLinksInString = job.checklistItems.map(
            (checklistItem) => {
                return {
                    ...checklistItem,
                    links: JSON.stringify(checklistItem.links),
                };
            }
        );

        const jobToAdd = { ...job, checklistItems: checklistItemsWithLinksInString };

        const response = await addServiceJobWithChecklistItems(
            params.jobId,
            jobToAdd
        );
        if (response.success) {
            toast.success("Job created successfully.");
            InitializeForm();
        } else {
            toast.error("Failed to create job.");
            setJob(jobWithLinksInJson);
        }
    };

    const onClickAddItem = () => {
        setOpenAddItem(true);
    };

    return (
        <section>
            <AddNewItemToJobModal
                open={openAddItem}
                setOpen={setOpenAddItem}
                createChecklistItem={createChecklistItem}
                job={job}
                setJob={setJob}
            />
            <Toaster />
            <div className="flex h-screen overflow-hidden">
                {/* Sidebar */}
                <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                {/* Content area */}
                <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                    {/*  Site header */}
                    <Header
                        sidebarOpen={sidebarOpen}
                        setSidebarOpen={setSidebarOpen}
                    />

                    <main>
                        <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                            <div className="flex flex-col items-start max-w-2xl">
                                <Breadcrumbs
                                    home="/templates/jobs"
                                    pages={[
                                        {
                                            name: "Job template setup",
                                            href: `/templates/job/${params.jobId}`,
                                            current: true,
                                        },
                                        {
                                            name: "Create job",
                                            href: `/templates/job-templates/${params.jobId}/jobs/create`,
                                            current: true,
                                        },
                                    ]}
                                />

                                <h1 className="text-2xl md:text-3xl text-slate-800 font-bold p-4">
                                    Create Job
                                </h1>
                                <div className="bg-white shadow-lg rounded-sm border border-slate-200 relative w-full pb-4">
                                    <div className="space-y-5 px-5 py-4">
                                        <div>
                                            <label
                                                htmlFor="email"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                Name
                                            </label>
                                            <div className="mt-1">
                                                <input
                                                    type="text"
                                                    onChange={(e) =>
                                                        setJob({
                                                            ...job,
                                                            name: e.target.value,
                                                        })
                                                    }
                                                    value={job.name}
                                                    required
                                                    className={`w-full shadow-sm border border-slate-300 rounded-sm p-2`}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="bg-white shadow-lg rounded-sm border border-slate-200 relative mt-12 w-full">
                                    <header className="flex w-full justify-between items-center px-5 py-4 border-b border-slate-200">
                                        <h2 className="font-semibold text-slate-800">
                                            Checklist Items
                                        </h2>
                                        <button
                                            id="add-btn"
                                            onClick={() => onClickAddItem()}
                                            type="button"
                                            className="inline-flex items-center p-1 border border-transparent rounded-sm shadow-sm text-white bg-teal-500 hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
                                        >
                                            <PlusIcon className="w-5 h-5" />
                                        </button>
                                    </header>

                                    <CreateNewChecklistItemFormSection
                                        inputCheckList={inputCheckList}
                                        setInputCheckList={setInputCheckList}
                                        updateChecklistItem={updateChecklistItem}
                                        removeChecklistItem={removeChecklistItem}
                                        createChecklistItem={createChecklistItem}
                                        job={job}
                                        setJob={setJob}
                                    />
                                </div>
                                <div className="w-full flex justify-end">
                                    <button
                                        onClick={onSave}
                                        className="z-50 mt-8 inline-flex justify-end py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-sm text-white bg-teal-500 hover:bg-teal-600 focus:outline-none focus:ring-0"
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </section>
    );
}
export default CreateJobV2;
