import React, { useState } from "react";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs";
import { getEmployee } from "../../services/getEmployee";
import { updateEmployee } from "../../services/updateEmployee";
import { motion } from "framer-motion";
import { getResetPassword } from "../../services/resetPasswords";
import RolesListBox from "./RolesListBox";
import toast, { Toaster } from "react-hot-toast";
import { getLoggedInEmployee } from "../../services/getLoggedInEmployee";

const ROLES = ["User", "Admin"];

function EditEmployeeForm() {
  const [loggedInEmployee, setLoggedInEmployee] = useState(null);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    role: 0,
  });
  const [loading, setLoading] = useState(true);
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    async function runOnLoad() {
      const employee = await getEmployee(params.id);
      console.log(employee);
      if (employee.success === false) return navigate("/employees");

      setForm({
        firstName: employee.data.employeeResponse.firstName,
        lastName: employee.data.employeeResponse.lastName,
        email: employee.data.employeeResponse.email,
        role: employee.data.employeeResponse.role,
      });
      setLoading(false);
    }
    runOnLoad();
    getCurrentEmployee();
    // eslint-disable-next-line
  }, []);

  const getCurrentEmployee = async () => {
    const employee = await getLoggedInEmployee();
    if (employee.success) {
      setLoggedInEmployee(employee.data.employeeResponse);
      console.log(employee.data.employeeResponse);
    }
  };

  const saveDetails = async () => {
    if (loggedInEmployee.role === 0) {
      toast.error("You don't have permission to do this");
      return;
    }

    const response = await updateEmployee(params.id, form);
    console.log(response);
    if (response.data.success) {
      toast.success(response.data.message);
    } else {
      toast.error(response.data.message);
    }
    //alert(res.data.message);
  };

  const resetPassword = async () => {
    if (loggedInEmployee.role === 0) {
      toast.error("You don't have permission to do this.");
      return;
    }
    const res = await getResetPassword(form.email);
    console.log(res);
  };

  const onHandleChangeRole = (role) => {
    setForm({ ...form, role: role });
  };

  return (
    <section>
      <Toaster />
      <div className="flex h-screen overflow-hidden">
        {/* Sidebar */}
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        {/* Content area */}
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          {/*  Site header */}
          <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

          {!loading ? (
            <main>
              <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                <div className="flex flex-col items-start max-w-2xl">
                  <Breadcrumbs
                    home="/employees"
                    pages={[
                      {
                        name: "Service",
                        href: `/employees/${params.id}`,
                        current: true,
                      },
                    ]}
                  />

                  {/* Page header */}
                  <div className="sm:flex sm:justify-between sm:items-center mb-8 w-full mt-12">
                    {/* Left: Title */}
                    <div className="mb-4 sm:mb-0">
                      <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">
                        Edit employee
                      </h1>
                    </div>
                  </div>

                  <div className="bg-white shadow-lg rounded-sm border border-slate-200 relative w-full">
                    <header className="px-5 py-4 border-b border-slate-200">
                      <h2 className="font-semibold text-slate-800">Details</h2>
                    </header>

                    <div className="space-y-5 px-5 py-4">
                      <div>
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium text-gray-700"
                        >
                          First Name
                        </label>
                        <div className="mt-1">
                          <input
                            type="text"
                            value={form.firstName}
                            onChange={(e) =>
                              setForm({ ...form, firstName: e.target.value })
                            }
                            className="shadow-sm border border-slate-200 rounded-sm p-1 px-2 w-full max-w-sm focus:ring-0 focus:outline-none"
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Last Name
                        </label>
                        <div className="mt-1">
                          <input
                            type="text"
                            value={form.lastName}
                            onChange={(e) =>
                              setForm({ ...form, lastName: e.target.value })
                            }
                            className="shadow-sm border border-slate-200 rounded-sm p-1 px-2 w-full max-w-sm focus:ring-0 focus:outline-none"
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Email
                        </label>
                        <div className="mt-1">
                          <input
                            type="text"
                            value={form.email}
                            onChange={(e) =>
                              setForm({ ...form, email: e.target.value })
                            }
                            className="shadow-sm border border-slate-200 rounded-sm p-1 px-2 w-full max-w-sm focus:ring-0 focus:outline-none"
                          />
                        </div>
                      </div>
                      <div>
                        {loggedInEmployee.role == 1 && (
                          <div className="mt-1">
                            <RolesListBox
                              roles={ROLES}
                              role={form.role}
                              onChange={(role) => onHandleChangeRole(role)}
                            />
                          </div>
                        )}
                      </div>
                      <div>
                        <label
                          htmlFor="link"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Send Reset Password Email
                        </label>
                        <button
                          onClick={() => resetPassword()}
                          type="button"
                          className="z-50 mt-4 ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-sm text-white bg-teal-500 hover:bg-teal-600 focus:outline-none focus:ring-0"
                        >
                          Reset Password
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="w-full flex justify-end">
                    <button
                      onClick={() => saveDetails()}
                      type="submit"
                      className="z-50 mt-4 ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-sm text-white bg-teal-500 hover:bg-teal-600 focus:outline-none focus:ring-0"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </main>
          ) : (
            <div className="w-full mt-64 flex justify-center items-center max-w-9xl">
              <motion.div
                className="border border-t-gray-700 border-t-[4px] rounded-full border-gray-200 w-12 h-12"
                initial={{ rotateZ: 0 }}
                animate={{ rotateZ: 360 }}
                exit={{ rotateZ: 0 }}
                transition={{ repeat: Infinity, ease: "linear", duration: 1 }}
              ></motion.div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}
export default EditEmployeeForm;
