const InputTextAreaModal = ({ label, name, onChange, value }) => {
  return (
    <div className="mt-5 sm:mt-6">
      <label
        htmlFor={name}
        className="text-left block text-sm font-medium text-gray-700"
      >
        {label}
      </label>
      <div className="relative inline-block text-left w-full">
        <textarea
          rows={5}
          col={10}
          name={name}
          onChange={onChange}
          value={value !== null ? value : ""}
          className="inline-flex truncate justify-between w-full rounded-md border border-gray-200 bg-white px-3 py-2 text-sm font-normal text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-0 resize-y whitespace-pre-line break-words"
        />
      </div>
    </div>
  );
};

export default InputTextAreaModal;
