import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useCallback, useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { getTimestamp } from "../../helpers/getTimestamp";
import { cloneClientServiceSubscription } from "../../services/clientService/cloneClientServiceSubscription";
import { getEmployees } from "../../services/getEmployees";
import { getLoggedInEmployee } from "../../services/getLoggedInEmployee";
import ButtonAddTimestamp from "../../utils/ButtonAddTimestamp";
import AssignedByListBox from "../clientServiceSubscriptionTask/updateClientServiceSubscriptionTaskModal/AssignedByListBox";
import InputTextAreaModal from "../clientServiceSubscriptionTask/updateClientServiceSubscriptionTaskModal/InputTextAreaModal";

export default function CloneSubscriptionModal({
    open,
    setOpen,
    clientServiceId,
    refreshSubs,
}) {
    const [displayNotes, setDisplayNotes] = useState(false);
    const [employees, setEmployees] = useState([]);
    const [loggedInEmployee, setLoggedInEmployee] = useState({});
    const [form, setForm] = useState({
        clientServiceId: clientServiceId,
        serviceYear: new Date().getFullYear(),
        start: new Date().toISOString().split("T")[0],
        end: new Date().toISOString().split("T")[0],
        //interval: "Weekly",
        search: "d",
        /* assignedEmployees: [], */
        notes: "",
        isEveryItemRenewable: false,
        cloningMethod: "SERVICE",
        assignedEmployee: null,
    });

    const toggleNotes = () => {
        setDisplayNotes(!displayNotes);
    };

    const getNewLoggedInEmployee = useCallback(async () => {
        const currentEmployee = await getLoggedInEmployee();
        if (currentEmployee.success) {
            setLoggedInEmployee(currentEmployee.data.employeeResponse);
            console.log(currentEmployee);
        }
    }, []);

    const onHandleClickAddTimestamp = (fieldName) => {
        setForm({
            ...form,
            [fieldName]:
                (form[fieldName] !== null ? form[fieldName] : "") +
                getTimestamp(form.notes, loggedInEmployee),
        });
    };

    async function handleSubmit() {
        if (form.cloningMethod == null) {
            toast.error("Choose a cloning method please");
            return;
        }
        if (form.start == null) {
            toast.error("Choose the assigned date");
            return;
        }
        if (form.assignedEmployee == null) {
            toast.error("Assign an employee to the service");
            return;
        }
        const data = {
            clientServiceId: clientServiceId,
            interval: form.interval,
            serviceYear: form.serviceYear,
            start: new Date().toISOString().split("T")[0],
            end: new Date().toISOString().split("T")[0],
            /* assignedEmployees: form.assignedEmployees, */
            notes: form.notes,
            assignedByEmployee: loggedInEmployee,
            cloningMethod: form.cloningMethod,
            isEveryItemRenewable: form.isEveryItemRenewable,
            assignedEmployee: form.assignedEmployee,
        };

        const response = await cloneClientServiceSubscription(data);
        if (response.success) {
            toast.success(response.data.message);
            refreshSubs();
        } else {
            toast.error(response.data.message);
        }
        setOpen(false);
        console.log(response);
    }

    useEffect(() => {
        const getNewEmployees = async () => {
            const response = await getEmployees();
            setEmployees(response.data.employees);
        };
        getNewEmployees();
        getNewLoggedInEmployee();
    }, []);

    const onHandleChangeAssignEmployee = (newAssignedEmployee) => {
        setForm({
            ...form,
            cloningMethod: "SERVICE",
            assignedEmployee: newAssignedEmployee,
        });
    };

    const onDueDateChange = (e) => {
        if (form.start > e.target.value) {
            toast.error("The due date cannot be before the assigned date.");
            return;
        }
        setForm({ ...form, end: e.target.value });
    };

    return (
        <section>
            <Toaster />
            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="relative z-50" onClose={setOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed z-10 inset-0 overflow-y-auto">
                        <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-sm w-full sm:p-6">
                                    <div className="text-left">
                                        <div className="flex justify-between">
                                            <Dialog.Title
                                                as="h3"
                                                className="text-lg leading-6 font-medium text-gray-900"
                                            >
                                                Service Cloning
                                            </Dialog.Title>
                                            <button onClick={() => setOpen(false)}>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="h-5 w-5"
                                                    viewBox="0 0 20 20"
                                                    fill="currentColor"
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                                        clipRule="evenodd"
                                                    />
                                                </svg>
                                            </button>
                                        </div>

                                        <div className="mt-5 sm:mt-6">
                                            <h1 className="text-md font-normal">
                                                Year
                                            </h1>
                                            <input
                                                onChange={(e) =>
                                                    setForm({
                                                        ...form,
                                                        serviceYear: e.target.value,
                                                    })
                                                }
                                                className="relative inline-block text-left w-full"
                                                type="number"
                                                min="2000"
                                                max="2100"
                                                step="1"
                                                value={form.serviceYear}
                                            />
                                        </div>

                                        {/* <div className="flex flex-col mt-5 sm:mt-6">
                                            <h1 className="text-md font-normal">
                                                Assigned Date
                                            </h1>
                                            <input
                                                onChange={(e) =>
                                                    setForm({
                                                        ...form,
                                                        start: e.target.value,
                                                    })
                                                }
                                                type="date"
                                                id="start"
                                                name="trip-start"
                                                className="form-select pr-1 rounded-md border border-gray-200"
                                            ></input>
                                        </div> */}

                                        <div className="flex flex-col mt-5 sm:mt-6">
                                            <h1 className="text-md font-normal">
                                                Due Date
                                            </h1>
                                            <input
                                                onChange={onDueDateChange}
                                                type="date"
                                                id="dueDate"
                                                name="dueDate"
                                                className="form-select pr-1 rounded-md border border-gray-200"
                                                value={form.end}
                                            ></input>
                                        </div>

                                        <AssignedByListBox
                                            label="Assign service to"
                                            employees={employees}
                                            assignedBy={form.assignedEmployee}
                                            onChange={onHandleChangeAssignEmployee}
                                        />

                                        {!displayNotes ? (
                                            <button
                                                className="inline-flex items-center p-1 border border-transparent rounded-sm shadow-sm text-white bg-teal-500 hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 text-base mt-4"
                                                onClick={toggleNotes}
                                            >
                                                Add notes
                                            </button>
                                        ) : (
                                            <button
                                                className="inline-flex items-center p-1 border border-transparent rounded-sm shadow-sm text-white bg-red-500 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 text-base mt-4"
                                                onClick={toggleNotes}
                                                type="button"
                                            >
                                                Hide notes
                                            </button>
                                        )}
                                        {displayNotes && (
                                            <>
                                                <InputTextAreaModal
                                                    label="Permanent Notes"
                                                    name="notes"
                                                    onChange={(e) =>
                                                        setForm({
                                                            ...form,
                                                            notes: e.target.value,
                                                        })
                                                    }
                                                    value={form.notes}
                                                />
                                                <ButtonAddTimestamp
                                                    onHandleClick={() =>
                                                        onHandleClickAddTimestamp(
                                                            "notes"
                                                        )
                                                    }
                                                />
                                            </>
                                        )}
                                    </div>
                                    <div className="mt-5 sm:mt-6">
                                        <button
                                            type="button"
                                            className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-teal-500 text-base font-medium text-white hover:bg-teal-600 focus:outline-none focus:ring-0 sm:text-sm"
                                            onClick={() => handleSubmit()}
                                        >
                                            Clone Service
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </section>
    );
}
