import { Dialog } from "@headlessui/react";
import { AnimatePresence, motion } from "framer-motion";
import { useRef, useState } from "react";
import toast from "react-hot-toast";
import LinkForm from "../../components/clientServiceSubscription/links/LinkForm";
import InputTextModal from "../../components/clientServiceSubscriptionTask/modalGeneralComponents/InputTextModal";
import ButtonModal from "../../components/clientServiceSubscriptionTask/updateClientServiceSubscriptionTaskModal/ButtonModal";
import { isValidLink } from "../../helpers/urlRegexValidator";
import { createChecklistItem } from "../../services/createChecklistItem";

const LINK_NAME = "name";
const LINK_URL = "url";

const AddItemToJobModal = ({ open, setOpen, taskId, getNewChecklistItems }) => {
    let completeButtonRef = useRef(null);
    const [newLink, setNewLink] = useState({ [LINK_NAME]: "", [LINK_URL]: "" });
    const [newChecklistItem, setNewChecklistItem] = useState({
        description: "",
        links: [],
        notes: "",
    });

    const initializeForm = () => {
        setNewChecklistItem({
            description: "",
            links: [],
            notes: "",
        });
    };

    const handleChangeLink = (e) => {
        const { name } = e.target;
        const { value } = e.target;
        setNewLink({ ...newLink, [name]: value });
    };

    const addLink = () => {
        if (newLink[LINK_NAME] !== "" && isValidLink(newLink[LINK_URL])) {
            setNewChecklistItem({
                ...newChecklistItem,
                links: [...newChecklistItem.links, newLink],
            });
            setNewLink({ [LINK_NAME]: "", [LINK_URL]: "" });
        } else {
            toast.error("Adding empty or invalid links is not allowed.");
        }
    };

    const deleteLink = (name) => {
        const newLinks = newChecklistItem.links.filter(
            (link) => link[LINK_NAME] !== name
        );
        setNewChecklistItem({
            ...newChecklistItem,
            links: newLinks,
        });
    };

    const onHandleChange = (e) => {
        const fieldName = e.target.name;
        const fieldValue = e.target.value;
        setNewChecklistItem({ ...newChecklistItem, [fieldName]: fieldValue });
    };

    const onHandleSubmit = async (e) => {
        e.preventDefault();
        console.log(newChecklistItem);
        const response = await createChecklistItem({
            task_id: taskId,
            description: newChecklistItem.description,
            links: JSON.stringify(newChecklistItem.links),
        });
        if (response.data.success) {
            toast.success(response.data.message);
            await getNewChecklistItems();
        } else {
            toast.error(response.data.message);
        }
        initializeForm();
        setOpen(false);
    };

    const onHandleCancel = () => {
        setOpen(false);
    };

    return (
        <AnimatePresence>
            {open && (
                <Dialog
                    initialFocus={completeButtonRef}
                    static
                    as={motion.div}
                    open={open}
                    onClose={() => setOpen(false)}
                    className="relative z-50"
                >
                    {/* The backdrop, rendered as a fixed sibling to the panel container */}
                    <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

                    {/* Full-screen scrollable container */}
                    <div className="fixed inset-0 w-screen overflow-y-auto">
                        {/* Container to center the panel */}
                        <div className="flex min-h-full items-center justify-center">
                            <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-y-auto  shadow-xl transform transition-all h-screen w-screen">
                                <Dialog.Title className="text-center w-full text-lg leading-6 font-medium text-gray-900">
                                    Add Checklist Item
                                </Dialog.Title>

                                <form onSubmit={onHandleSubmit}>
                                    <InputTextModal
                                        required={true}
                                        label="Description"
                                        name="description"
                                        onChange={onHandleChange}
                                        value={newChecklistItem.description}
                                    />

                                    <LinkForm
                                        newLink={newLink}
                                        addLink={addLink}
                                        handleChange={handleChangeLink}
                                        deleteLink={deleteLink}
                                        LINK_NAME={LINK_NAME}
                                        LINK_URL={LINK_URL}
                                        inputLinksJSON={newChecklistItem.links}
                                        divWidth="w-full"
                                        title="Links"
                                    />

                                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                                        <ButtonModal
                                            name="Save"
                                            type="submit"
                                            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-teal-500 text-base font-medium text-white hover:bg-teal-600 focus:outline-none focus:ring-0 sm:col-start-2 sm:text-sm"
                                        />
                                        <ButtonModal
                                            name="Cancel"
                                            type="button"
                                            onClick={onHandleCancel}
                                            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-0 sm:mt-0 sm:col-start-1 sm:text-sm"
                                        />
                                    </div>
                                </form>
                            </Dialog.Panel>
                        </div>
                    </div>
                </Dialog>
            )}
        </AnimatePresence>
    );
};

export default AddItemToJobModal;
