import Chart from "react-apexcharts";

export const BarChart = (props) => {
  return (
    <div id="barchart">
      <Chart
        options={props.settings.options}
        series={props.settings.series}
        type={props.settings.options.chart.type}
        width={500}
        height={300}
      />
    </div>
  );
};
