import axios from "axios"
import Cookies from "js-cookie"
import { FIRMFLOWURL } from "./url";
export const createCustomTask = (subscription_id, form) => {
    return new Promise(async resolve => {
        try {
            const token = Cookies.get('Bearer');
            const response = await axios.post(`${FIRMFLOWURL}api/ClientSubscriptionCustomization/${subscription_id}/task`,
                {
                    taskName: form.name
                },
                {
                    headers: { 'Authorization': token }
                }
            )
            resolve({ ...response, success: true });
        } catch (err) {

            if (err.message.includes(401)) {
                Cookies.remove('Bearer');
                window.location.replace('/');
                return;
            }
            resolve({ success: false, message: err.response.data.message });
        }
    })
}