const RadioInput = ({ title, name, onChange, items, selectedValue }) => {
  return (
    <div className="mt-4">
      <label
        htmlFor="label"
        className="block text-sm font-medium text-gray-700"
      >
        {title}
      </label>
      <div className="flex mt-1">
        {items.map((item) => (
          <div key={item.label} className="flex items-center mx-4">
            <input
              type="radio"
              value={item.value}
              checked={selectedValue === item.value}
              onChange={(e) => onChange(e.target.value)}
              name={name}
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
            <label for={name} className="ml-4">
              {item.label}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RadioInput;
