import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/solid";
import { Fragment, useEffect } from "react";

const SelectServiceListBox = ({ services, selectedService, onChange }) => {
  useEffect(() => {
    console.log(services);
  }, []);

  return (
    <div className="basic-single min-w-[180px] w-full">
      <label
        htmlFor="selectedService"
        className="text-left block text-base font-bold text-black"
      >
        Select Service
      </label>
      <Listbox
        by="value"
        as="div"
        className="relative inline-block text-left w-full"
        value={selectedService}
        onChange={onChange}
      >
        <div>
          <Listbox.Button className="inline-flex truncate justify-between w-full rounded-md border border-gray-300 bg-white px-3 py-2 text-base font-normal text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-0">
            <span>
              {selectedService !== null
                ? selectedService.label
                : "Not Assigned"}
            </span>
            <ChevronUpDownIcon className="h-5 w-5" aria-hidden="true" />
          </Listbox.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Listbox.Options className="absolute right-0 z-10 mt-2 w-fit origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <ul className="py-1 max-h-72 overflow-auto w-full">
              {services.map((service) => (
                <Listbox.Option
                  name="selectedService"
                  key={service.value}
                  value={service}
                  className="m-5 cursor-pointer"
                >
                  {({ active, selected }) => (
                    <div
                      className={`${
                        active ? "text-white bg-blue-600" : "text-gray-900"
                      } cursor-pointer select-none relative pl-8 pr-4`}
                    >
                      {selected && (
                        <span
                          className={`${
                            active ? "text-white" : "text-blue-600"
                          } absolute inset-y-0 left-0 flex items-center pl-1.5`}
                        >
                          <CheckIcon className="h-5 w-5 text-green-600" />
                        </span>
                      )}
                      <span
                        className={`${
                          selected ? "font-semibold" : "font-normal"
                        } block truncate`}
                      >
                        {service.label}
                      </span>
                    </div>
                  )}
                </Listbox.Option>
              ))}
            </ul>
          </Listbox.Options>
        </Transition>
      </Listbox>
    </div>
  );
};

export default SelectServiceListBox;
