const InputDateModal = ({ label, name, value, onChange, disabled }) => {
  return (
    <div className="flex flex-col mt-5 sm:mt-6">
      <h1 className="text-left block text-sm font-medium text-gray-700">
        {label}
      </h1>
      <input
        disabled={disabled}
        value={value}
        onChange={onChange}
        type="date"
        id={name}
        name={name}
        className="form-select pr-1"
      />
    </div>
  );
};

export default InputDateModal;
