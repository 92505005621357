import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/solid";
import { Fragment } from "react";

const AssignedEmployeesListBox = ({
  title,
  employees,
  assignedEmployees,
  onChange,
}) => {
  return (
    <div className="mt-5 sm:mt-6">
      <label
        htmlFor="assignedEmployees"
        className="text-left block text-sm font-medium text-gray-700"
      >
        {title}
      </label>
      <Listbox
        by="employeeId"
        as="div"
        className="relative inline-block text-left w-full"
        value={assignedEmployees}
        onChange={onChange}
        multiple
      >
        <div>
          <Listbox.Button className="inline-flex truncate justify-between w-full rounded-md border border-gray-200 bg-white px-3 py-2 text-sm font-normal text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-0">
            <span>
              {assignedEmployees &&
                assignedEmployees
                  .map((employee) =>
                    employee.fullName
                      ? employee.fullName
                      : employee.firstName + " " + employee.lastName
                  )
                  .join(" | ")}
            </span>
            <ChevronUpDownIcon className="h-5 w-5" />
          </Listbox.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Listbox.Options className="right-0 z-10 mt-2 w-full origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <ul className="py-1 max-h-72 overflow-auto w-full">
              {employees.map((employee) => (
                <Listbox.Option
                  name="assignedEmployees"
                  key={employee.employeeId}
                  value={employee}
                  className="m-5 cursor-pointer"
                >
                  {({ active, selected }) => (
                    <div
                      className={`${
                        active ? "text-white bg-blue-600" : "text-gray-900"
                      } cursor-pointer select-none relative pl-8 pr-4`}
                    >
                      {selected && (
                        <span
                          className={`${
                            active ? "text-white" : "text-blue-600"
                          } absolute inset-y-0 left-0 flex items-center pl-1.5`}
                        >
                          <CheckIcon className="h-5 w-5 text-green-600" />
                        </span>
                      )}
                      <span
                        className={`${
                          selected ? "font-semibold" : "font-normal"
                        } block truncate`}
                      >
                        {employee.firstName + " " + employee.lastName}
                      </span>
                    </div>
                  )}
                </Listbox.Option>
              ))}
            </ul>
          </Listbox.Options>
        </Transition>
      </Listbox>
    </div>
  );
};

export default AssignedEmployeesListBox;
