import { motion } from "framer-motion";
import { parseDigit, templateFormatter, templateParser } from "input-format";
import ReactInput from "input-format/react";
import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import { TAX_NUMBER_REGEX } from "../../helpers/taxNumberRegex";
import { getClient } from "../../services/getClient";
import { updateClient } from "../../services/updateClient";

function CreateClientForm() {
  const navigate = useNavigate();
  const params = useParams();

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [form, setForm] = useState({
    clientName: "",
    phoneNumber: "",
    taxNumber: "",
    address: {
      street: "",
      city: "",
      stateProvince: "",
      postalCode: "",
      country: "United States",
    },
    clientDetail: {
      clientType: "individual",
      ssn: "",
      spouseName: "",
      spouseEmail: "",
      spouseTaxNumber: "",
      pointOfContactName: "",
      pointOfContactEmail: "",
      secondaryPointOfContactName: "",
      secondaryPointOfContactEmail: "",
      clientEmail: "",
      isMeetingRequired: false,
    },
  });

  /* useEffect(() => {
    getNewLoggedInEmployeeFunction(setLoggedInEmployee);
  }, []); */

  useEffect(() => {
    async function runOnLoad() {
      const client = await getClient(params.id);
      console.log(client);
      let clientVal = client.data?.clientDetail?.clientType;
      let defaultClientVal =
        clientVal === undefined ? "individual" : clientVal?.toLowerCase();
      setForm({
        id: client.data.id,
        clientName: client.data.clientName,
        phoneNumber: client.data.phoneNumber,
        taxNumber: client.data.taxNumber,
        address: {
          street: client.data.address.street,
          city: client.data.address.city,
          stateProvince: client.data.address.stateProvince,
          postalCode: client.data.address.postalCode,
          country: client.data.address.country,
        },
        clientDetail: {
          clientType: defaultClientVal,
          spouseName: client.data?.clientDetail?.spouseName,
          spouseEmail: client.data?.clientDetail?.spouseEmail,
          spouseTaxNumber: client.data?.clientDetail?.spouseTaxNumber,
          pointOfContactName: client.data?.clientDetail?.pointOfContactName,
          pointOfContactEmail: client.data?.clientDetail?.pointOfContactEmail,
          secondaryPointOfContactName:
            client.data?.clientDetail?.secondaryPointOfContactName,
          secondaryPointOfContactEmail:
            client.data?.clientDetail?.secondaryPointOfContactName,
          clientEmail: client.data?.clientDetail?.clientEmail,
          language: client.data?.clientDetail?.language,
          contactMethod: client.data?.clientDetail?.contactMethod,
          isMeetingRequired: client.data.clientDetail.isMeetingRequired,
        },
      });
      setLoading(false);
    }
    runOnLoad();
    // eslint-disable-next-line
  }, []);

  async function save() {
    /* if (loggedInEmployee.role === 0 || loggedInEmployee.role === 1) {
      toast.error("You don't have permission to do this.");
      return;
    } */
    if (!TAX_NUMBER_REGEX.test(form.taxNumber)) {
      toast.error("Tax ID Number has an invalid format");
      return;
    }
    if (form.clientDetail?.clientType === "individual") {
      form.clientDetail.pointOfContactName = "";
      form.clientDetail.pointOfContactEmail = "";
      form.clientDetail.secondaryPointOfContactName = "";
      form.clientDetail.secondaryPointOfContactEmail = "";
    } else if (form.clientDetail?.clientType === "business") {
      form.clientDetail.spouseName = "";
      form.clientDetail.spouseEmail = "";
    }
    console.log(form);
    const res = await updateClient(form);
    console.log(res);

    if (res.success) {
      toast.success(res.message);
      navigate("/clients");
    } else {
      toast.error(res.message);
    }
  }

  return (
    <section>
      <Toaster />
      <div className="flex h-screen overflow-hidden">
        {/* Sidebar */}
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        {/* Content area */}
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          {/*  Site header */}
          <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

          {!loading ? (
            <main>
              <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                <div className="flex flex-col items-start max-w-2xl">
                  <Breadcrumbs
                    home="/clients"
                    pages={[
                      {
                        name: "Edit client",
                        href: `/clients/${params.id}`,
                        current: true,
                      },
                    ]}
                  />

                  {/* Page header */}
                  <div className="sm:flex sm:justify-between sm:items-center mb-8 w-full mt-12">
                    {/* Left: Title */}
                    <div className="mb-4 sm:mb-0">
                      <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">
                        Edit client
                      </h1>
                    </div>
                  </div>

                  <div className="bg-white shadow-lg rounded-sm border border-slate-200 relative w-full">
                    <header className="px-5 py-4 border-b border-slate-200">
                      <h2 className="font-semibold text-slate-800">Details</h2>
                    </header>

                    <div className="space-y-5 px-5 py-4">
                      <div>
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Name
                        </label>
                        <div className="mt-1">
                          <input
                            onChange={(e) =>
                              setForm({ ...form, clientName: e.target.value })
                            }
                            type="text"
                            value={form.clientName}
                            className="shadow-sm border border-slate-200 rounded-sm p-1 px-2 w-full max-w-sm focus:ring-0 focus:outline-none"
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Email
                        </label>
                        <div className="mt-1">
                          <input
                            onChange={(e) =>
                              setForm({
                                ...form,
                                clientDetail: {
                                  ...form.clientDetail,
                                  clientEmail: e.target.value,
                                },
                              })
                            }
                            type="text"
                            value={form.clientDetail?.clientEmail}
                            className="shadow-sm border border-slate-200 rounded-sm p-1 px-2 w-full max-w-sm focus:ring-0 focus:outline-none"
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Phone number
                        </label>
                        <div className="mt-1">
                          <ReactInput
                            value={form.phoneNumber}
                            onChange={(value) =>
                              setForm({ ...form, phoneNumber: value })
                            }
                            format={templateFormatter("(xxx) xxx-xxxx")}
                            parse={templateParser("(xxx) xxx-xxxx", parseDigit)}
                            displayType="input"
                            type="text"
                            className="shadow-sm border border-slate-200 rounded-sm p-1 px-2 w-full max-w-sm focus:ring-0 focus:outline-none"
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Tax ID Number
                        </label>
                        <div className="mt-1">
                          <input
                            onChange={(e) =>
                              setForm({ ...form, taxNumber: e.target.value })
                            }
                            type="string"
                            value={form.taxNumber}
                            className="shadow-sm border border-slate-200 rounded-sm p-1 px-2 w-full max-w-sm focus:ring-0 focus:outline-none"
                          />
                        </div>
                      </div>

                      <div className="pb-1">
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Client type
                        </label>
                        <div className="mt-1">
                          <select
                            onChange={(e) =>
                              setForm({
                                ...form,
                                clientDetail: {
                                  ...form.clientDetail,
                                  clientType: e.target.value,
                                },
                              })
                            }
                            value={form.clientDetail?.clientType}
                            className="shadow-sm border border-slate-200 rounded-sm p-1 px-2 w-full max-w-sm focus:ring-0 focus:outline-none"
                          >
                            <option value="individual">Individual</option>
                            <option value="business">Business</option>
                          </select>
                        </div>
                      </div>

                      <div className="pb-1">
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Language
                        </label>
                        <div className="mt-1">
                          <select
                            onChange={(e) =>
                              setForm({
                                ...form,
                                clientDetail: {
                                  ...form.clientDetail,
                                  language: e.target.value,
                                },
                              })
                            }
                            value={form.clientDetail.language}
                            className="shadow-sm border border-slate-200 rounded-sm p-1 px-2 w-full max-w-sm focus:ring-0 focus:outline-none"
                          >
                            <option value="English">English</option>
                            <option value="Spanish">Spanish</option>
                          </select>
                        </div>
                      </div>
                      <div className="pb-1">
                        <label
                          htmlFor="contactMethod"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Preferred method of contact
                        </label>
                        <div className="mt-1">
                          <select
                            onChange={(e) =>
                              setForm({
                                ...form,
                                clientDetail: {
                                  ...form.clientDetail,
                                  contactMethod: e.target.value,
                                },
                              })
                            }
                            value={form.clientDetail.contactMethod}
                            className="shadow-sm border border-slate-200 rounded-sm p-1 px-2 w-full max-w-sm focus:ring-0 focus:outline-none"
                          >
                            <option value="Email">Email</option>
                            <option value="Text">Text</option>
                            <option value="Phone Call">Phone Call</option>
                          </select>
                        </div>
                      </div>
                      <div className="pb-1">
                        <label
                          htmlFor="contactMethod"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Is Meeting Required?
                        </label>
                        <div className="flex mt-1">
                          <div className="flex items-center mx-4">
                            <input
                              id="meeting-required-yes"
                              type="radio"
                              checked={
                                form.clientDetail.isMeetingRequired === true
                              }
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  clientDetail: {
                                    ...form.clientDetail,
                                    isMeetingRequired: true,
                                  },
                                })
                              }
                              name="isMeetingRequired"
                              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                            <label for="inline-radio" className="ml-4">
                              Yes
                            </label>
                          </div>
                          <div className="flex items-center mx-4">
                            <input
                              id="imeeting-required-no"
                              type="radio"
                              checked={
                                form.clientDetail.isMeetingRequired === false
                              }
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  clientDetail: {
                                    ...form.clientDetail,
                                    isMeetingRequired: false,
                                  },
                                })
                              }
                              name="isMeetingRequired"
                              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                            <label for="inline-2-radio" className="ml-4">
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-white shadow-lg rounded-sm border border-slate-200 relative w-full mt-6">
                    <header className="px-5 py-4 border-b border-slate-200">
                      <h2 className="font-semibold text-slate-800 capitalize">
                        {form.clientDetail?.clientType == "individual"
                          ? "Spouse"
                          : "Business"}
                      </h2>
                    </header>

                    {form.clientDetail?.clientType == "individual" ? (
                      <div>
                        <div className="px-5 mt-5">
                          <label
                            htmlFor="email"
                            className="block text-sm font-medium text-gray-700"
                          >
                            {`Spouse Name`}
                          </label>
                          <div className="mt-1">
                            <input
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  clientDetail: {
                                    ...form.clientDetail,
                                    spouseName: e.target.value,
                                  },
                                })
                              }
                              type="text"
                              value={form.clientDetail?.spouseName}
                              className="shadow-sm border border-slate-200 rounded-sm p-1 px-2 w-full max-w-sm focus:ring-0 focus:outline-none"
                            />
                          </div>
                        </div>
                        <div className="px-5 mt-5">
                          <label
                            htmlFor="email"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Spouse Tax ID Number
                          </label>
                          <div className="mt-1">
                            <input
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  clientDetail: {
                                    ...form.clientDetail,
                                    spouseTaxNumber: e.target.value,
                                  },
                                })
                              }
                              type="text"
                              value={form.clientDetail?.spouseTaxNumber}
                              className="shadow-sm border border-slate-200 rounded-sm p-1 px-2 w-full max-w-sm focus:ring-0 focus:outline-none"
                            />
                          </div>
                        </div>
                        <div className="px-5 mt-5 pb-5">
                          <label
                            htmlFor="email"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Spouse Email
                          </label>
                          <div className="mt-1">
                            <input
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  clientDetail: {
                                    ...form.clientDetail,
                                    spouseEmail: e.target.value,
                                  },
                                })
                              }
                              type="text"
                              value={form.clientDetail?.spouseEmail}
                              className="shadow-sm border border-slate-200 rounded-sm p-1 px-2 w-full max-w-sm focus:ring-0 focus:outline-none"
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div className="px-5 mt-5">
                          <label
                            htmlFor="email"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Name of main point of contact
                          </label>
                          <div className="mt-1">
                            <input
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  clientDetail: {
                                    ...form.clientDetail,
                                    pointOfContactName: e.target.value,
                                  },
                                })
                              }
                              type="text"
                              value={form.clientDetail?.pointOfContactName}
                              className="shadow-sm border border-slate-200 rounded-sm p-1 px-2 w-full max-w-sm focus:ring-0 focus:outline-none"
                            />
                          </div>
                        </div>
                        <div className="px-5 mt-5 pb-5">
                          <label
                            htmlFor="email"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Email of main point of contact
                          </label>
                          <div className="mt-1">
                            <input
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  clientDetail: {
                                    ...form.clientDetail,
                                    pointOfContactEmail: e.target.value,
                                  },
                                })
                              }
                              type="text"
                              value={form.clientDetail?.pointOfContactEmail}
                              className="shadow-sm border border-slate-200 rounded-sm p-1 px-2 w-full max-w-sm focus:ring-0 focus:outline-none"
                            />
                          </div>
                        </div>
                        <div className="px-5 mt-5">
                          <label
                            htmlFor="email"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Name of secondary point of contact
                          </label>
                          <div className="mt-1">
                            <input
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  clientDetail: {
                                    ...form.clientDetail,
                                    secondaryPointOfContactName: e.target.value,
                                  },
                                })
                              }
                              type="text"
                              value={
                                form.clientDetail?.secondaryPointOfContactName
                              }
                              className="shadow-sm border border-slate-200 rounded-sm p-1 px-2 w-full max-w-sm focus:ring-0 focus:outline-none"
                            />
                          </div>
                        </div>
                        <div className="px-5 mt-5 pb-5">
                          <label
                            htmlFor="email"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Email of secondary point of contact
                          </label>
                          <div className="mt-1">
                            <input
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  clientDetail: {
                                    ...form.clientDetail,
                                    secondaryPointOfContactEmail:
                                      e.target.value,
                                  },
                                })
                              }
                              type="text"
                              value={
                                form.clientDetail?.secondaryPointOfContactEmail
                              }
                              className="shadow-sm border border-slate-200 rounded-sm p-1 px-2 w-full max-w-sm focus:ring-0 focus:outline-none"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="bg-white shadow-lg rounded-sm border border-slate-200 relative w-full mt-6">
                    <header className="px-5 py-4 border-b border-slate-200">
                      <h2 className="font-semibold text-slate-800">Address</h2>
                    </header>

                    <div className="px-5 mt-5">
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Street address
                      </label>
                      <div className="mt-1">
                        <input
                          onChange={(e) =>
                            setForm({
                              ...form,
                              address: {
                                ...form.address,
                                street: e.target.value,
                              },
                            })
                          }
                          type="text"
                          value={form.address?.street}
                          className="shadow-sm border border-slate-200 rounded-sm p-1 px-2 w-full max-w-sm focus:ring-0 focus:outline-none"
                        />
                      </div>
                    </div>
                    <div className="px-5 mt-5">
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700"
                      >
                        City
                      </label>
                      <div className="mt-1">
                        <input
                          onChange={(e) =>
                            setForm({
                              ...form,
                              address: {
                                ...form.address,
                                city: e.target.value,
                              },
                            })
                          }
                          type="text"
                          value={form.address?.city}
                          className="shadow-sm border border-slate-200 rounded-sm p-1 px-2 w-full max-w-sm focus:ring-0 focus:outline-none"
                        />
                      </div>
                    </div>
                    <div className="px-5 mt-5">
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700"
                      >
                        State/Province
                      </label>
                      <div className="mt-1">
                        <input
                          onChange={(e) =>
                            setForm({
                              ...form,
                              address: {
                                ...form.address,
                                stateProvince: e.target.value,
                              },
                            })
                          }
                          type="text"
                          value={form.address?.stateProvince}
                          className="shadow-sm border border-slate-200 rounded-sm p-1 px-2 w-full max-w-sm focus:ring-0 focus:outline-none"
                        />
                      </div>
                    </div>
                    <div className="px-5 mt-5">
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Postal Code
                      </label>
                      <div className="mt-1">
                        <input
                          onChange={(e) =>
                            setForm({
                              ...form,
                              address: {
                                ...form.address,
                                postalCode: e.target.value,
                              },
                            })
                          }
                          type="text"
                          value={form.address?.postalCode}
                          className="shadow-sm border border-slate-200 rounded-sm p-1 px-2 w-full max-w-sm focus:ring-0 focus:outline-none"
                        />
                      </div>
                    </div>
                    <div className="px-5 pb-3 mt-5">
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Country
                      </label>
                      <div className="mt-1 mb-2">
                        <select
                          onChange={(e) =>
                            setForm({
                              ...form,
                              address: {
                                ...form.address,
                                country: e.target.value,
                              },
                            })
                          }
                          value={form.address?.country}
                          className="shadow-sm border border-slate-200 rounded-sm p-1 px-2 w-full max-w-sm focus:ring-0 focus:outline-none"
                        >
                          <option value="United States">United States</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="w-full flex justify-end">
                    <button
                      onClick={() => save()}
                      type="submit"
                      className="z-50 mt-4 ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-sm text-white bg-teal-500 hover:bg-teal-600 focus:outline-none focus:ring-0"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </main>
          ) : (
            <div className="w-full mt-64 flex justify-center items-center max-w-9xl">
              <motion.div
                className="border border-t-gray-700 border-t-[4px] rounded-full border-gray-200 border-[4px] w-12 h-12"
                initial={{ rotateZ: 0 }}
                animate={{ rotateZ: 360 }}
                exit={{ rotateZ: 0 }}
                transition={{ repeat: Infinity, ease: "linear", duration: 1 }}
              ></motion.div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}
export default CreateClientForm;
