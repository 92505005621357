import React, { useState, useEffect } from "react";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import { getFirm } from "../../services/getFirm";
import Breadcrumbs from "../../components/Breadcrumbs";
import { updateFirm } from "../../services/updateFirm";
import { getNewLoggedInEmployeeFunction } from "../../helpers/getLoggedInEmployeeFunction";
import toast from "react-hot-toast";
import { Toaster } from "react-hot-toast";

function Settings() {
    const [loggedInEmployee, setLoggedInEmployee] = useState(null);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [form, setForm] = useState({
        firmName: "",
        email: "",
        password: "",
        street: "",
        city: "",
        stateProvince: "",
        country: "",
        postalCode: "",
    });
    async function save() {
        if (loggedInEmployee.role === 0) {
            toast.error("You don't have permission to do this.");
            return;
        }
        console.log(form);
        const response = await updateFirm(form);
        if (response.data.success) {
            toast.success("Firm updated successfully");
        } else {
            toast.error("Failed to update firm");
        }
        console.log(response);
    }

    useEffect(() => {
        async function runOnLoad() {
            const resp = await getFirm();
            let firm = resp.data;
            console.log(firm);
            setForm({
                firmName: firm.firmName,
                email: firm.email,
                street: firm.address?.street,
                city: firm.address?.city,
                stateProvince: firm.address?.stateProvince,
                country: firm.address?.country,
                postalCode: firm.address?.postalCode,
            });
            //  setLoading(false);
        }
        runOnLoad();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        getNewLoggedInEmployeeFunction(setLoggedInEmployee);
    }, []);

    return (
        <section>
            <Toaster />
            <div className="flex h-screen overflow-hidden">
                {/* Sidebar */}
                <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

                {/* Content area */}
                <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                    {/*  Site header */}
                    <Header
                        sidebarOpen={sidebarOpen}
                        setSidebarOpen={setSidebarOpen}
                    />
                    <main>
                        <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                            <div className="flex flex-col items-start max-w-2xl">
                                <Breadcrumbs
                                    home="/settings"
                                    pages={[
                                        {
                                            name: "Firm Settings",
                                            href: ``,
                                            current: true,
                                        },
                                    ]}
                                />

                                {/* Page header */}
                                <div className="sm:flex sm:justify-between sm:items-center mb-8 w-full mt-12">
                                    {/* Left: Title */}
                                    <div className="mb-4 sm:mb-0">
                                        <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">
                                            Firm Settings
                                        </h1>
                                    </div>
                                </div>

                                <div className="bg-white shadow-lg rounded-sm border border-slate-200 relative w-full">
                                    <header className="px-5 py-4 border-b border-slate-200">
                                        <h2 className="font-semibold text-slate-800">
                                            Details
                                        </h2>
                                    </header>

                                    <div className="space-y-5 px-5 py-4">
                                        <div>
                                            <label
                                                htmlFor="email"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                Firm name
                                            </label>
                                            <div className="mt-1">
                                                <input
                                                    onChange={(e) =>
                                                        setForm({
                                                            ...form,
                                                            firmName: e.target.value,
                                                        })
                                                    }
                                                    type="text"
                                                    value={form?.firmName}
                                                    className="shadow-sm border border-slate-200 rounded-sm p-1 px-2 w-full max-w-sm focus:ring-0 focus:outline-none"
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <label
                                                htmlFor="email"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                Email address
                                            </label>
                                            <div className="mt-1">
                                                <input
                                                    onChange={(e) =>
                                                        setForm({
                                                            ...form,
                                                            email: e.target.value,
                                                        })
                                                    }
                                                    type="text"
                                                    value={form?.email}
                                                    className="shadow-sm border border-slate-200 rounded-sm p-1 px-2 w-full max-w-sm focus:ring-0 focus:outline-none"
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <label
                                                htmlFor="email"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                Street address
                                            </label>
                                            <div className="mt-1">
                                                <input
                                                    onChange={(e) =>
                                                        setForm({
                                                            ...form,
                                                            street: e.target.value,
                                                        })
                                                    }
                                                    type="text"
                                                    value={form?.street}
                                                    className="shadow-sm border border-slate-200 rounded-sm p-1 px-2 w-full max-w-sm focus:ring-0 focus:outline-none"
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <label
                                                htmlFor="email"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                City
                                            </label>
                                            <div className="mt-1">
                                                <input
                                                    onChange={(e) =>
                                                        setForm({
                                                            ...form,
                                                            city: e.target.value,
                                                        })
                                                    }
                                                    type="text"
                                                    value={form?.city}
                                                    className="shadow-sm border border-slate-200 rounded-sm p-1 px-2 w-full max-w-sm focus:ring-0 focus:outline-none"
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <label
                                                htmlFor="email"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                State/Province
                                            </label>
                                            <div className="mt-1">
                                                <input
                                                    onChange={(e) =>
                                                        setForm({
                                                            ...form,
                                                            stateProvince:
                                                                e.target.value,
                                                        })
                                                    }
                                                    type="text"
                                                    value={form?.stateProvince}
                                                    className="shadow-sm border border-slate-200 rounded-sm p-1 px-2 w-full max-w-sm focus:ring-0 focus:outline-none"
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <label
                                                htmlFor="email"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                Country
                                            </label>
                                            <div className="mt-1">
                                                <input
                                                    onChange={(e) =>
                                                        setForm({
                                                            ...form,
                                                            country: e.target.value,
                                                        })
                                                    }
                                                    type="text"
                                                    value={form?.country}
                                                    className="shadow-sm border border-slate-200 rounded-sm p-1 px-2 w-full max-w-sm focus:ring-0 focus:outline-none"
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <label
                                                htmlFor="email"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                Postal Code
                                            </label>
                                            <div className="mt-1">
                                                <input
                                                    onChange={(e) =>
                                                        setForm({
                                                            ...form,
                                                            postalCode:
                                                                e.target.value,
                                                        })
                                                    }
                                                    type="text"
                                                    value={form?.postalCode}
                                                    className="shadow-sm border border-slate-200 rounded-sm p-1 px-2 w-full max-w-sm focus:ring-0 focus:outline-none"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="w-full flex justify-end">
                                    <button
                                        onClick={() => save()}
                                        type="submit"
                                        className="z-50 mt-4 ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-sm text-white bg-teal-500 hover:bg-teal-600 focus:outline-none focus:ring-0"
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </section>
    );
}
export default Settings;
