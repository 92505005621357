const SelectInModal = ({ label, onChange, value, options, required }) => {
  return (
    <div className="mt-5 sm:mt-6">
      <label
        htmlFor="contactMethod"
        className="block text-sm font-medium text-gray-700"
      >
        {label}
      </label>
      <div className="relative inline-block text-left w-full">
        <select
          required={required}
          onChange={(e) => onChange(e)}
          value={value}
          className="inline-flex truncate justify-between w-full rounded-md border border-gray-200 bg-white px-3 py-2 text-sm font-normal text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-0"
        >
          {value == null && (
            <option disabled value={""} selected>
              Select an option
            </option>
          )}
          {options.map((option) => (
            <option value={option}>{option}</option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default SelectInModal;
