import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import Select from "react-select";
import AssignEmployeToTaskWhenServiceStarts from "../../components/clientServiceSubscriptionTask/updateClientServiceSubscriptionTaskModal/AssignEmployeeToTaskWhenServiceStarts";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import {
    getUserChecklistItems,
    getUserJobs,
    getUserServices,
} from "../../services/analytics";
import { getLoggedInEmployee } from "../../services/getLoggedInEmployee";
import MyAssignedChecklistItemsTable from "./MyAssignedChecklisItemTable";
import MyAssignedJobsTable from "./MyAssignedJobsTable";
import MyAssignedServicesTable from "./MyAssignedServicesTable";
import { useParams } from "react-router-dom";
import { ArrowLeftIcon } from "@heroicons/react/24/solid";

const ASSIGNMENTS = [
    { label: "Checklist Items", value: 1 },
    { label: "Jobs", value: 2 },
    { label: "Services", value: 3 },
];

function MyAssignments() {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [jobs, setJobs] = useState(null);
    const [services, setServices] = useState(null);
    const [checklistItems, setChecklistItems] = useState(null);
    const [employeeName, setEmployeeName] = useState("");
    const [selectedAssignment, setSelectedAssignment] = useState(null);
    const [isModalStartServiceOpen, setIsModalStartServiceOpen] = useState(false);
    const [clientServiceId, setClientServiceId] = useState(null);
    const [loggedInEmployee, setLoggedInEmployee] = useState(null);
    const params = useParams();

    useEffect(() => {
        getNewLoggedInEmployee();
        getfilterName();
    }, []);

    const getfilterName = () => {
        switch (params.filterName) {
            case "services":
                onChangeAssignment(ASSIGNMENTS[2]);
                break;
            case "checklist-items":
                onChangeAssignment(ASSIGNMENTS[0]);
                break;
            case "jobs":
                onChangeAssignment(ASSIGNMENTS[1]);
                break;
            default:
                break;
        }
        return;
    };

    /* const onHandleUpdateServiceStatus = async (
        clientServiceSubscriptionId,
        clientId
    ) => {
        const response = await updateClientServiceStatus(
            clientServiceSubscriptionId,
            1
        );
        if (response.success) {
            toast.success(response.data.message);
            fetchMyJobs();
            window.open(
                `/clients/service/${clientServiceSubscriptionId}/client/${clientId}`,
                "_blank"
            );
        } else {
            toast.error(response.data.message);
        }
    }; */

    const onChangeAssignment = async (assignment) => {
        console.log(assignment.label);
        if (assignment.label === ASSIGNMENTS[0].label) {
            await fetchMyChecklistItems();
        } else if (assignment.label === ASSIGNMENTS[1].label) {
            await fetchMyJobs();
        } else {
            await fetchMyServices();
        }
        setSelectedAssignment(assignment);
    };

    const fetchMyJobs = async () => {
        const myJobs = await getUserJobs();
        if (myJobs.success) {
            var values = myJobs.data.tasksAssignedToUser;
            console.log(values);
            setJobs(values);
            setEmployeeName(myJobs.data.fullName);
        } else {
            toast.error("Error fetching jobs.");
        }
        setLoading(false);
    };

    const fetchMyServices = async () => {
        const response = await getUserServices();
        if (response.success) {
            var values = response.data.servicesAssignedToUser;
            console.log(values);
            setServices(values);
            setEmployeeName(response.data.fullName);
        } else {
            toast.error("Error fetching services.");
        }
        setLoading(false);
    };

    const fetchMyChecklistItems = async () => {
        const response = await getUserChecklistItems();
        console.log(response);
        if (response.success) {
            var values = response.data.checklistItemsAssignedToUser;
            console.log(values);
            setChecklistItems(values);
            setEmployeeName(response.data.fullName);
        } else {
            toast.error("Error fetching checklist items.");
        }
        setLoading(false);
    };

    const onHandleStartService = (clientServiceId, newClientService) => {
        setIsModalStartServiceOpen(true);
        setClientServiceId(clientServiceId);
    };

    const getNewLoggedInEmployee = async () => {
        const currentEmployee = await getLoggedInEmployee();
        if (currentEmployee.success) {
            setLoggedInEmployee(currentEmployee.data.employeeResponse);
            console.log(currentEmployee);
        }
    };

    return (
        <div className="flex h-screen overflow-hidden">
            {loggedInEmployee && (
                <AssignEmployeToTaskWhenServiceStarts
                    open={isModalStartServiceOpen}
                    setOpen={setIsModalStartServiceOpen}
                    loggedInEmployee={loggedInEmployee}
                    clientSubscriptionId={clientServiceId}
                    newClientService={fetchMyServices}
                />
            )}
            <Toaster />
            {/* Sidebar */}
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

            {/* Content area */}
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                {/*  Site header */}
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                {!loading ? (
                    <main>
                        <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                            {/* Page header */}
                            <div className="mb-4 sm:mb-0">
                                <button
                                    onClick={() => window.history.back()}
                                    className="flex items-center text-slate-800 hover:text-slate-600"
                                >
                                    <ArrowLeftIcon className="h-4 w-4 mr-2" />
                                    Return
                                </button>
                            </div>
                            <div className="sm:flex sm:justify-between sm:items-center mb-8">
                                {/* Left: Title */}
                                <div className="mb-4 sm:mb-0">
                                    <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">
                                        {employeeName} Assignments
                                    </h1>
                                </div>
                                <div className="mb-4 sm:mb-0">
                                    <h2 className="text-2xl md:text-3xl text-slate-800 font-bold">
                                        {selectedAssignment.label}
                                    </h2>
                                </div>

                                <div className="flex flex-col items-start">
                                    <h2 className="text-1xl md:text-1xl text-slate-800 font-bold">
                                        Select Assignment
                                    </h2>
                                    {ASSIGNMENTS.length > 0 && (
                                        <Select
                                            onChange={onChangeAssignment}
                                            className="basic-single min-w-[180px] w-auto"
                                            classNamePrefix="select"
                                            isSearchable={true}
                                            defaultValue={selectedAssignment}
                                            options={ASSIGNMENTS}
                                            name="assignments"
                                        />
                                    )}
                                </div>
                            </div>

                            {selectedAssignment.label == ASSIGNMENTS[0].label && (
                                <MyAssignedChecklistItemsTable
                                    data={checklistItems}
                                    employee={employeeName}
                                />
                            )}

                            {selectedAssignment.label == ASSIGNMENTS[1].label && (
                                <MyAssignedJobsTable
                                    data={jobs}
                                    employee={employeeName}
                                />
                            )}

                            {selectedAssignment.label == ASSIGNMENTS[2].label && (
                                <MyAssignedServicesTable
                                    data={services}
                                    employee={employeeName}
                                    onHandleUpdateServiceStatus={
                                        onHandleStartService
                                    }
                                />
                            )}
                        </div>
                    </main>
                ) : (
                    <div className="w-full mt-64 flex justify-center items-center max-w-9xl">
                        <motion.div
                            className="border border-t-gray-700 border-t-[4px] rounded-full border-gray-200 w-12 h-12"
                            initial={{ rotateZ: 0 }}
                            animate={{ rotateZ: 360 }}
                            exit={{ rotateZ: 0 }}
                            transition={{
                                repeat: Infinity,
                                ease: "linear",
                                duration: 1,
                            }}
                        ></motion.div>
                    </div>
                )}
            </div>
        </div>
    );
}
export default MyAssignments;
