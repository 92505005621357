import axios from "axios"
import { FIRMFLOWURL } from "./url";
export const loginToAccount = (form) => {
    return new Promise(async resolve => {
        try {
            console.log(process.env.FIRMFLOWURL);
            const response = await axios.post(`${FIRMFLOWURL}api/Auth/LogIn?email=${form.email}&password=${form.password}`)
            resolve({...response, success: true});
        }catch(err){
            console.log(err)
            resolve({success: false, message: 'Email or password incorrect!'});
        }
    })
}