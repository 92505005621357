import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ChecklistItemsList from "../../components/clientServiceSubscription/tasks/checklistItems/ChecklistItemsList";
import EditItemModal from "../Jobs/EditItemModal";
import { useState } from "react";
import { ArrowsUpDownIcon, PencilIcon, TrashIcon } from "@heroicons/react/24/solid";

const ChecklistFormSection = ({
    taskId,
    checklist,
    inputCheckList,
    retrieveChecklist,
    setCheckList,
    setInputCheckList,
    updateChecklistItem,
    onClickRemoveChecklistItem,
    createChecklistItem,
}) => {
    const [openEditItem, setOpenEditItem] = useState(false);
    const [checklistToEdit, setChecklistToEdit] = useState([]);

    // a little function to help us with reordering the result
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };
    const onDragEnd = async (result) => {
        // dropped outside the list
        console.log(checklist);
        if (!result.destination) {
            return;
        }

        const items = reorder(
            checklist,
            result.source.index,
            result.destination.index
        );
        setCheckList(items);
        await Promise.all(
            items.map(async (item, index) => {
                await updateChecklistItem({
                    id: item.id,
                    task_id: taskId,
                    description: item.description,
                    links: JSON.stringify(item.links),
                    order: index,
                });
            })
        );
    };

    const onClickEditItem = (value, index) => {
        const item = {
            ...value,
            order: index,
        };
        setChecklistToEdit(item);
        setOpenEditItem(true);
    };

    return (
        <div className="space-y-5 px-5 py-4">
            <EditItemModal
                open={openEditItem}
                setOpen={setOpenEditItem}
                checklistItemToEdit={checklistToEdit}
                setChecklistItemToEdit={setChecklistToEdit}
                retrieveChecklist={retrieveChecklist}
            />
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                            {checklist.map((value, index) => {
                                return (
                                    <Draggable
                                        key={value.id}
                                        draggableId={value.id}
                                        index={index}
                                    >
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                <div
                                                    key={index}
                                                    className="relative flex items-center my-4 border border-slate-300 rounded-sm p-2 bg-gray-200"
                                                >
                                                    <ArrowsUpDownIcon className="h-4 w-4 mx-2" />
                                                    <div className="flex w-full items-center">
                                                        <h1
                                                            htmlFor="comments"
                                                            className="w-full font-medium text-gray-700"
                                                        >
                                                            {value.description}
                                                        </h1>
                                                        <div className="w-full">
                                                            <ChecklistItemsList
                                                                links={
                                                                    value.links
                                                                        ? value.links
                                                                        : []
                                                                }
                                                            />
                                                        </div>

                                                        <button
                                                            id="edit-btn"
                                                            onClick={() =>
                                                                onClickEditItem(
                                                                    value,
                                                                    index
                                                                )
                                                            }
                                                        >
                                                            <PencilIcon className="h-5 w-5 mx-2" />
                                                        </button>
                                                        <button
                                                            id="delete-btn"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                onClickRemoveChecklistItem(
                                                                    value.id
                                                                );
                                                            }}
                                                        >
                                                            <TrashIcon className="h-5 w-5 mx-2" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </Draggable>
                                );
                            })}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </div>
    );
};

export default ChecklistFormSection;
