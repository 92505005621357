import { useEffect, useState } from "react";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import ClientsByJobsV2 from "../../components/analytics/ClientsByJobsV2";
import SelectJobComboBox from "../../components/analytics/SelectJobComboBox";
import { getJobs } from "../../services/jobs/getJobs";

const JobsReportV2 = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState({ name: null });

  useEffect(() => {
    const getNewJobs = async () => {
      const response = await getJobs();
      if (response.success) {
        console.log(response);
        setJobs(response.data.jobs);
      }
    };
    getNewJobs();
  }, []);

  const onChangeService = (newJob) => {
    console.log(newJob);
    setSelectedJob(newJob);
  };
  return (
    <section>
      <div className="flex h-screen overflow-hidden">
        {/* Sidebar */}
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        {/* Content area */}
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          {/*  Site header */}
          <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
          <main>
            <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
              {/* Page header */}
              <div className="sm:flex sm:justify-between sm:items-center mb-8">
                {/* Left: Title */}
                <div className="mb-4 sm:mb-0 text">
                  <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">
                    Jobs Report
                  </h1>
                  <ul>{selectedJob.name !== null && <li>{selectedJob.name}</li>}</ul>
                </div>
                <div className="flex flex-col sm:flex-row sm:space-x-2 items-center">
                  <div className="flex flex-col items-start">
                    <SelectJobComboBox
                      label="Select Job"
                      items={jobs}
                      selectedItem={selectedJob}
                      onChangeItem={onChangeService}
                    />
                  </div>
                </div>
              </div>
            </div>
            {selectedJob.name !== null ? (
              <ClientsByJobsV2 task={selectedJob} />
            ) : (
              <h4 className="m-8 font-bold">
                Please select a job to see the report.
              </h4>
            )}
          </main>
        </div>
      </div>
    </section>
  );
};
export default JobsReportV2;
