const ErrorMessage = (error) => {
    console.log(error.error)
  return (
    error && (
      <div>
        <p className="text-left text-red-500 text-sm mt-1">{error.error}</p>
      </div>
    )
  );
};

export default ErrorMessage;
