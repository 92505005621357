import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { toast } from "react-hot-toast";
import CreateChecklistItemsForJobSection from "./CreateChecklistItemsForJobSection";
import { PlusIcon } from "@heroicons/react/24/solid";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/solid";

const CreateJobsForServiceSection = ({ jobs, setJobs }) => {
  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };
  const onDragEnd = async (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const items = reorder(jobs, result.source.index, result.destination.index);
    items.map((item, index) => {
      item.id = index;
    });
    setJobs(items);
  };

  const onClickEditJob = (index) => {
    const arr = [...jobs];
    arr[index].editing = true;
    setJobs(arr);
  };

  const onClickDeleteJob = (job) => {
    console.log(job);
    const newJobs = [...jobs];
    newJobs.splice(job.id, 1);
    setJobs(newJobs);
  };

  const onChangeJob = (e, index) => {
    const newJobs = [...jobs];
    newJobs[index].name = e.target.value;
    newJobs[index].editing = true;
    setJobs(newJobs);
    console.log(jobs);
  };

  const onUpdateJob = (jobToUpdate) => {
    if (!validateJobName(jobToUpdate) || !validateAtLeastOneChecklistItem(jobToUpdate)) {
      return;
    }
    const newJobs = jobs.map((job) => {
      if (job.id == jobToUpdate.id) {
        return { ...job, editing: false };
      } else {
        return job;
      }
    });
    setJobs(newJobs);
  };

  const validateJobName = (job) => {
    if (job.name == "") {
      toast.error("The name can't be empty");
      return false;
    } else {
      return true;
    }
  };

  const validateAtLeastOneChecklistItem = (job) => {
    if(job.checklistItems.length > 0 && job.checklistItems[0].name !== ""){
      return true;
    } else {
      toast.error("Add at least one checklist item");
      return false;
    }
  }

  const addChecklistItem = (jobToUpdate) => {
    console.log(jobToUpdate);
    console.log(jobs);
    const newJobs = jobs.map((job) => {
      if (job.id == jobToUpdate.id) {
        return {
          ...job,
          checklistItems: [
            ...job.checklistItems,
            {
              id: job.checklistItems.length,
              name: "",
              editing: true,
            },
          ],
        };
      } else {
        return job;
      }
    });
    setJobs(newJobs);
  };

  return (
    <div className="space-y-5 px-5 py-4">
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {jobs.map((job, index) => {
                return (
                  <Draggable
                    key={index}
                    draggableId={index.toString()}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <div key={index} className="relative flex items-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
                            />
                          </svg>

                          <div className="m-2 text-sm w-full">
                            {job.editing ? (
                              <>
                                <h2 className="font-black text-teal-500 m-2">
                                  Job
                                </h2>
                                <div className="flex w-full">
                                  <input
                                    type="text"
                                    value={job.name}
                                    onChange={(e) => onChangeJob(e, index)}
                                    placeholder="Name"
                                    className="shadow-sm border border-slate-200 rounded-sm p-2 px-2 w-full focus:ring-0 focus:outline-none"
                                  />
                                  <button
                                    type="button"
                                    id="update-btn"
                                    onClick={() => onUpdateJob(job)}
                                    className="ml-2 inline-flex items-center p-1.5 border border-transparent rounded-sm shadow-sm text-white bg-teal-500 hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
                                  >
                                    <CheckIcon className="h-5 w-5 text-white" />
                                  </button>
                                  <button
                                    id="cancel-edit-btn"
                                    onClick={() => onClickDeleteJob(job)}
                                    className="ml-2 inline-flex items-center p-1.5 border border-transparent rounded-sm shadow-sm text-white bg-red-500 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                  >
                                    <XMarkIcon className="h-5 w-5" />
                                  </button>
                                </div>

                                <hr className="font-black bg-slate-900 mt-12" />

                                <div className="bg-white shadow-lg rounded-sm border border-slate-200 relative mt-12 w-full">
                                  <header className="flex w-full justify-between items-center px-5 py-4 border-b border-slate-200">
                                    <h2 className=" font-black text-slate-500">
                                      Checklist Items{" "}
                                      <span className="font-light text-sm text-slate-700">
                                        click & drag to re order
                                      </span>
                                    </h2>
                                    <button
                                      id="add-btn"
                                      onClick={() => addChecklistItem(job)}
                                      type="button"
                                      className="inline-flex items-center p-2 border border-transparent rounded-sm shadow-sm text-white bg-teal-500 hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
                                    >
                                      <PlusIcon className="w-5 h-5" />
                                    </button>
                                  </header>

                                  <CreateChecklistItemsForJobSection
                                    checklistItems={job.checklistItems}
                                    jobs={jobs}
                                    setJobs={setJobs}
                                    currentJobId={job.id}
                                  />
                                </div>
                              </>
                            ) : (
                              <div className="flex w-full items-center">
                                <h1
                                  htmlFor="comments"
                                  className="w-full font-medium text-gray-700"
                                >
                                  {job.name}
                                </h1>
                                <button
                                  id="edit-btn"
                                  onClick={() => onClickEditJob(index)}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="w-6 h-6"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                                    />
                                  </svg>
                                </button>
                                <button
                                  id="delete-btn"
                                  onClick={() => onClickDeleteJob(index)}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                    className="w-7 h-7 hover:text-red-500 p-1"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 01-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                </button>
                              </div>
                            )}
                          </div>
                        </div>{" "}
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default CreateJobsForServiceSection;
