import React, { useState, useEffect } from "react";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import { motion } from "framer-motion";
import { getServices } from "../../services/getServices";
import { getClientsSubscriptionStates } from "../../services/analytics";
import Select from "react-select";
import FirmFlowTable from "../../components/mytasks/FirmFlowTable";

function ServiceInsights() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [service, setService] = useState({ id: 0 });
  const [services, setServices] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [status, setStatus] = useState({
    value: "All",
    label: "All",
    is_showing: "true",
  });
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);

  //Set initial list
  useEffect(() => {
    callApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const callApi = async (e) => {
    //Get clients
    Promise.all([getServices()]).then((data) => {
      const arr = [];
      // eslint-disable-next-line
      data[0].data.map((service, i) => {
        arr.push({
          value: service.id,
          label: service.name,
          is_showing: true,
        });
        if (arr.length > 0) {
          setService(arr[0]);
          setServices(arr);
        }
      });
      console.log("services: ", arr);
    });
  };

  useEffect(() => {
    //Fetch subscriptions on client change
    fetchClientSubscriptionStates();
    console.log("lista: ", list);

    // eslint-disable-next-line
  }, [service, status]);

  async function fetchClientSubscriptionStates() {
    console.log("service", service)
    if (service.id === 0) return;

    let statusVal = status.value === "undefined" ? "All" : status.value;
    const subscriptions = await getClientsSubscriptionStates(
      service.value,
      statusVal
    );
    console.log("sub: ", subscriptions);
    if (subscriptions.success) {
      console.log("subscriptions data",subscriptions.data);
      var values = subscriptions.data.clientStatesList;
      console.log(values);
      setList(values);
      var statusList = subscriptions.data.statesList;
      const arr = [{ value: "All", label: "All", is_showing: "true" }];
      statusList.map((state, i) => {
        console.log(state);
        arr.push({
          value: state,
          label: state,
          is_showing: true,
        });
        if (arr.length > 0) {
          setStatuses(arr);
        }
      });
    } else {
      alert("Error fetching subscriptions.");
    }
    //Set loading state false
    setLoading(false);
  }

  return (
    <section>
      <div className="flex h-screen overflow-hidden">
        {/* Sidebar */}
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        {/* Content area */}
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          {/*  Site header */}
          <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

          <main>
            {!loading ? (
              <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                {/* Page header */}
                <div className="sm:flex sm:justify-between sm:items-center mb-8">
                  {/* Left: Title */}
                  <div className="mb-4 sm:mb-0">
                    <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">
                      Service Insights Dashboard
                    </h1>
                  </div>
                  {/* Right: Actions */}
                  <div className="flex flex-col sm:flex-row sm:space-x-2 items-center">
                    <div className="flex flex-col items-start">
                      <h2 className="text-1xl md:text-1xl text-slate-800 font-bold">
                        Select Service
                      </h2>
                      {services.length > 0 && (
                        <Select
                          onChange={(e) => setService(e)}
                          className="basic-single min-w-[180px] w-auto"
                          classNamePrefix="select"
                          isSearchable={true}
                          defaultValue={services[0]}
                          options={services}
                          name="color"
                        />
                      )}
                    </div>
                    <div className="flex flex-col items-start">
                      <h2 className="text-1xl md:text-1xl text-slate-800 font-bold">
                        Select Status
                      </h2>
                      {statuses.length > 0 && (
                        <Select
                          onChange={(e) => setStatus(e)}
                          className="basic-single min-w-[180px] w-auto"
                          classNamePrefix="select"
                          isSearchable={true}
                          defaultValue={statuses[0]}
                          options={statuses}
                          name="color"
                        />
                      )}
                    </div>
                  </div>
                </div>
                {/* Table */}

                <FirmFlowTable
                  columns={[
                    {
                      Header: "Client",
                      accessor: "clientName",
                    },
                    {
                      Header: "Notes",
                      accessor: "notes",
                    },
                    {
                      Header: "Service Description",
                      accessor: "serviceDescription",
                    },
                    {
                      Header: "Status",
                      accessor: "state",
                    },
                    {
                      Header: "Client Service Jobs",
                      accessor: "clientServiceSubscriptionTasks",
                      Cell: (props) => (
                        <ul>
                          {props.value.map((task) => {
                            console.log(task);
                            return (
                              <li className="text-sm text-gray-500" key={task.id}>
                                {task.name} -{" "}
                                {task.status == 0
                                  ? "In Progress"
                                  : task.status == 1
                                  ? "Blocked"
                                  : "Completed"}
                              </li>
                            );
                          })}
                        </ul>
                      ),
                    },
                  ]}
                  data={list}
                />
              </div>
            ) : (
              <div className="w-full mt-64 flex justify-center items-center max-w-9xl">
                <motion.div
                  className="border-t-gray-700 border-t-[4px] rounded-full border-gray-200 border-[4px] w-12 h-12"
                  initial={{ rotateZ: 0 }}
                  animate={{ rotateZ: 360 }}
                  exit={{ rotateZ: 0 }}
                  transition={{ repeat: Infinity, ease: "linear", duration: 1 }}
                ></motion.div>
              </div>
            )}
          </main>
        </div>
      </div>
    </section>
  );
}
export default ServiceInsights;
