import axios from "axios"
import Cookies from "js-cookie"
import { FIRMFLOWURL } from "./url";
export const updateClient = (form) => {

    console.log(form);
    return new Promise(async resolve => {
        try {
            const token = Cookies.get('Bearer');
            const firm = await axios.get(`${FIRMFLOWURL}api/Firm`,
                {
                    headers: { 'Authorization': token }
                }
            )

            const response = await axios.put(`${FIRMFLOWURL}api/Client/firm/${firm.data.id}/client/${form.id}`,
                {
                    ...form
                },
                {
                    headers: { 'Authorization': token }
                }
            )
            resolve({ success: true, message: response.data.message});
        } catch (err) {
            console.log(err)
            if(err.message.includes(401)){
                Cookies.remove('Bearer');
                window.location.replace('/');
                return;
            }
            resolve({ success: false, message: err.response.data.message });
        }
    })
}