import { useEffect, useState } from "react";
import Select from "react-select";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import SelectServiceListBox from "../../components/analytics/SelectServiceListBox";
import TaxReturnAgenda from "../../components/analytics/TaxReturnAgenda";
import { getClients } from "../../services/getClients";
import { getSubscriptions } from "../../services/getSubscriptions";

const defaultSelect = (value, label, isShowing) => {
  const values = [
    {
      value: value,
      label: label,
      is_showing: isShowing,
    },
  ];
  return values;
};

const TaxAgendaReport = () => {
  const defaultId = "0";
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [clients, setClients] = useState(
    defaultSelect(0, "Select Client", true)
  );
  const [client, setClient] = useState({ id: defaultId });

  const [services, setServices] = useState(
    defaultSelect(0, "Select Service", true)
  );
  const [service, setService] = useState(services[0]);

  useEffect(() => {
    const getNewClients = async () => {
      const newClients = await getClients();
      if (newClients.success) {
        const arr = [];
        // eslint-disable-next-line
        newClients.data.map((newClient) => {
          arr.push({
            value: newClient.id,
            label: newClient.clientName,
            is_showing: true,
          });
          if (arr.length > 0) {
            setClients(arr);
            setClient(client[0]);
          }
        });
        console.log("clients: ", arr);
      }
    };
    getNewClients();
  }, []);

  const getClientServiceSubscriptions = async (clientId) => {
    const getNewSubs = await getSubscriptions(clientId);
    if (getNewSubs.success) {
      console.log(getNewSubs.data);
      const newSubs = getNewSubs.data.clientServiceSubscriptions;
      const arr = [];
      if (newSubs.length > 0) {
        newSubs.map((sub) => {
          arr.push({
            value: sub.clientServiceSubscriptionId,
            label: sub.serviceName,
            is_showing: true,
          });
        });
        setServices(arr);
        setService(arr[0]);
      } else {
        setServices(defaultSelect(0, "No Services", true));
        setService(defaultSelect(0, "No Services", true)[0]);
      }
      console.log("services: ", service);
    }
  };

  const onSelectClient = (e) => {
    const clientId = e.value;
    setClient(e);
    getClientServiceSubscriptions(clientId);
  };

  const onSelectService = (e) => {
    console.log(e);
    setService(e);
    console.log(service);
  };

  return (
    <section>
      <div className="flex h-screen overflow-hidden">
        {/* Sidebar */}
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        {/* Content area */}
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          {/*  Site header */}
          <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

          <main>
            <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
              {/* Page header */}
              <div className="sm:flex sm:justify-between sm:items-center mb-8">
                {/* Left: Title */}
                <div className="mb-4 sm:mb-0">
                  <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">
                    Tax Agenda Report
                  </h1>
                </div>
                {/* Right: Actions */}

                <div className="flex flex-col sm:flex-row sm:space-x-2 items-center">
                  <div className="flex flex-col items-start">
                    <h2 className="text-1xl md:text-1xl text-slate-800 font-bold">
                      Select Client
                    </h2>

                    {clients.length > 0 && (
                      <Select
                        onChange={(e) => onSelectClient(e)}
                        className="basic-single min-w-[180px] w-auto"
                        classNamePrefix="select"
                        isSearchable={true}
                        defaultValue={clients[0]}
                        options={clients}
                      />
                    )}
                  </div>

                  {/*  <div className="flex flex-col sm:flex-row sm:space-x-2 items-center">
                    <div className="flex flex-col items-start">
                      <h2 className="text-1xl md:text-1xl text-slate-800 font-bold">
                        Select Service
                      </h2>

                      <Select
                        onChange={(e) => onSelectService(e)}
                        className="basic-single min-w-[180px] w-auto"
                        classNamePrefix="select"
                        isSearchable={true}
                        defaultValue={services[0]}
                        options={services}
                      />
                    </div>
                  </div> */}
                  <SelectServiceListBox
                    services={services}
                    selectedService={service}
                    onChange={onSelectService}
                  />

                  {/*  <div className="flex flex-col items-start">
                      <h2 className="text-1xl md:text-1xl text-slate-800 font-bold">
                        Select Status
                      </h2>
                      {statuses.length > 0 && (
                        <Select
                          onChange={(e) => setStatus(e)}
                          className="basic-single min-w-[180px] w-auto"
                          classNamePrefix="select"
                          isSearchable={true}
                          defaultValue={statuses[0]}
                          options={statuses}
                          name="color"
                        />
                      )}
                    </div> */}
                </div>
              </div>
              {/* Table */}
              {/*   <div style={{ "overflow-x": "auto" }}>
                  <table className=" border-4">
                    <thead>
                      <tr>
                        {jobsByStatuses.map((status) => {
                          return <th>{status.serviceStatusName}</th>;
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {jobsByStatuses.map((status) => {
                        return (
                          <tr>
                            {status.jobs.map((job) => (
                              <td>{job.name}</td>
                            ))}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div> */}

              {/*   <div className="flex flex-row overflow-x-auto">
                  {jobsByStatuses.map((status) => {
                    return (
                      <div className="my-4 mx-2 w-full">
                        <h3 className=" font-bold">
                          {status.serviceStatusName}
                        </h3>
                        <ul className="max-w-md divide-y divide-gray-200 dark:divide-gray-700">
                          {status.jobs.map((job) => (
                            <li className="pb-3 sm:pb-4">
                              <div class="flex items-center space-x-4 rtl:space-x-reverse">
                                <div class="flex-1 min-w-0">
                                  <p class="text-sm font-medium text-gray-900 truncate dark:text-greeb">
                                    {job.name}
                                  </p>
                                  <p class="text-sm text-gray-500 truncate dark:text-gray-400">
                                    {job.notes}
                                  </p>
                                </div>
                                <div class="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                  $320
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    );
                  })}
                </div> */}
            </div>

            {service.value && service.value !== "0" ? (
              <>
                <div className="mb-4">
                  <h2 className="ml-8 mb-2 text-xl md:text-2xl text-slate-800 font-bold">
                    Client: {client.label}
                  </h2>
                  <h2 className="ml-8 mb-4 text-xl md:text-2xl text-slate-800 font-bold">
                    Service: {service.label}
                  </h2>
                  <a
                    rel="noreferrer"
                    href={`/clients/service/${service.value}/client/${client.value}`}
                    className="ml-8 mb-4 text-sm font-medium text-cyan-600 hover:underline dark:text-cyan-500"
                  >
                    View details
                  </a>
                </div>
                <TaxReturnAgenda serviceId={service.value} />
              </>
            ) : (
              <h4 className="m-8 font-bold">
                Please select a client and service
              </h4>
            )}
          </main>
        </div>
      </div>
    </section>
  );
};
export default TaxAgendaReport;
