import axios from "axios"
import { FIRMFLOWURL } from "./url";
export const registerFirm = (form) => {
    return new Promise(async resolve => {
        try {
            const response = await axios.post(`${FIRMFLOWURL}api/Registration/RegisterFirm`, {
                firmName: form.firmName,
                email: form.email,
                password: form.password,
                address: {
                    street: form.address,
                    city: form.city,
                    stateProvince: form.stateProvince,
                    postalCode: form.postalCode,
                    country: form.country,
                }
            })
            resolve(response.data)
        }catch(err){
            console.log(err)
            resolve({success: false, message: 'Error creating account'});
        }
    })
}