import { ClockIcon } from "@heroicons/react/24/solid";

const ButtonAddTimestamp = ({ onHandleClick }) => {
  return (
    <button
      onClick={onHandleClick}
      type="button"
      className="inline-flex items-center p-1 border border-transparent rounded-sm shadow-sm text-white bg-teal-500 hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 text-xs"
    >
      <span>Add Timestamp</span>
      <ClockIcon className="p-1 h-5 w-5" />
    </button>
  );
};
export default ButtonAddTimestamp;
