const ViewLinks = ({ label, links, setEditLinks }) => {
  return (
    <div className="w-full mt-8 mb-8">
      <label htmlFor="links" className="block text-sm font-medium text-gray-500">
        {label}
      </label>
      <ul className="max-w-md space-y-1 text-black-500 list-disc list-inside dark:text-black-400 mb-4">
        {links && links.length > 0 ? (
          links.map((link) => (
            <li key={link.name}>
              <a
                target="_blank"
                className="font-normal text-blue-600 dark:text-blue-500 hover:underline"
                rel="noreferrer"
                href={link.url}
              >
                {link.name}
              </a>
            </li>
          ))
        ) : (
          <p className="block text-sm font-medium text-gray-500 my-4">
            The links are empty.
          </p>
        )}
      </ul>

      <button
        onClick={() => setEditLinks(true)}
        className="btn bg-teal-500 hover:bg-teal-600 text-white h-10"
      >
        Edit
      </button>
    </div>
  );
};

export default ViewLinks;
