import { useState } from "react";
import toast from "react-hot-toast";
import { isValidLink } from "../../../helpers/urlRegexValidator";
import LinkForm from "./LinkForm";

const LINK_NAME = "name";
const LINK_URL = "url";

const EditLinks = ({ links, saveLinks, setEditLinks }) => {
  const [inputLinksJSON, setInputLinksJSON] = useState(links);
  const [newLink, setNewLink] = useState({ [LINK_NAME]: "", [LINK_URL]: "" });

  const handleChange = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    setNewLink({ ...newLink, [name]: value });
  };

  const addLink = () => {
    if (newLink[LINK_NAME] !== "" && isValidLink(newLink[LINK_URL])) {
      setInputLinksJSON([...inputLinksJSON, newLink]);
      setNewLink({ [LINK_NAME]: "", [LINK_URL]: "" });
    } else {
      toast.error("Adding empty or invalid links is not allowed.");
    }
  };

  const deleteLink = (name) => {
    const newLinks = inputLinksJSON.filter((link) => link[LINK_NAME] !== name);
    setInputLinksJSON(newLinks);
  };

  return (
    <div className="w-full mt-8 mb-8">
      <LinkForm
        newLink={newLink}
        addLink={addLink}
        handleChange={handleChange}
        deleteLink={deleteLink}
        LINK_NAME={LINK_NAME}
        LINK_URL={LINK_URL}
        inputLinksJSON={inputLinksJSON}
        divWidth="w-1/3"
        title="Permanent links"
      />
      <div className="w-full flex justify-end">
        <button
          onClick={() => setEditLinks(false)}
          className="btn bg-red-500 hover:bg-red-600 text-white h-10 mr-4"
        >
          Cancel
        </button>
        <button
          onClick={() => {
            saveLinks(JSON.stringify(inputLinksJSON));
            setEditLinks(false);
          }}
          className="btn bg-teal-500 hover:bg-teal-600 text-white h-10"
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default EditLinks;
