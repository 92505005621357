import { ArrowRightIcon, PlusIcon, TrashIcon } from "@heroicons/react/24/solid";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import FirmFlowTable from "../../components/mytasks/FirmFlowTable";
import { deleteEmployee } from "../../services/employees/deleteEmployee";
import { getEmployees } from "../../services/getEmployees";
import { getLoggedInEmployee } from "../../services/getLoggedInEmployee";
import DeleteEmployeeModal from "./DeleteEmployeeModal";

function EmployeesPage() {
  const navigate = useNavigate();

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [loggedInEmployee, setLoggedInEmployee] = useState({});
  //const [selectedItems, setSelectedItems] = useState([]);
  const [list, setList] = useState([]);
  const [employeesToChooseFrom, setEmployeesToChooseFrom] = useState([]);
  const [loading, setLoading] = useState(true);
  const rol = ["User", "Admin"];
  const [employeeToRemoveId, setEmployeeToRemoveId] = useState("");
  const [employeeThatReplaces, setEmployeeThatReplaces] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  //Set initial list
  useEffect(() => {
    callApi();
    getCurrentEmployee();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCurrentEmployee = async () => {
    const employee = await getLoggedInEmployee();
    if (employee.success) {
      setLoggedInEmployee(employee.data.employeeResponse);
      console.log(employee.data.employeeResponse);
    }
  };

  const callApi = async (e) => {
    //setSelectedItems([]);
    const res = await getEmployees();
    console.log(res);

    //Setting list, pagination labels, and client name
    setList(res.data.employees);
    setLoading(false);
  };

  const onHandleClickViewButton = (employeeId) => {
    console.log(employeeId);
    navigate(`/employees/${employeeId}`);
  };

  const onRemoveEmployee = async (employeeThatReplacesId) => {
    const response = await deleteEmployee(
      employeeToRemoveId,
      employeeThatReplacesId
    );
    if (response.success) {
      toast.success(response.data.message);
      const newEmployees = list.filter(
        (employee) => employee.employeeId !== employeeToRemoveId
      );
      setList(newEmployees);
    } else {
      toast.error(response.message);
    }
    setIsModalOpen(false);
    return;
  };

  const onHandleClickRemoveButton = (employeeId) => {
    setEmployeeToRemoveId(employeeId);
    const employeesToChoose = list.filter(
      (employee) => employee.employeeId !== employeeId
    );
    setEmployeesToChooseFrom(employeesToChoose);
    setEmployeeThatReplaces(employeesToChoose[0]);
    setIsModalOpen(true);
  };

  return (
    <section>
      <Toaster />
      <DeleteEmployeeModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        employeeThatReplaces={employeeThatReplaces}
        setEmployeeToReplace={setEmployeeThatReplaces}
        employees={employeesToChooseFrom}
        onRemoveEmployee={onRemoveEmployee}
      />
      <div className="flex h-screen overflow-hidden">
        {/* Sidebar */}
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        {/* Content area */}
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          {/*  Site header */}
          <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

          {!loading ? (
            <main>
              <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                {/* Page header */}
                <div className="sm:flex sm:justify-between sm:items-center mb-8">
                  {/* Left: Title */}
                  <div className="mb-4 sm:mb-0">
                    <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">
                      Employees
                    </h1>
                  </div>

                  {/* Right: Actions */}
                  <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end items-end gap-2">
                    {/* Delete button */}

                    {/* Add customer button */}
                    {loggedInEmployee.role == 1 && (
                      <button
                        onClick={() => navigate("/employees/create")}
                        className="btn bg-teal-500 hover:bg-teal-600 text-white h-10"
                      >
                        <PlusIcon className="w-5 h-5" />
                        <span className="xs:block ml-2">Add Employee</span>
                      </button>
                    )}
                  </div>
                </div>

                <FirmFlowTable
                  columns={[
                    {
                      Header: "Name",
                      accessor: "firstName",
                      Cell: (props) => (
                        <a
                          rel="noreferrer"
                          href={`/employees/${props.row.original.employeeId}`}
                          className="text-gray hover:text-blue-500 rounded-full"
                        >
                          {props.value}
                        </a>
                      ),
                    },
                    {
                      Header: "Email",
                      accessor: "email",
                      Cell: (props) => (
                        <a
                          rel="noreferrer"
                          href={`/employees/${props.row.original.employeeId}`}
                          className="text-gray hover:text-blue-500 rounded-full"
                        >
                          {props.value}
                        </a>
                      ),
                    },
                    {
                      Header: "Role",
                      accessor: "role",
                      Cell: (props) => (
                        <a
                          rel="noreferrer"
                          href={`/employees/${props.row.original.employeeId}`}
                          className="text-gray hover:text-blue-500 rounded-full"
                        >
                          {rol[props.value]}
                        </a>
                      ),
                    },
                    {
                      Header: "Actions",
                      accessor: "employeeId",
                      Cell: (props) => (
                        <div>
                          {loggedInEmployee.role == 1 ? (
                            <div>
                              <button
                                onClick={() =>
                                  onHandleClickViewButton(props.value)
                                }
                                className="btn bg-teal-500 hover:bg-teal-600 text-white h-10 mx-2"
                              >
                                <ArrowRightIcon className="mx-2 h-4 w-4" />
                              </button>
                              <button
                                onClick={() =>
                                  onHandleClickRemoveButton(props.value)
                                }
                                className="btn bg-red-500 hover:bg-red-600 text-white h-10 mx-2"
                              >
                                <TrashIcon className="mx-2 h-4 w-4" />
                              </button>
                            </div>
                          ) : (
                            <p>-</p>
                          )}
                        </div>
                      ),
                    },
                  ]}
                  data={list}
                />
              </div>
            </main>
          ) : (
            <div className="w-full mt-64 flex justify-center items-center max-w-9xl">
              <motion.div
                className="border border-t-gray-700 border-t-[4px] rounded-full border-gray-200 w-12 h-12"
                initial={{ rotateZ: 0 }}
                animate={{ rotateZ: 360 }}
                exit={{ rotateZ: 0 }}
                transition={{ repeat: Infinity, ease: "linear", duration: 1 }}
              ></motion.div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}
export default EmployeesPage;
