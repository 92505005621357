/* This example requires Tailwind CSS v2.0+ */
import { Dialog } from "@headlessui/react";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import RenewalJobsAutomaticallyCheckBox from "../../components/clientServiceSubscription/RenewalJobsAutomaticallyCheckBox";
import ButtonModal from "../../components/clientServiceSubscriptionTask/updateClientServiceSubscriptionTaskModal/ButtonModal";
import ErrorMessage from "../../components/clientServiceSubscriptionTask/updateClientServiceSubscriptionTaskModal/ErrorMessage";
import DisplayEfileStatusCheckbox from "./DisplayEfileStatusCheckbox";
import EfileStatusListBox from "./EfileStatusListBox";
import IntervalListBox from "./IntervalListBox";
import { updateClientService } from "../../services/clientService/updateClientService";
import toast from "react-hot-toast";
import AssignedByListBox from "../../components/clientServiceSubscriptionTask/updateClientServiceSubscriptionTaskModal/AssignedByListBox";
import { getEmployees } from "../../services/getEmployees";

const EFILE_STATUSES = ["Needs Extension", "On Extension", "Accepted", "Rejected"];
const INTERVALS = [
  "Annually",
  "Quarterly",
  "Montly",
  "Semi-Montly",
  "Bi-Weekly",
  "Weekly",
];

const UpdateClientServiceModal = ({
  open,
  setOpen,
  clientService,
  setClientService,
}) => {
  const [clientServiceForm, setClientServiceForm] = useState(null);
  const [errors, setErrors] = useState([]);
  const [employees, setEmployees] = useState([]);

  let completeButtonRef = useRef(null);

  useEffect(() => {
    setClientServiceForm(clientService);
    console.log(clientServiceForm);
  }, [clientService]);

  useEffect(() => {
    const getNewEmployees = async () => {
      const response = await getEmployees();
      setEmployees(response.data.employees);
    };
    getNewEmployees();
  }, []);

  const onHandleCancel = () => {
    setErrors([]);
    setClientServiceForm(clientService);
    setOpen(false);
  };

  const onChangeRenewJobsAutomatically = (e) => {
    console.log(e.target.checked);
    setClientServiceForm({
      ...clientServiceForm,
      isEveryItemRenewable: e.target.checked,
    });
  };

  const onHandleChangeYear = (e) => {
    setClientServiceForm({
      ...clientServiceForm,
      year: e.target.value,
    });
  };

  const onHandleChangeEfileStatus = (status) => {
    /**
     * Efile Statuses
     * 0 - Needs Extension
     * 2 - On Extension
     * 3 - Approved
     * 4 - Rejected
     */

    setClientServiceForm({
      ...clientServiceForm,
      efileStatus: status,
    });
  };

  const onHandleChangeInterval = (interval) => {
    console.log(interval);
    setClientServiceForm({ ...clientServiceForm, intervalType: interval });
  };

  const onChangeIsEfileStatusActive = (isEfileStatusActive) => {
    setClientServiceForm({
      ...clientServiceForm,
      isEfileStatusActive: isEfileStatusActive.target.checked,
    });
  };

  const onHandleChangeAssignEmployee = (newAssignedEmployee) => {
    setClientServiceForm({
      ...clientServiceForm,
      assignedEmployee: newAssignedEmployee,
    });
    console.log(clientServiceForm);
  };

  const onHandleChangeDueDate = (e) => {
    console.log(e.target.value);
    setClientServiceForm({ ...clientServiceForm, endDate: e.target.value });
  };

  /*  const validateForm = (form) => {
    console.log(form);
    const efilestatusnumber = EFILE_STATUSES.indexOf(form.efileStatus);
    console.log(efilestatusnumber);
    return true;
  }; */

  const onHandleSubmit = async (e) => {
    e.preventDefault();
    console.log(clientServiceForm);

    const response = await updateClientService(
      clientServiceForm.id,
      clientServiceForm
    );
    if (response.data.success) {
      toast.success(response.data.message);
      setClientService(clientServiceForm);
    } else {
      toast.error(response.data.message);
    }
    setOpen(false);
  };

  return (
    <AnimatePresence>
      {open && (
        <Dialog
          initialFocus={completeButtonRef}
          static
          as={motion.div}
          open={open}
          onClose={() => setOpen(false)}
          className="relative z-50"
        >
          {/* The backdrop, rendered as a fixed sibling to the panel container */}
          <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

          {/* Full-screen scrollable container */}
          <div className="fixed inset-0 w-screen overflow-y-auto">
            {/* Container to center the panel */}
            <div className="flex min-h-full items-center justify-center p-4">
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg w-full sm:p-6">
                <div className="text-center">
                  <div className="w-full flex justify-between">
                    <Dialog.Title className="text-center w-full text-lg leading-6 font-medium text-gray-900">
                      Update Service
                    </Dialog.Title>
                  </div>
                </div>

                <form onSubmit={onHandleSubmit}>
                  <div className="flex flex-col mt-5 sm:mt-6">
                    <h1 className="text-md font-normal">Due Date</h1>
                    <input
                      onChange={(e) => onHandleChangeDueDate(e)}
                      type="date"
                      id="endDate"
                      name="endDate"
                      className="form-select pr-1 rounded-md border border-gray-200"
                      value={
                        clientServiceForm.endDate &&
                        clientServiceForm.endDate.split("T")[0]
                      }
                    ></input>
                  </div>

                  <EfileStatusListBox
                    efileStatuses={EFILE_STATUSES}
                    selectedEfileStatus={clientServiceForm.efileStatus}
                    onChange={(e) => onHandleChangeEfileStatus(e)}
                  />
                  <DisplayEfileStatusCheckbox
                    name={"Display E-file status"}
                    value={clientServiceForm.isEfileStatusActive}
                    message={"Check this box to display the e-file status."}
                    checked={clientServiceForm.isEfileStatusActive}
                    onChange={(e) => onChangeIsEfileStatusActive(e)}
                  />
                  <RenewalJobsAutomaticallyCheckBox
                    name="Renew jobs automatically"
                    value={clientServiceForm.isEveryItemRenewable}
                    message="Check this box to renew this service automatically."
                    checked={clientServiceForm.isEveryItemRenewable}
                    onChange={(e) => onChangeRenewJobsAutomatically(e)}
                  />
                  <ErrorMessage error={errors["isEveryItemRenewable"]} />

                  {clientServiceForm.isEveryItemRenewable && (
                    <IntervalListBox
                      intervals={INTERVALS}
                      selectedInterval={clientServiceForm.intervalType}
                      onChange={onHandleChangeInterval}
                    />
                  )}

                  <AssignedByListBox
                    label="Assigned to"
                    employees={employees}
                    assignedBy={clientServiceForm.assignedEmployee}
                    onChange={onHandleChangeAssignEmployee}
                  />

                  <div className="mt-5 sm:mt-6">
                    <h1 className="text-md font-normal">Year</h1>
                    <input
                      onChange={(e) => onHandleChangeYear(e)}
                      className="relative inline-block text-left w-full"
                      type="number"
                      min="2000"
                      max="2100"
                      step="1"
                      value={clientServiceForm.year}
                    />
                  </div>

                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                    <ButtonModal
                      name="Save"
                      type="submit"
                      className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-teal-500 text-base font-medium text-white hover:bg-teal-600 focus:outline-none focus:ring-0 sm:col-start-2 sm:text-sm"
                    />
                    <ButtonModal
                      name="Cancel"
                      type="button"
                      onClick={onHandleCancel}
                      className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-0 sm:mt-0 sm:col-start-1 sm:text-sm"
                    />
                  </div>
                </form>
              </Dialog.Panel>
            </div>
          </div>
        </Dialog>
      )}
    </AnimatePresence>
  );
};
export default UpdateClientServiceModal;
