import { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { getJobsReport } from "../../services/analytics/getJobsReport";

const ClientsByJobsV2 = ({ task }) => {
  const [clientsByTasks, setClientsByTasks] = useState([]);

  useEffect(() => {
    const getClientsByServices = async () => {
      console.log(task);
      const response = await getJobsReport(task.id);
      console.log(response);
      if (response.success) {
        setClientsByTasks(response.data.clientByTasks);
        
      } else {
        toast.error("Length or names of selected services don't match, please verify.")
      }
    };
    getClientsByServices();
  }, [task]);
  return (
    <div className="flex flex-row overflow-x-auto mb-12">
      <Toaster />
      {clientsByTasks &&
        clientsByTasks.map((task) => {
          return (
            <div
              className="w-full mx-4 p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-8  dark:border-gray-700 min-w-fit min-h-full"
              key={task.taskId}
            >
              <div className="flex items-center justify-between mb-4">
                {/*  <p className="text-sm font-medium text-blue-600 hover:underline dark:text-blue-500">
                  Total: {task.clients.length}
                </p> */}
                <h5 className="text-xl font-bold leading-none text-gray-900 dark:text-gray-800">
                  {task.taskName}
                </h5>
              </div>
              <div className="flow-root">
                <ul
                  className="divide-y divide-gray-200 dark:divide-gray-700"
                  key={task.id}
                >
                  {task.clients.length > 0 ? (
                    <>
                      <p className=" font-semibold dark:text-gray-700">
                        Total: {task.clients.length}
                      </p>
                      {task.clients.map((client) => (
                        <li className="py-3 sm:py-4" key={client.id}>
                          <div className="flex items-center">
                            <div className="flex-shrink-0"></div>
                            <div
                              className="flex-1 min-w-0 ms-4"
                              href={`/clients/service/${client.serviceId}/client/${client.id}`}
                              rel="noreferrer"
                            >
                              <p className="text-md font-medium text-gray-900 truncate dark:text-green-500">
                                <a
                                  href={`/clients/service/${client.serviceId}/client/${client.id}`}
                                  rel="noreferrer"
                                >
                                  Client: {client.name}
                                </a>
                              </p>
                              <p className="text-md font-medium">
                                Service: {client.serviceName}
                              </p>
                              <small className="text-gray-900 font-medium">
                                Year: {client.serviceYear}
                              </small>
                            </div>
                            {/* <div className="block-flex items-center text-base font-semibold text-gray-900 dark:text-gray-700">
                            Due date {moment(task.dueDate).format("MM-DD-YYYY")}
                          </div> */}
                          </div>
                        </li>
                      ))}
                    </>
                  ) : (
                    <li className="py-3 sm:py-4">
                      No clients assigned to this status.
                    </li>
                  )}
                </ul>
              </div>
            </div>
          );
        })}
    </div>
  );
};
export default ClientsByJobsV2;
