import { useMemo } from "react";
import FirmFlowTable from "../../../../components/mytasks/FirmFlowTable"; // Adjust the import path as necessary
import NewBadge from "../../../../components/NewBadge";

const STATUSES = ["To DO", "In Progress", "Complete"];

const JobAccountabilityTable = ({ employeeName, onHandleClickNotes, data }) => {
    const columns = useMemo(
        () => [
            {
                Header: "Department",
                accessor: "departmentName",
                Cell: (props) => (
                    <a
                        rel="noreferrer"
                        href={`/clients/service/${props.row.original.serviceId}/client/${props.row.original.clientId}`}
                        className="text-gray hover:text-blue-500 rounded-full"
                    >
                        {props.row.original.isNew === true ? (
                            <span>
                                <NewBadge label="New" /> {props.value}
                            </span>
                        ) : (
                            props.value
                        )}
                    </a>
                ),
            },
            {
                Header: "Client",
                accessor: "clientName",
                Cell: (props) => (
                    <a
                        rel="noreferrer"
                        href={`/clients/service/${props.row.original.serviceId}/client/${props.row.original.clientId}`}
                        className="text-gray hover:text-blue-500 rounded-full"
                    >
                        {props.value}
                    </a>
                ),
            },
            {
                Header: "Service",
                accessor: "serviceName",
                Cell: (props) => (
                    <a
                        rel="noreferrer"
                        href={`/clients/service/${props.row.original.serviceId}/client/${props.row.original.clientId}`}
                        className="text-gray hover:text-blue-500 rounded-full"
                    >
                        {props.value}
                    </a>
                ),
            },
            {
                Header: "Assigend to",
                accessor: "assignedEmployee",
                Cell: (props) => (
                    <a
                        rel="noreferrer"
                        href={`/clients/service/${props.row.original.serviceId}/client/${props.row.original.clientId}`}
                        className="text-gray hover:text-blue-500 rounded-full"
                    >
                        {props.value != null
                            ? props.value.firstName + " " + props.value.lastName
                            : "Not Assigned"}
                    </a>
                ),
            },

            {
                Header: "Assigned Date",
                accessor: "subscriptionStartDate",
                Cell: (props) => (
                    <a
                        rel="noreferrer"
                        href={`/clients/service/${props.row.original.serviceId}/client/${props.row.original.clientId}`}
                        className="text-gray hover:text-blue-500 rounded-full"
                    >
                        {props.value
                            ? new Date(props.value).toDateString().substring(4)
                            : "Not Assigned"}
                    </a>
                ),
            },
            {
                Header: "Due Date",
                accessor: "subscriptionEndDate",
                Cell: (props) => (
                    <a
                        rel="noreferrer"
                        href={`/clients/service/${props.row.original.serviceId}/client/${props.row.original.clientId}`}
                        className="text-gray hover:text-blue-500 rounded-full"
                    >
                        {props.value
                            ? new Date(props.value).toDateString().substring(4)
                            : "Not Assigned"}
                    </a>
                ),
            },
            {
                Header: "Status",
                accessor: "status",
                Cell: (props) => (
                    <a
                        rel="noreferrer"
                        href={`/clients/service/${props.row.original.serviceId}/client/${props.row.original.clientId}`}
                        className="text-gray hover:text-blue-500 rounded-full"
                    >
                        {STATUSES[props.value]}
                    </a>
                ),
            },
            /*  {
                Header: "Notes",
                accessor: "notes",
                Cell: (props) => (
                    <p
                        onClick={() =>
                            onHandleClickNotes({
                                jobId: props.row.original.id,
                                notes: props.value,
                            })
                        }
                        className="text-gray hover:text-blue-500 rounded-full cursor-pointer"
                    >
                        {props.value ? props.value : "Click to add notes"}
                    </p>
                ),
            }, */
        ],
        [employeeName]
    );
    return (
        <div>
            <FirmFlowTable columns={columns} data={data} />
        </div>
    );
};

export default JobAccountabilityTable;
