import { useEffect, useState } from "react";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import { getDueDateReport } from "../../services/analytics/getDueDateReport";
import { useMemo } from "react";
import FirmFlowTable from "../../components/mytasks/FirmFlowTable";
import moment from "moment";
import { getServiceDepartments } from "../../services/service/getServiceDepartments";
import Select from "react-select";
import { getEmployees } from "../../services/getEmployees";
import { getDueDateReportServices } from "../../services/analytics/getDueDateReportServices";
import { getDueDateReportChecklistItems } from "../../services/analytics/getDueDateReportChecklistItems";

const DueDateStatuses = [
    {
        name: "Past Due",
        alias: "pastDue",
        color: "linear-gradient(90deg, rgba(239,68,68,1) 0%, rgba(220,38,38,1) 100%)",
        numberOfJobs: 0,
        jobs: null,
        numberOfServices: 0,
        services: null,
        numberOfChecklistItems: 0,
        checklistItems: null,
    },
    {
        name: "Today",
        alias: "today",
        color: "linear-gradient(90deg, rgba(217,119,6,1) 0%, rgba(245,158,11,1) 100%)",
        numberOfJobs: 0,
        jobs: null,
        numberOfServices: 0,
        services: null,
        numberOfChecklistItems: 0,
        checklistItems: null,
    },
    {
        name: "Tomorrow",
        alias: "tomorrow",
        color: "linear-gradient(90deg, rgba(217,119,6,1) 0%, rgba(245,158,11,1) 100%)",
        numberOfJobs: 0,
        jobs: null,
        numberOfServices: 0,
        services: null,
        numberOfChecklistItems: 0,
        checklistItems: null,
    },
    {
        name: "Next week",
        alias: "nextWeek",
        color: "linear-gradient(90deg, rgba(13,148,136,1) 0%, rgba(20,184,166,1) 100%)",
        numberOfJobs: 0,
        jobs: null,
        numberOfServices: 0,
        services: null,
        numberOfChecklistItems: 0,
        checklistItems: null,
    },
    {
        name: "Everything Thereafter",
        alias: "thereafter",
        color: "linear-gradient(90deg, rgba(13,148,136,1) 0%, rgba(20,184,166,1) 100%)",
        numberOfJobs: 0,
        jobs: null,
        numberOfServices: 0,
        services: null,
        numberOfChecklistItems: 0,
        checklistItems: null,
    },
];

const DueDateReport = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [dueDateReports, setDueDateReports] = useState(DueDateStatuses);
    const [selectedReport, setSelectedReport] = useState(null);
    const [selectedReportStatus, setSelectedReportStatus] = useState(null);
    const [selectedReportChecklistItems, setSelectedReportChecklistItems] =
        useState(null);
    const defaultId = "00000000-0000-0000-0000-000000000000";
    const [selectedReportName, setSelectedReportName] = useState(null);

    const items = [
        {
            value: 1,
            label: "Jobs",
            is_showing: true,
        },
        {
            value: 2,
            label: "Services",
            is_showing: true,
        },
        {
            value: 3,
            label: "Checklist Items",
            is_showing: true,
        },
    ];
    const [selectedItem, setSelectedItem] = useState(items[0]);

    const [departments, setDepartments] = useState([
        {
            value: defaultId,
            label: "All",
            is_showing: true,
        },
    ]);
    const [selectedDepartment, setSelectedDepartment] = useState({});

    const [employees, setEmployees] = useState([
        {
            value: defaultId,
            label: "All",
            is_showing: true,
        },
    ]);
    const [selectedEmployee, setSelectedEmployee] = useState({ id: defaultId });

    const onHandleClickReport = (jobs, reportName) => {
        setSelectedReport(jobs);
        setSelectedReportName(reportName);
    };

    const onHandleClickReportStatus = (services, reportName) => {
        setSelectedReportStatus(services);
        setSelectedReportName(reportName);
    };

    const onHandleClickReportChecklistItems = (checklistItems, reportName) => {
        setSelectedReportChecklistItems(checklistItems);
        setSelectedReportName(reportName);
    };

    const fetchDepartments = async () => {
        const response = await getServiceDepartments();
        const departmentsArray = [];
        if (response.data.success) {
            response.data.departments.map((department) => {
                departmentsArray.push({
                    value: department.id,
                    label: department.name,
                    is_showing: true,
                });
            });
            setDepartments([departments[0], ...departmentsArray]);
        }
        console.log(response);
    };

    async function fetchEmployees() {
        Promise.all([getEmployees()]).then((res) => {
            let data = res[0].data.employees;
            console.log(data);
            const arr = [];
            // eslint-disable-next-line
            data.map((emp, i) => {
                arr.push({
                    value: emp.employeeId,
                    label: `${emp.firstName} ${emp.lastName}`,
                    is_showing: true,
                });
                if (arr.length > 0) {
                    // setEmployee(arr[0]);
                    let tmp = [employees[0], ...arr];
                    // console.log(tmp);
                    setEmployees(tmp);
                }
            });
            // console.log(arr);
        });
    }

    const getDueDateReportJobs = async () => {
        const newReport = await Promise.all(
            dueDateReports.map(async (report) => {
                const request = {
                    rangeName: report.alias,
                    departmentId: selectedDepartment.value,
                    employeeId: selectedEmployee.value,
                };
                const response = await getDueDateReport(request);
                if (response.success) {
                    return {
                        ...report,
                        numberOfJobs: response.data.report.numberOfJobs,
                        jobs: response.data.report.jobs,
                    };
                } else {
                    return report;
                }
            })
        );
        console.log(newReport);
        setDueDateReports(newReport);
    };

    const getServicesReport = async () => {
        const newReport = await Promise.all(
            dueDateReports.map(async (report) => {
                const request = {
                    rangeName: report.alias,
                    departmentId: selectedDepartment.value,
                    employeeId: selectedEmployee.value,
                };
                const response = await getDueDateReportServices(request);
                if (response.success) {
                    console.log(response.data);
                    return {
                        ...report,
                        numberOfServices: response.data.report.numberOfServices,
                        services: response.data.report.services,
                    };
                } else {
                    return report;
                }
            })
        );
        console.log(newReport);
        setDueDateReports(newReport);
    };

    const getChecklistReport = async () => {
        const newReport = await Promise.all(
            dueDateReports.map(async (report) => {
                const request = {
                    rangeName: report.alias,
                    departmentId: selectedDepartment.value,
                    employeeId: selectedEmployee.value,
                };
                const response = await getDueDateReportChecklistItems(request);
                if (response.success) {
                    console.log(response.data);
                    return {
                        ...report,
                        numberOfChecklistItems:
                            response.data.report.numberOfChecklistItems,
                        checklistItems: response.data.report.checklistItems,
                    };
                } else {
                    return report;
                }
            })
        );
        console.log(newReport);
        setDueDateReports(newReport);
    };

    const getReport = async () => {
        if (selectedItem.label === "Jobs") {
            await getDueDateReportJobs();
        } else if (selectedItem.label === "Services") {
            await getServicesReport();
        } else {
            await getChecklistReport();
        }
    };

    const InitializeReports = () => {
        setSelectedReport(null);
        setSelectedReportStatus(null);
        setSelectedReportChecklistItems(null);
    };

    const onChangeItem = (item) => {
        setSelectedItem(item);
        InitializeReports();
    };

    const onChangeDepartment = (department) => {
        setSelectedDepartment(department);
        InitializeReports();
    };

    const onChangeEmployee = (employee) => {
        setSelectedEmployee(employee);
        InitializeReports();
    };

    useEffect(() => {
        getReport();
    }, [selectedDepartment, selectedEmployee, selectedItem]);

    useEffect(() => {
        fetchDepartments();
    }, []);

    useEffect(() => {
        fetchEmployees();
    }, []);

    const columns = useMemo(() => [
        {
            Header: "Service",
            accessor: "clientServiceSubscriptionServiceName",
            Cell: (props) => (
                <a
                    rel="noreferrer"
                    href={`/clients/service/${props.row.original.clientServiceSubscriptionId}/client/${props.row.original.clientId}`}
                    className="text-gray hover:text-blue-500 rounded-full"
                >
                    {props.value}
                </a>
            ),
        },
        {
            Header: "Client",
            accessor: "clientName",
            Cell: (props) => (
                <a
                    rel="noreferrer"
                    href={`/clients/service/${props.row.original.clientServiceSubscriptionId}/client/${props.row.original.clientId}`}
                    className="text-gray hover:text-blue-500 rounded-full"
                >
                    {props.value}
                </a>
            ),
        },
        {
            Header: "Job",
            accessor: "name",
            Cell: (props) => (
                <a
                    rel="noreferrer"
                    href={`/clients/service/${props.row.original.clientServiceSubscriptionId}/client/${props.row.original.clientId}`}
                    className="text-gray hover:text-blue-500 rounded-full"
                >
                    {props.value}
                </a>
            ),
        },
        {
            Header: "Year",
            accessor: "clientServiceSubscriptionYear",
            Cell: (props) => (
                <a
                    rel="noreferrer"
                    href={`/clients/service/${props.row.original.clientServiceSubscriptionId}/client/${props.row.original.clientId}`}
                    className="text-gray hover:text-blue-500 rounded-full"
                >
                    {props.value}
                </a>
            ),
        },
        {
            Header: "Assigned date",
            accessor: "assignedDate",
            Cell: (props) => (
                <a
                    rel="noreferrer"
                    href={`/clients/service/${props.row.original.clientServiceSubscriptionId}/client/${props.row.original.clientId}`}
                    className="text-gray hover:text-blue-500 rounded-full"
                >
                    {moment(props.value).format("MM-DD-YYYY")}
                </a>
            ),
        },
        {
            Header: "Due date",
            accessor: "dueDate",
            Cell: (props) => (
                <a
                    rel="noreferrer"
                    href={`/clients/service/${props.row.original.clientServiceSubscriptionId}/client/${props.row.original.clientId}`}
                    className="text-gray hover:text-blue-500 rounded-full"
                >
                    {moment(props.value).format("MM-DD-YYYY")}
                </a>
            ),
        },
    ]);

    const columnsForServices = useMemo(() => [
        {
            Header: "Service",
            accessor: "serviceName",
            Cell: (props) => (
                <a
                    rel="noreferrer"
                    href={`/clients/service/${props.row.original.serviceId}/client/${props.row.original.clientId}`}
                    className="text-gray hover:text-blue-500 rounded-full"
                >
                    {props.value}
                </a>
            ),
        },
        {
            Header: "Client",
            accessor: "clientName",
            Cell: (props) => (
                <a
                    rel="noreferrer"
                    href={`/clients/service/${props.row.original.serviceId}/client/${props.row.original.clientId}`}
                    className="text-gray hover:text-blue-500 rounded-full"
                >
                    {props.value}
                </a>
            ),
        },
        {
            Header: "Year",
            accessor: "year",
            Cell: (props) => (
                <a
                    rel="noreferrer"
                    href={`/clients/service/${props.row.original.clientServiceSubscriptionId}/client/${props.row.original.clientId}`}
                    className="text-gray hover:text-blue-500 rounded-full"
                >
                    {props.value}
                </a>
            ),
        },
        {
            Header: "Assigned date",
            accessor: "subscriptionStartDate",
            Cell: (props) => (
                <a
                    rel="noreferrer"
                    href={`/clients/service/${props.row.original.clientServiceSubscriptionId}/client/${props.row.original.clientId}`}
                    className="text-gray hover:text-blue-500 rounded-full"
                >
                    {moment(props.value).format("MM-DD-YYYY")}
                </a>
            ),
        },
        {
            Header: "Due date",
            accessor: "subscriptionEndDate",
            Cell: (props) => (
                <a
                    rel="noreferrer"
                    href={`/clients/service/${props.row.original.clientServiceSubscriptionId}/client/${props.row.original.clientId}`}
                    className="text-gray hover:text-blue-500 rounded-full"
                >
                    {moment(props.value).format("MM-DD-YYYY")}
                </a>
            ),
        },
    ]);

    const columnsForChecklistItems = useMemo(() => [
        {
            Header: "Service",
            accessor: "serviceName",
            Cell: (props) => (
                <a
                    rel="noreferrer"
                    href={`/clients/service/${props.row.original.clientServiceSubscriptionId}/client/${props.row.original.clientId}`}
                    className="text-gray hover:text-blue-500 rounded-full"
                >
                    {props.value}
                </a>
            ),
        },
        {
            Header: "Client",
            accessor: "clientName",
            Cell: (props) => (
                <a
                    rel="noreferrer"
                    href={`/clients/service/${props.row.original.clientServiceSubscriptionId}/client/${props.row.original.clientId}`}
                    className="text-gray hover:text-blue-500 rounded-full"
                >
                    {props.value}
                </a>
            ),
        },
        {
            Header: "Job Name",
            accessor: "jobName",
            Cell: (props) => (
                <a
                    rel="noreferrer"
                    href={`/clients/service/${props.row.original.clientServiceSubscriptionId}/client/${props.row.original.clientId}`}
                    className="text-gray hover:text-blue-500 rounded-full"
                >
                    {props.value}
                </a>
            ),
        },
        {
            Header: "Name",
            accessor: "description",
            Cell: (props) => (
                <a
                    rel="noreferrer"
                    href={`/clients/service/${props.row.original.clientServiceSubscriptionId}/client/${props.row.original.clientId}`}
                    className="text-gray hover:text-blue-500 rounded-full"
                >
                    {props.value}
                </a>
            ),
        },
        {
            Header: "Year",
            accessor: "year",
            Cell: (props) => (
                <a
                    rel="noreferrer"
                    href={`/clients/service/${props.row.original.clientServiceSubscriptionId}/client/${props.row.original.clientId}`}
                    className="text-gray hover:text-blue-500 rounded-full"
                >
                    {props.value}
                </a>
            ),
        },
        {
            Header: "Assigned date",
            accessor: "assignedDate",
            Cell: (props) => (
                <a
                    rel="noreferrer"
                    href={`/clients/service/${props.row.original.clientServiceSubscriptionId}/client/${props.row.original.clientId}`}
                    className="text-gray hover:text-blue-500 rounded-full"
                >
                    {moment(props.value).format("MM-DD-YYYY")}
                </a>
            ),
        },
        {
            Header: "Due date",
            accessor: "dueDate",
            Cell: (props) => (
                <a
                    rel="noreferrer"
                    href={`/clients/service/${props.row.original.clientServiceSubscriptionId}/client/${props.row.original.clientId}`}
                    className="text-gray hover:text-blue-500 rounded-full"
                >
                    {moment(props.value).format("MM-DD-YYYY")}
                </a>
            ),
        },
    ]);

    return (
        <section>
            <div className="flex h-screen overflow-hidden">
                {/* Sidebar */}
                <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

                {/* Content area */}
                <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                    {/*  Site header */}
                    <Header
                        sidebarOpen={sidebarOpen}
                        setSidebarOpen={setSidebarOpen}
                    />

                    <main>
                        <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                            {/* Page header */}
                            <div className="sm:flex sm:justify-between sm:items-center mb-8">
                                {/* Left: Title */}
                                <div className="mb-4 sm:mb-0">
                                    <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">
                                        Due Date Report{" "}
                                        {/* {selectedYear && selectedYear.name} */}
                                    </h1>
                                </div>
                                <div className="flex flex-col sm:flex-row sm:space-x-2 items-center">
                                    <div className="flex flex-col items-start">
                                        <h2 className="text-1xl md:text-1xl text-slate-800 font-bold">
                                            Select Assignment
                                        </h2>
                                        {items.length > 0 && (
                                            <Select
                                                onChange={(e) => onChangeItem(e)}
                                                className="basic-single min-w-[180px] w-auto"
                                                classNamePrefix="select"
                                                isSearchable={true}
                                                defaultValue={items[0]}
                                                options={items}
                                                name="color"
                                            />
                                        )}
                                    </div>
                                    <div className="flex flex-col items-start">
                                        <h2 className="text-1xl md:text-1xl text-slate-800 font-bold">
                                            Select Employee
                                        </h2>
                                        {employees.length > 0 && (
                                            <Select
                                                onChange={(e) => onChangeEmployee(e)}
                                                className="basic-single min-w-[180px] w-auto"
                                                classNamePrefix="select"
                                                isSearchable={true}
                                                defaultValue={employees[0]}
                                                options={employees}
                                                name="color"
                                            />
                                        )}
                                    </div>
                                    <div className="flex flex-col items-start">
                                        <h2 className="text-1xl md:text-1xl text-slate-800 font-bold">
                                            Select Department
                                        </h2>
                                        {departments.length > 0 && (
                                            <Select
                                                onChange={(e) =>
                                                    onChangeDepartment(e)
                                                }
                                                className="basic-single min-w-[180px] w-auto"
                                                classNamePrefix="select"
                                                isSearchable={true}
                                                defaultValue={departments[0]}
                                                options={departments}
                                                name="departments"
                                            />
                                        )}
                                    </div>
                                </div>

                                {/*  <div className="flex flex-col sm:flex-row sm:space-x-2 items-center">
                  <div className="flex flex-col items-start">
                    {selectedYear !== null && (
                      <SelectYearEfileStatusReportListBox
                        label={"Select year"}
                        onChange={onHandleChangeYear}
                        years={years}
                        selectedYear={selectedYear}
                      />
                    )}
                  </div>

                </div> */}
                                {/* Right: Actions */}
                            </div>
                        </div>

                        {selectedItem.label === "Jobs" ? (
                            <div className="flex flex-row overflow-x-auto mb-12">
                                {dueDateReports.map((status, index) => {
                                    return (
                                        <div
                                            className={`relative flex flex-col bg-clip-border rounded-xl text-white shadow-gray-900/20 shadow-md w-full p-8 mx-12`}
                                            style={{
                                                background: `${status.color}`,
                                            }}
                                        >
                                            <div className="relative pb-8 m-0 mb-8 overflow-hidden text-center text-gray-700 bg-transparent border-b rounded-none shadow-none bg-clip-border border-white/10">
                                                <p className="block font-sans text-sm antialiased font-normal leading-normal text-white uppercase">
                                                    {status.name}
                                                </p>
                                                <h1 className="flex justify-center gap-1 mt-6 font-sans antialiased font-normal tracking-normal text-white text-7xl">
                                                    <span className="mt-2 text-2xl"></span>
                                                    {status.numberOfJobs}
                                                </h1>
                                                <p className="text-white text-lg mt-6">
                                                    JOBS
                                                </p>
                                            </div>
                                            <p
                                                onClick={() =>
                                                    onHandleClickReport(
                                                        status.jobs,
                                                        status.name
                                                    )
                                                }
                                                className="justify-center uppercase hover:cursor-pointer hover:text-gray-600"
                                            >
                                                View Details
                                            </p>
                                        </div>
                                    );
                                })}
                            </div>
                        ) : selectedItem.label === "Services" ? (
                            <div className="flex flex-row overflow-x-auto mb-12">
                                {dueDateReports.map((status, index) => {
                                    return (
                                        <div
                                            className={`relative flex flex-col bg-clip-border rounded-xl text-white shadow-gray-900/20 shadow-md w-full p-8 mx-12`}
                                            style={{
                                                background: `${status.color}`,
                                            }}
                                        >
                                            <div className="relative pb-8 m-0 mb-8 overflow-hidden text-center text-gray-700 bg-transparent border-b rounded-none shadow-none bg-clip-border border-white/10">
                                                <p className="block font-sans text-sm antialiased font-normal leading-normal text-white uppercase">
                                                    {status.name}
                                                </p>
                                                <h1 className="flex justify-center gap-1 mt-6 font-sans antialiased font-normal tracking-normal text-white text-7xl">
                                                    <span className="mt-2 text-2xl"></span>
                                                    {status.numberOfServices}
                                                </h1>
                                                <p className="text-white text-lg mt-6">
                                                    SERVICES
                                                </p>
                                            </div>
                                            <p
                                                onClick={() =>
                                                    onHandleClickReportStatus(
                                                        status.services,
                                                        status.name
                                                    )
                                                }
                                                className="justify-center uppercase hover:cursor-pointer hover:text-gray-600"
                                            >
                                                View Details
                                            </p>
                                        </div>
                                    );
                                })}
                            </div>
                        ) : (
                            <div className="flex flex-row overflow-x-auto mb-12">
                                {dueDateReports.map((status, index) => {
                                    return (
                                        <div
                                            className={`relative flex flex-col bg-clip-border rounded-xl text-white shadow-gray-900/20 shadow-md w-full p-8 mx-12`}
                                            style={{
                                                background: `${status.color}`,
                                            }}
                                        >
                                            <div className="relative pb-8 m-0 mb-8 overflow-hidden text-center text-gray-700 bg-transparent border-b rounded-none shadow-none bg-clip-border border-white/10">
                                                <p className="block font-sans text-sm antialiased font-normal leading-normal text-white uppercase">
                                                    {status.name}
                                                </p>
                                                <h1 className="flex justify-center gap-1 mt-6 font-sans antialiased font-normal tracking-normal text-white text-7xl">
                                                    <span className="mt-2 text-2xl"></span>
                                                    {status.numberOfChecklistItems}
                                                </h1>
                                                <p className="text-white text-lg mt-6">
                                                    CHECKLIST ITEMS
                                                </p>
                                            </div>
                                            <p
                                                onClick={() =>
                                                    onHandleClickReportChecklistItems(
                                                        status.checklistItems,
                                                        status.name
                                                    )
                                                }
                                                className="justify-center uppercase hover:cursor-pointer hover:text-gray-600"
                                            >
                                                View Details
                                            </p>
                                        </div>
                                    );
                                })}
                            </div>
                        )}

                        <div className="relative flex flex-col mx-12">
                            {selectedReport !== null &&
                                selectedItem.label === "Jobs" && (
                                    <>
                                        <h2 className="pb-8 text-black font-bold text-2xl">
                                            {selectedReportName} Jobs
                                        </h2>
                                        <FirmFlowTable
                                            columns={columns}
                                            data={selectedReport}
                                        />
                                    </>
                                )}

                            {selectedReportStatus !== null &&
                                selectedItem.label === "Services" && (
                                    <>
                                        <h2 className="pb-8 text-black font-bold text-2xl">
                                            {selectedReportName} {selectedItem.label}
                                        </h2>
                                        <FirmFlowTable
                                            columns={columnsForServices}
                                            data={selectedReportStatus}
                                        />
                                    </>
                                )}

                            {selectedReportChecklistItems !== null &&
                                selectedItem.label === "Checklist Items" && (
                                    <>
                                        <h2 className="pb-8 text-black font-bold text-2xl">
                                            {selectedReportName} {selectedItem.label}
                                        </h2>
                                        <FirmFlowTable
                                            columns={columnsForChecklistItems}
                                            data={selectedReportChecklistItems}
                                        />
                                    </>
                                )}
                        </div>
                    </main>
                </div>
            </div>
        </section>
    );
};
export default DueDateReport;
