import Chart from "react-apexcharts";

export const TimelineChart = (props) => {
  return (
      <div id="timeline-chart">
        <Chart
          options={props.settings.options}
          series={props.settings.series}
          type={props.settings.options.chart.type}
          width={500}
          height={300}
        />
      </div>
  );
};
