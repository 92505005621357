import React, { useState, useRef } from "react";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
// import DeleteButton from "../../components/actions/DeleteButton";
import { motion } from "framer-motion";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
//import { deleteService } from "../../services/deleteService";
import toast, { Toaster } from "react-hot-toast";
import FirmFlowTable from "../../components/mytasks/FirmFlowTable";
import { getJobs } from "../../services/jobs/getJobs";
import DeleteModal from "../../utils/DeleteModal";
import { deleteJob } from "../../services/jobs/deleteJob";
import FullSizeModal from "../../components/shared/FullSizeModal";
import CreateJob from "./CreateJob";
import { cloneJob } from "../../services/jobs/cloneJob";
import GenericModal from "../../utils/GenericModal";
import CloneJobForm from "./CloneJobForm";
import { getLoggedInEmployee } from "../../services/getLoggedInEmployee";

function JobsList() {
    const navigate = useNavigate();
    const [sidebarOpen, setSidebarOpen] = useState(false);
    // const [selectedItems, setSelectedItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [jobs, setJobs] = useState([]);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [jobIdToDelete, setJobIdToDelete] = useState("");
    const [isAddJobModalOpen, setIsAddJobModalOpen] = useState(false);
    const cancelButtonRef = useRef(null);
    const [jobToCloneId, setJobToCloneId] = useState(null);
    const [isCloneJobModalOpen, setIsCloneJobModalOpen] = useState(false);
    const [loggedInEmployee, setLoggedInEmployee] = useState({});

    //Set initial list
    useEffect(() => {
        getNewJobs();
        setLoading(false);
        getCurrentEmployee();
    }, []);

    const getCurrentEmployee = async () => {
        const employee = await getLoggedInEmployee();
        if (employee.success) {
            setLoggedInEmployee(employee.data.employeeResponse);
            console.log(employee.data.employeeResponse);
        }
    };

    const getNewJobs = async () => {
        const response = await getJobs();
        console.log(response);
        if (response.success) {
            setJobs(response.data.jobs);
        } else {
            toast.error(response.data.message);
        }
    };

    const onClickRemoveJob = (jobId) => {
        setJobIdToDelete(jobId);
        setIsDeleteModalOpen(true);
    };

    const onRemoveJob = async () => {
        console.log(jobIdToDelete);
        const response = await deleteJob(jobIdToDelete);
        if (response.success) {
            toast.success(response.data.message);
            getNewJobs();
            setIsDeleteModalOpen(false);
        } else {
            toast.error(response.data.message);
        }
    };

    const onClickAddJob = () => {
        setIsAddJobModalOpen(true);
    };

    const onCloneJob = async (newName) => {
        const response = await cloneJob(jobToCloneId, newName);
        console.log(response);
        if (response.success) {
            toast.success(response.data.message);
            navigate(`/templates/job/${response.data.clonedJobId}`);
            //getNewJobs();
        } else {
            toast.error(response.data.message);
        }
        setIsCloneJobModalOpen(false);
    };
    /* const onHandleClickDeleteDeparment = (jobId) => {
    setIsDeleteModalOpen(true);
    setJobIdToDelete(jobId)
  } */
    /*
  const onRemoveDepartment = async () => {
    const response = await deleteDepartment(jobIdToDelete);
    if (response.success) {
      toast.success(response.data.message);
      setIsDeleteModalOpen(false);
      getNewJobs();
    } else {
      toast.error(response.data.message);
    }
  }; */

    const onClickCloneJob = (jobId) => {
        setJobToCloneId(jobId);
        setIsCloneJobModalOpen(true);
    };

    return (
        <section>
            <Toaster />
            <DeleteModal
                isModalOpen={isDeleteModalOpen}
                title={"Are you sure to delete this job?"}
                setIsModalOpen={setIsDeleteModalOpen}
                onRemove={onRemoveJob}
            />
            <FullSizeModal
                cancelButtonRef={cancelButtonRef}
                isModalOpen={isAddJobModalOpen}
                setIsModalOpen={setIsAddJobModalOpen}
            >
                <CreateJob
                    setIsModalOpen={setIsAddJobModalOpen}
                    getNewJobs={getNewJobs}
                />
            </FullSizeModal>
            <GenericModal
                cancelButtonRef={cancelButtonRef}
                isModalOpen={isCloneJobModalOpen}
                setIsModalOpen={setIsCloneJobModalOpen}
                title="What is the name of the cloned job template?"
            >
                <CloneJobForm
                    label=""
                    onAction={onCloneJob}
                    setIsModalOpen={setIsCloneJobModalOpen}
                    cancelButtonRef={cancelButtonRef}
                    onActionText={"Clone"}
                />
            </GenericModal>
            {/*  <DeleteModal
        isModalOpen={isCloneJobModalOpen}
        title="Are you sure you want to clone this job?"
        setIsModalOpen={setIsCloneJobModalOpen}
        onRemove={onCloneJob}
        buttonText="Clone"
      /> */}
            {/* <DeleteModal isModalOpen={isDeleteModalOpen} setIsModalOpen={setIsDeleteModalOpen} onRemove={onRemoveDepartment}/> */}
            <div className="flex h-screen overflow-hidden">
                {/* Sidebar */}
                <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                {/* Content area */}
                <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                    {/*  Site header */}
                    <Header
                        sidebarOpen={sidebarOpen}
                        setSidebarOpen={setSidebarOpen}
                    />

                    {!loading ? (
                        <main>
                            <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                                {/* Page header */}
                                <div className="sm:flex sm:justify-between sm:items-center mb-8">
                                    {/* Left: Title */}
                                    <div className="mb-4 sm:mb-0">
                                        <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">
                                            Jobs
                                        </h1>
                                    </div>

                                    {/* Right: Actions */}
                                    <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end items-end gap-2">
                                        {/* Add service button */}
                                        {loggedInEmployee.role == 1 && (
                                            <button
                                                onClick={() => onClickAddJob()}
                                                className="btn bg-teal-500 hover:bg-teal-600 text-white h-10"
                                            >
                                                <svg
                                                    className="w-4 h-4 fill-current opacity-50 shrink-0"
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                                                </svg>
                                                <span className="xs:block ml-2">
                                                    Add Job
                                                </span>
                                            </button>
                                        )}

                                        {/* Clone button */}
                                        {/* <button onClick={() => clone()} className="btn bg-teal-500 hover:bg-teal-600 text-white h-10">
                      <svg className="w-4 h-4 fill-current opacity-50 shrink-0" viewBox="0 0 16 16">
                        <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                      </svg>
                      <span className="xs:block ml-2">Clone Service Template</span>
                    </button> */}
                                    </div>
                                </div>

                                <FirmFlowTable
                                    columns={[
                                        {
                                            Header: "Name",
                                            accessor: "name",
                                            Cell: (props) => (
                                                <a
                                                    href={`/templates/job/${props.row.original.id}`}
                                                    className="text-gray hover:text-blue-500 rounded-ful"
                                                >
                                                    {props.value}
                                                </a>
                                            ),
                                        },
                                        {
                                            Header: "Actions",
                                            accessor: "id",
                                            Cell: (props) => (
                                                <>
                                                    {loggedInEmployee.role == 1 ? (
                                                        <>
                                                            <button
                                                                data-tip="Edit"
                                                                onClick={() =>
                                                                    navigate(
                                                                        `/templates/job/${props.value}`
                                                                    )
                                                                }
                                                                className="btn bg-teal-500 hover:bg-teal-600 text-white h-10 mx-2 w-20"
                                                            >
                                                                Edit
                                                            </button>
                                                            <button
                                                                data-tip="Clone"
                                                                onClick={() =>
                                                                    onClickCloneJob(
                                                                        props.value
                                                                    )
                                                                }
                                                                className="btn bg-yellow-500 hover:bg-yellow-600 text-white h-10 mx-2 w-20"
                                                            >
                                                                Clone
                                                            </button>
                                                            <button
                                                                data-tip="Delete"
                                                                onClick={() =>
                                                                    onClickRemoveJob(
                                                                        props.value
                                                                    )
                                                                }
                                                                className="btn bg-red-500 hover:bg-red-600 text-white h-10 mx-2 w-20"
                                                            >
                                                                Delete
                                                            </button>
                                                        </>
                                                    ) : (
                                                        <p>-</p>
                                                    )}
                                                </>
                                            ),
                                        },
                                    ]}
                                    data={jobs}
                                />
                            </div>
                        </main>
                    ) : (
                        <div className="w-full mt-64 flex justify-center items-center max-w-9xl">
                            <motion.div
                                className="border border-t-gray-700 border-t-[4px] rounded-full border-gray-200 w-12 h-12"
                                initial={{ rotateZ: 0 }}
                                animate={{ rotateZ: 360 }}
                                exit={{ rotateZ: 0 }}
                                transition={{
                                    repeat: Infinity,
                                    ease: "linear",
                                    duration: 1,
                                }}
                            ></motion.div>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
}
export default JobsList;
