import axios from "axios"
import Cookies from "js-cookie"
import { FIRMFLOWURL } from "./url";
export const getResetPassword = (email) => {
    return new Promise(async resolve => {
        try {
            const token = Cookies.get('Bearer');

            const response = await axios.get(`${FIRMFLOWURL}api/User/ResetPassword/${email}`,
                {
                    headers: { 'Authorization': token }
                }
            )
            resolve({ ...response });
        } catch (err) {
            console.log(err)
            if (err.message.includes(401)) {
                Cookies.remove('Bearer');
                window.location.replace('/');
                return;
            }
            resolve({ success: false, message: err.message });
        }
    })
}

export const resetUserPassword = async (data) => {
  return new Promise(async (resolve) => {
    await axios
      .post(
        `${FIRMFLOWURL}api/User/ResetPassword`,
        {
          ...data,
        }
      )
      .then((response) => {
        resolve({ success: true, data: response.data });
      })
      .catch((err) => {
        console.log(err);

        resolve({ success: false, message: err.response.data.message });
      });
  });
};
