import { useState } from "react";
import toast from "react-hot-toast";
import EditNotes from "./EditNotes";
import ViewNotes from "./ViewNotes";
import { saveSubscriptionNotes } from "../../../services/saveSubscriptionNotes";

const ClientServiceNotes = ({ clientService, setClientService }) => {
  const [editNotes, setEditNotes] = useState(false);

  async function saveNotes(newNotes) {
    const response = await saveSubscriptionNotes(clientService.id, newNotes);
    toast.success(response.data.message);
    setClientService({
      ...clientService,
      notes: newNotes,
    });
    //setNotes(newNotes);
  }

  return (
    <div className="w-full">
      {editNotes ? (
        <EditNotes
          label="Permanent notes"
          notes={clientService.notes}
          saveNotes={saveNotes}
          setEditNotes={setEditNotes}
        />
      ) : (
        <ViewNotes
          label="Permanent notes"
          notes={clientService.notes}
          setEditNotes={setEditNotes}
        />
      )}
    </div>
  );
};

export default ClientServiceNotes;
