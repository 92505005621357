import { PlusIcon } from "@heroicons/react/24/solid";
import React, { useState } from "react";
import { Toaster, toast } from "react-hot-toast";
import { createJob } from "../../services/jobs/createJob";
import CreateJobsForServiceSection from "../TaskForm/CreateJobsForServiceSection";

function CreateJob({setIsModalOpen, getNewJobs}) {
  const [service, setService] = useState({
    name: "",
  });
  const [jobs, setJobs] = useState([]);

  const IntialStates = () => {
    setService({
      name: "",
    });
    setJobs([]);
  };

  const addNewJob = () => {
    setJobs([
      ...jobs,
      {
        id: jobs.length,
        name: "",
        editing: true,
        checklistItems: [{ id: 0, name: "", editing: true }],
      },
    ]);
  };

  const saveDetails = async () => {
    if (service.name == "") {
      toast.error("Service name can't be empty");
      return;
    }

    if (jobs.length == 0 || (jobs.length == 1 && jobs[0].editing == true)) {
      toast.error("You need to add at least one job.");
      return;
    }
    const newJobs = jobs
      .filter((job) => job.editing == false)
      .map((job) => {
        return {
          name: job.name,
          order: job.id,
          checklistItems: job.checklistItems,
        };
      });

    const servicesWithJobs = {
      name: service.name,
      tasks: newJobs,
    };

    const response = await createJob(servicesWithJobs);
    if (response.data.success) {
      toast.success(response.data.message);
      IntialStates();
      setIsModalOpen(false);
      getNewJobs();
    } else {
      toast.error(response.data.message);
    }
    console.log(servicesWithJobs);
  };

  return (
    <section>
      <Toaster />

      <main className="w-full justify-center overflow-auto">
        <div className="px-4 sm:px-6 lg:px-8 py-8 ">
          <div className="relative flex-col items-center">
            {/* Page header */}
            <div className="flex justify-center">
              {/* Left: Title */}
              <div className="mb-4 sm:mb-0">
                <h3 className=" text-md text-slate-800 font-bold">New Job</h3>
              </div>
            </div>

            <div className="bg-white shadow-lg rounded-sm border border-slate-200 w-full">
              <header className="px-5 py-4 border-b border-slate-200">
                <h2 className="font-semibold text-slate-800">Details</h2>
              </header>

              <div className="space-y-5 px-5 py-4">
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Job Template Name
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      onChange={(e) =>
                        setService({ ...service, name: e.target.value })
                      }
                      value={service.name}
                      className="shadow-sm border border-slate-200 rounded-sm p-1 px-2 w-full focus:ring-0 focus:outline-none"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-white shadow-lg rounded-sm border border-slate-200 relative mt-12">
              <header className="flex justify-between items-center px-5 py-4 border-b border-slate-200">
                <h2 className="font-semibold text-slate-800">
                  Jobs{" "}
                  <span className="font-light text-sm text-slate-700">
                    click & drag to re order
                  </span>
                </h2>
                <button
                  id="add-btn"
                  onClick={() => {
                    addNewJob();
                  }}
                  type="button"
                  className="inline-flex items-center p-2 border border-transparent rounded-sm shadow-sm text-white bg-teal-500 hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
                >
                  <PlusIcon className="w-5 h-5" />
                </button>
              </header>

              <CreateJobsForServiceSection jobs={jobs} setJobs={setJobs} />
            </div>
            <div className="w-full flex justify-end">
              <button
                onClick={() => saveDetails()}
                className="z-50 mt-4 ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-sm text-white bg-teal-500 hover:bg-teal-600 focus:outline-none focus:ring-0"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </main>
    </section>
  );
}
export default CreateJob;
