import axios from "axios"
import Cookies from "js-cookie"
import { FIRMFLOWURL } from "./url";
export const createCustomChecklistItem = (form) => {
    return new Promise(async resolve => {
        try {
            const token = Cookies.get('Bearer');
            const response = await axios.post(`${FIRMFLOWURL}api/ClientSubscriptionCustomization/${form.subscription_id}/checklist`,
                {
                    description: form.description,
                    subscriptionTaskId: form.task_id,
                },
                {
                    headers: { 'Authorization': token }
                }
            )
            resolve({ ...response, success: true });
        } catch (err) {
            console.log(err)
            if (err.message.includes(401)) {
                Cookies.remove('Bearer');
                window.location.replace('/');
                return;
            }
            resolve({ success: false, message: err.response.data.message });
        }
    })
}