import { Toaster, toast } from "react-hot-toast";
import { useState } from "react";
import Breadcrumbs from "../../components/Breadcrumbs";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import { createDepartment } from "../../services/departments/createDepartment";

const CreateDepartmentForm = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [department, setDepartment] = useState({ name: "" });

  const onHandleSubmitForm = async () => {
    if (department.name == "") {
      toast.error("Name can't be blank");
      return;
    }
    const response = await createDepartment(department);
    if (response.success) {
      toast.success(response.data.message);
      setDepartment({ name: "" });
    } else {
      toast.error(response.message);
    }
    return;
  };
  return (
    <section>
      <Toaster />
      <div className="flex h-screen overflow-hidden">
        {/* Sidebar */}
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        {/* Content area */}
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          {/*  Site header */}
          <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

          <main>
            <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
              <div className="flex flex-col items-start max-w-2xl">
                <Breadcrumbs
                  home="/departments"
                  pages={[
                    {
                      name: "Deparment",
                      href: `/departments/create`,
                      current: true,
                    },
                  ]}
                />

                {/* Page header */}
                <div className="sm:flex sm:justify-between sm:items-center mb-8 w-full mt-12">
                  {/* Left: Title */}
                  <div className="mb-4 sm:mb-0">
                    <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">
                      New Department
                    </h1>
                  </div>
                </div>

                <div className="bg-white shadow-lg rounded-sm border border-slate-200 relative w-full">
                  <header className="px-5 py-4 border-b border-slate-200">
                    <h2 className="font-semibold text-slate-800">Details</h2>
                  </header>

                  <div className="space-y-5 px-5 py-4">
                    <div>
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Name
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          onChange={(e) =>
                            setDepartment({
                              ...department,
                              name: e.target.value,
                            })
                          }
                          value={department.name}
                          className="shadow-sm border border-slate-200 rounded-sm p-1 px-2 w-full max-w-sm focus:ring-0 focus:outline-none"
                        />
                      </div>
                    </div>

                    {/*  <ServiceStatusesListBox
                      label="Service status *"
                      serviceStatuses={serviceStatuses}
                      selectedServiceStatus={service.serviceState}
                      onChange={onHandleChangeStatus}
                    /> */}
                  </div>
                </div>

                <div className="w-full flex justify-end">
                  <button
                    onClick={() => onHandleSubmitForm()}
                    className="z-50 mt-4 ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-sm text-white bg-teal-500 hover:bg-teal-600 focus:outline-none focus:ring-0"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </section>
  );
};
export default CreateDepartmentForm;
