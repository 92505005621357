export const getDueDateColor = (dueDateRange) => {
  let color = "";
  switch (dueDateRange) {
    case "pastDue":
      color =
        "linear-gradient(90deg, rgba(239,68,68,1) 0%, rgba(220,38,38,1) 100%)";
      break;
    case "today":
      color =
        "linear-gradient(90deg, rgba(217,119,6,1) 0%, rgba(245,158,11,1) 100%)";
      break;
    case "tomorrow":
      color =
        "linear-gradient(90deg, rgba(217,119,6,1) 0%, rgba(245,158,11,1) 100%)";
      break;
    case "nextWeek":
      color =
        "linear-gradient(90deg, rgba(13,148,136,1) 0%, rgba(20,184,166,1) 100%)";
      break;
    default:
      color =
        "linear-gradient(90deg, rgba(13,148,136,1) 0%, rgba(20,184,166,1) 100%)";
      break;
  }
  return color;
};
