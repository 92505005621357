/* This example requires Tailwind CSS v2.0+ */
import { Dialog } from "@headlessui/react";
import { AnimatePresence, motion } from "framer-motion";
import { useCallback, useEffect, useRef, useState } from "react";
import ButtonModal from "../../components/clientServiceSubscriptionTask/updateClientServiceSubscriptionTaskModal/ButtonModal";
import ErrorMessage from "../../components/clientServiceSubscriptionTask/updateClientServiceSubscriptionTaskModal/ErrorMessage";
import InputTextAreaModal from "../../components/clientServiceSubscriptionTask/updateClientServiceSubscriptionTaskModal/InputTextAreaModal";
import { getTimestamp } from "../../helpers/getTimestamp";
import { updateClientServiceSubscriptionTaskNotes } from "../../services/clientServiceSubscriptionTask/updateClientServiceSubscriptionTaskNotes";
import { getLoggedInEmployee } from "../../services/getLoggedInEmployee";
import ButtonAddTimestamp from "../../utils/ButtonAddTimestamp";
import toast from "react-hot-toast";

const UpdateNotesInAccountabilityReportModal = ({ open, setOpen, notes, fetchAccountability }) => {
  const [loggedInEmployee, setLoggedInEmployee] = useState({});
  const [errors, setErrors] = useState([]);
  const [form, setForm] = useState({
    jobId: null,
    notes: null,
  });

  let completeButtonRef = useRef(null);

  const getNewLoggedInEmployee = useCallback(async () => {
    const currentEmployee = await getLoggedInEmployee();
    if (currentEmployee.success) {
      setLoggedInEmployee(currentEmployee.data.employeeResponse);
      console.log(currentEmployee);
    }
  }, []);

  const onHandleClickAddTimestamp = (fieldName) => {
    setForm({
      ...form,
      [fieldName]:
        (form[fieldName] !== null ? form[fieldName] : "") +
        getTimestamp(form.notes, loggedInEmployee),
    });
  };

  const onHandleChangeNotes = (e) => {
    console.log(e.target.value);
    setForm({ ...form, notes: e.target.value });
  };

  const onHandleCancel = () => {
    setErrors([]);
    setOpen(false);
  };

  const validateForm = (form) => {
    if (form.notes == null) {
      setErrors({ notes: "This field cannot be empty." });
      return false;
    }
    return true;
  };

  const onHandleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm(form)) {
      console.log(errors);
      return;
    }

    const response = await updateClientServiceSubscriptionTaskNotes(
      form.jobId,
      form.notes
    );
    console.log(response);
    if (response.success) {
      toast.success(response.data.message);
      fetchAccountability()
    } else {
      toast.error(response.data.message);
    }
    setOpen(false);
  };

  useEffect(() => {
    getNewLoggedInEmployee();
  }, []);

  useEffect(() => {
    setForm({
      jobId: notes.jobId,
      notes: notes.notes,
    });
  }, [notes]);

  return (
    <AnimatePresence>
      {open && (
        <Dialog
          initialFocus={completeButtonRef}
          static
          as={motion.div}
          open={open}
          onClose={() => setOpen(false)}
          className="relative z-50"
        >
          {/* The backdrop, rendered as a fixed sibling to the panel container */}
          <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

          {/* Full-screen scrollable container */}
          <div className="fixed inset-0 w-screen overflow-y-auto">
            {/* Container to center the panel */}
            <div className="flex min-h-full items-center justify-center p-4">
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg w-full sm:p-6">
                <Dialog.Title className="text-center w-full text-lg leading-6 font-medium text-gray-900">
                  Update Notes
                </Dialog.Title>

                <form onSubmit={(e) => onHandleSubmit(e)}>
                  <InputTextAreaModal
                    label="Notes"
                    name="notes"
                    onChange={(e) => onHandleChangeNotes(e)}
                    value={form.notes}
                  />
                  {<ErrorMessage error={errors["notes"]} />}
                  <ButtonAddTimestamp
                    onHandleClick={() => onHandleClickAddTimestamp("notes")}
                  />

                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                    <ButtonModal
                      name="Save"
                      type="submit"
                      className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-teal-500 text-base font-medium text-white hover:bg-teal-600 focus:outline-none focus:ring-0 sm:col-start-2 sm:text-sm"
                    />
                    <ButtonModal
                      name="Cancel"
                      type="button"
                      onClick={onHandleCancel}
                      className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-0 sm:mt-0 sm:col-start-1 sm:text-sm"
                    />
                  </div>
                </form>
              </Dialog.Panel>
            </div>
          </div>
        </Dialog>
      )}
    </AnimatePresence>
  );
};
export default UpdateNotesInAccountabilityReportModal;
