import React, { useState, useEffect } from "react";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import {
  getFirmStatistics,
  getRegisteredClients,
} from "../../services/analytics";
import DashboardCard from "../../components/dashboard/DashboardCard";
import { motion } from "framer-motion";
import { BarChart } from "../../components/dashboard/BarChart";
import { TimelineChart } from "../../components/dashboard/TimelineChart";
import { FIRMFLOW_NAMES } from "../../helpers/firmFlowNames";

function Dashboard() {
  //   const [barChartSettings, setBarChartSettings] = useState({});
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [statistics, setStatistics] = useState(null);
  const [loading, setLoading] = useState(true);
  const [
    subscriptionsByServiceChartSettings,
    setSubscriptionsByServiceChartSettings,
  ] = useState({
    options: {
      chart: {
        id: "barchart",
        color: "white",
        type: "bar",
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      colors: ["#134e4a"],
      xaxis: {
        categories: [],
        labels: {
          style: {
            colors: "#333",
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: "#333",
          },
        },
      },
      responsive: [
        {
          breakpoint: 800,
          options: {
            plotOptions: {
              bar: {
                horizontal: false,
              },
            },
            legend: {
              position: "bottom",
            },
            chart: {
              width: 400,
            },
          },
        },
      ],
    },
    series: [],
  });
  const [registeredClientsChartSettings, setRegisteredClientsChartSettings] =
    useState({
      options: {
        chart: {
          id: "timeline-chart",
          color: "white",
          type: "area",
          height: 300,
          foreColor: "#999",
          stacked: true,
          dropShadow: {
            enabled: true,
            enabledSeries: [0],
            top: -2,
            left: 2,
            blur: 5,
            opacity: 0.06,
          },
        },
        dataLabels: {
          enabled: false,
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        colors: ["#134e4a"],
        xaxis: {
          type: "datetime",
          // categories: props.categories,
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          labels: {
            offsetX: 14,
            offsetY: -5,
          },
          tooltip: {
            enabled: true,
          },
        },
        tooltip: {
          x: {
            format: "dd MMM yyyy",
          },
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
        },
        responsive: [
          {
            breakpoint: 800,
            options: {
              plotOptions: {
                bar: {
                  horizontal: false,
                },
              },
              legend: {
                position: "bottom",
              },
              chart: {
                width: 400,
              },
            },
          },
        ],
      },
      series: [],
    });

  //Set initial list
  useEffect(() => {
    fetchStatistics();
    fetchRegisteredClients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  async function fetchStatistics() {
    let response = await getFirmStatistics();
    //   console.log(response.data);
    setStatistics(response.data);
    let labels = [];
    let series = [];
    response.data.subscriptionsByServiceCounts.map((item) => {
      labels.push(item.serviceName);
      series.push(item.totalSubscriptions);
      setSubscriptionsByServiceChartSettings((prevState) => ({
        options: {
          ...prevState.options,
          xaxis: { ...prevState.options.xaxis, categories: labels },
        },
        series: [{ name: "Subscriptions", data: series }],
      }));
    });
    setLoading(false);
  }
  async function fetchRegisteredClients() {
    let response = await getRegisteredClients();
    let series = [];
    response.data.registeredClients.forEach((item) => {
      series.push([item.date, item.total]);
    });
    setRegisteredClientsChartSettings((prevState) => ({
        options: {
          ...prevState.options,
        },
        series: [{ name: "Client Registrations", data: series }],
      }));
    setLoading(false);
  }
  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        {!loading ? (
          <main>
            <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
              {/* Page header */}
              <div className="sm:flex sm:justify-between sm:items-center mb-8">
                {/* Left: Title */}
                <div className="mb-4 sm:mb-0">
                  <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">
                    {statistics?.firmName} Overview
                  </h1>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-12 gap-4">
              <DashboardCard
                title={"Clients"}
                subTitle={"Current clients"}
                total={statistics?.totalClients}
                backgroundColor={"bg-blue-500"}
                titleColor={"text-white"}
                subTitleColor={"text-white"}
                totalColor={"text-white"}
                showChart={false}
              />
              <DashboardCard
                title={"Service Templates"}
                subTitle={"All service Templates"}
                total={statistics?.totalServiceTemplates}
                backgroundColor={"bg-teal-900"}
                titleColor={"text-white"}
                subTitleColor={"text-white"}
                totalColor={"text-white"}
                showChart={false}
              />
              <DashboardCard
                title={`Client ${FIRMFLOW_NAMES.CLIENT_SERVICE_SUBSCRIPTIONS}`}
                subTitle={`All Client ${FIRMFLOW_NAMES.CLIENT_SERVICE_SUBSCRIPTIONS}`}
                total={statistics?.totalSubscriptions}
                backgroundColor={"bg-yellow-500"}
                titleColor={"text-white"}
                subTitleColor={"text-white"}
                totalColor={"text-white"}
                showChart={false}
              />
            </div>
            <br />
            <div className="grid grid-cols-10 cols-gap-2">
              <DashboardCard
                title={"Client Services by Service Template"}
                subTitle={"Total services grouped by service templates"}
                total={statistics?.subscriptionsByServiceCounts.reduce(
                  (n, { totalSubscriptions }) => n + totalSubscriptions,
                  0
                )}
                backgroundColor={"bg-white-500"}
                titleColor={"text-darkgray-500"}
                subTitleColor={"text-darkgray-500"}
                totalColor={"text-darkgray-500"}
                showChart={true}
                chart={
                  <BarChart settings={subscriptionsByServiceChartSettings} />
                }
              />
              <div></div>
              <div></div>
              <DashboardCard
                title={"Client Registrations"}
                subTitle={""}
                total={statistics?.totalClients}
                backgroundColor={"bg-white-500"}
                titleColor={"text-darkgray-500"}
                subTitleColor={"text-darkgray-500"}
                totalColor={"text-darkgray-500"}
                showChart={true}
                chart={<TimelineChart settings={registeredClientsChartSettings} />}
              />
            </div>
          </main>
        ) : (
          <div className="w-full mt-64 flex justify-center items-center max-w-9xl">
            <motion.div
              className="border border-t-gray-700 border-t-[4px] rounded-full border-gray-200 border-[4px] w-12 h-12"
              initial={{ rotateZ: 0 }}
              animate={{ rotateZ: 360 }}
              exit={{ rotateZ: 0 }}
              transition={{ repeat: Infinity, ease: "linear", duration: 1 }}
            ></motion.div>
          </div>
        )}
      </div>
    </div>
  );
}
export default Dashboard;
