import React, { useState } from "react";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
// import DeleteButton from "../../components/actions/DeleteButton";
import { motion } from "framer-motion";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
//import { deleteService } from "../../services/deleteService";
import toast, { Toaster } from "react-hot-toast";
import FirmFlowTable from "../../components/mytasks/FirmFlowTable";
import { getServiceDepartments } from "../../services/service/getServiceDepartments";
import { deleteDepartment } from "../../services/departments/deleteDepartment";
import DeleteModal from "../../utils/DeleteModal";
import { getLoggedInEmployee } from "../../services/getLoggedInEmployee";

function ServiceDepartments() {
  const navigate = useNavigate();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  // const [selectedItems, setSelectedItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [serviceDepartments, setServiceDepartments] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [departmentIdToDelete, setDepartmentIdToDelete] = useState("");
  const [loggedInEmployee, setLoggedInEmployee] = useState({});

  //Set initial list
  useEffect(() => {
    getNewServiceDeparments();
    setLoading(false);
    getCurrentEmployee();
  }, []);

  const getCurrentEmployee = async () => {
    const employee = await getLoggedInEmployee();
    if (employee.success) {
      setLoggedInEmployee(employee.data.employeeResponse);
      console.log(employee.data.employeeResponse);
    }
  };

  const getNewServiceDeparments = async () => {
    const response = await getServiceDepartments();
    console.log(response);
    if (response.success) {
      setServiceDepartments(response.data.departments);
    } else {
      toast.error(response.data.message);
    }
  };

  const onHandleClickDeleteDeparment = (departmentId) => {
    setIsDeleteModalOpen(true);
    setDepartmentIdToDelete(departmentId);
  };

  const onRemoveDepartment = async () => {
    const response = await deleteDepartment(departmentIdToDelete);
    if (response.success) {
      toast.success(response.data.message);
      setIsDeleteModalOpen(false);
      getNewServiceDeparments();
    } else {
      toast.error(response.data.message);
    }
  };

  return (
    <section>
      <Toaster />
      <DeleteModal
        isModalOpen={isDeleteModalOpen}
        setIsModalOpen={setIsDeleteModalOpen}
        onRemove={onRemoveDepartment}
      />
      <div className="flex h-screen overflow-hidden">
        {/* Sidebar */}
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        {/* Content area */}
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          {/*  Site header */}
          <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

          {!loading ? (
            <main>
              <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                {/* Page header */}
                <div className="sm:flex sm:justify-between sm:items-center mb-8">
                  {/* Left: Title */}
                  <div className="mb-4 sm:mb-0">
                    <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">
                      Service Departments
                    </h1>
                  </div>

                  {/* Right: Actions */}
                  <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end items-end gap-2">
                    {/* Add service button */}
                    {loggedInEmployee.role == 1 && (
                      <button
                        onClick={() => navigate("/departments/create")}
                        className="btn bg-teal-500 hover:bg-teal-600 text-white h-10"
                      >
                        <svg
                          className="w-4 h-4 fill-current opacity-50 shrink-0"
                          viewBox="0 0 16 16"
                        >
                          <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                        </svg>
                        <span className="xs:block ml-2">Add Department</span>
                      </button>
                    )}

                    {/* Clone button */}
                    {/* <button onClick={() => clone()} className="btn bg-teal-500 hover:bg-teal-600 text-white h-10">
                      <svg className="w-4 h-4 fill-current opacity-50 shrink-0" viewBox="0 0 16 16">
                        <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                      </svg>
                      <span className="xs:block ml-2">Clone Service Template</span>
                    </button> */}
                  </div>
                </div>

                <FirmFlowTable
                  columns={[
                    {
                      Header: "Name",
                      accessor: "name",
                      Cell: (props) => (
                        <a
                          href={`/templates/servicesByDepartment/${props.value}`}
                          className="text-gray hover:text-blue-500 rounded-ful"
                        >
                          {props.value}
                        </a>
                      ),
                    },
                    {
                      Header: "Actions",
                      accessor: "id",
                      Cell: (props) => (
                        <>
                          {loggedInEmployee.role == 1 ? (
                            <>
                              <button
                                data-tip="Edit"
                                onClick={() =>
                                  navigate(`/departments/${props.value}`)
                                }
                                className="btn bg-teal-500 hover:bg-teal-600 text-white h-10 mx-2 w-20"
                              >
                                Edit
                              </button>

                              <button
                                data-tip="Delete"
                                onClick={() =>
                                  onHandleClickDeleteDeparment(props.value)
                                }
                                className="btn bg-red-500 hover:bg-red-600 text-white h-10 mx-2 w-20"
                              >
                                Delete
                              </button>
                            </>
                          ) : (
                            <p>-</p>
                          )}
                        </>
                      ),
                    },
                  ]}
                  data={serviceDepartments}
                />
              </div>
            </main>
          ) : (
            <div className="w-full mt-64 flex justify-center items-center max-w-9xl">
              <motion.div
                className="border border-t-gray-700 border-t-[4px] rounded-full border-gray-200 w-12 h-12"
                initial={{ rotateZ: 0 }}
                animate={{ rotateZ: 360 }}
                exit={{ rotateZ: 0 }}
                transition={{ repeat: Infinity, ease: "linear", duration: 1 }}
              ></motion.div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}
export default ServiceDepartments;
