import EditLinks from "./EditLinks";
import ViewLinks from "./ViewLinks";
import { useState } from "react";
import { saveSubscriptionLinks } from "../../../services/saveSubscriptionLinks";
import { validateJSONString } from "../../../helpers/validateJSONString";
import toast from "react-hot-toast";

const ClientServiceLinks = ({ clientService, setClientService }) => {
  const [editLinks, setEditLinks] = useState(false);

  async function saveLinks(newLinks) {
    const response = await saveSubscriptionLinks(clientService.id, newLinks);
    toast.success(response.data.message);
    console.log("links", newLinks);
    setClientService({ ...clientService, links: newLinks });
  }

  return (
    <div className="w-full">
      {editLinks ? (
        <EditLinks
          links={validateJSONString(clientService.links)}
          saveLinks={saveLinks}
          setEditLinks={setEditLinks}
        />
      ) : (
        <ViewLinks
          label="Permanent Links"
          links={validateJSONString(clientService.links)}
          setEditLinks={setEditLinks}
        />
      )}
    </div>
  );
};

export default ClientServiceLinks;
