import moment from "moment";
import { useEffect } from "react";
import { geJobsByStatuses } from "../../services/analytics/getJobsByStatuses";
import { useState } from "react";

const TaxReturnAgenda = ({ serviceId }) => {
  const [jobsByStatuses, setJobByStatuses] = useState([]);

  useEffect(() => {
    const getJobsByService = async () => {
      const response = await geJobsByStatuses(serviceId);
      if (response.success) {
        setJobByStatuses(response.data.jobsByServiceStatuses);
        console.log(response)
      }
    };
    getJobsByService();
    console.log(serviceId)
  }, [serviceId]);
  return (
    <div className="flex flex-row overflow-x-auto mb-12">
      {jobsByStatuses.map((status) => {
        return (
          <div className="w-full mx-4 p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-8  dark:border-gray-700 min-w-fit min-h-full">
            <div className="flex items-center justify-between mb-4">
              <h5 className="text-xl font-bold leading-none text-gray-900 dark:text-gray-800">
                {status.serviceStatusName}
              </h5>
              <p clpssName="text-sm font-medium text-blue-600 hover:underline dark:text-blue-500">
                Total: {status.jobs.length}
              </p>
            </div>
            <div className="flow-root">
              <ul
                className="divide-y divide-gray-200 dark:divide-gray-700"
                key={status.id}
              >
                {status.jobs.length > 0 ? (
                  status.jobs.map((job) => (
                    <li className="py-3 sm:py-4" key={job.id}>
                      <div className="flex items-center">
                        <div class="flex-shrink-0"></div>
                        <div className="flex-1 min-w-0 ms-4">
                          <p className="text-sm font-medium text-gray-900 truncate dark:text-gray-700">
                            Name: {job.name}
                          </p>
                          <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                            Assigned Date:{" "}
                            {moment(job.assignedDate).format("MM-DD-YYYY")}
                          </p>
                          <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                            Due Date: {moment(job.dueDate).format("MM-DD-YYYY")}
                          </p>
                        </div>
                        {/* <div className="block-flex items-center text-base font-semibold text-gray-900 dark:text-gray-700">
                            Due date {moment(job.dueDate).format("MM-DD-YYYY")}
                          </div> */}
                      </div>
                    </li>
                  ))
                ) : (
                  <li className="py-3 sm:py-4">
                    No jobs assigned to this status.
                  </li>
                )}
              </ul>
            </div>
          </div>
        );
      })}
    </div>
  );
};
export default TaxReturnAgenda;
