import axios from "axios";
import Cookies from "js-cookie";
import { FIRMFLOWURL } from "./url";
export const updateService = (form) => {
  return new Promise(async (resolve) => {
    try {
      const token = Cookies.get("Bearer");
      const firm = await axios.get(`${FIRMFLOWURL}api/Firm`, {
        headers: { Authorization: token },
      });

      const response = await axios.put(
        `${FIRMFLOWURL}api/Service/firm/${firm.data.id}/service/${form.id}`,
        {
          name: form.name,
          departmentId: form.departmentId,
          jobId: form.jobId,
          isEfileStatusActive: form.isEfileStatusActive,
          isEveryItemRenewable: form.isEveryItemRenewable,
          description: form.description,
          category: form.category,
        },
        {
          headers: { Authorization: token },
        }
      );
      resolve({ ...response });
    } catch (err) {
      console.log(err);
      if (err.message.includes(401)) {
        Cookies.remove("Bearer");
        window.location.replace("/");
        return;
      }
      resolve({ success: false, message: err.message });
    }
  });
};
