import InputTextModal from "../../components/clientServiceSubscriptionTask/modalGeneralComponents/InputTextModal";
import { useState } from "react";

const CloneJobForm = ({
  label,
  onActionText,
  cancelButtonRef,
  setIsModalOpen,
  onAction,
}) => {
  const [name, setName] = useState("");

  const onHandleChange = (e) => {
    setName(e.target.value);
  };

  return (
    <>
      <InputTextModal
        required={true}
        label={label}
        name="name"
        onChange={onHandleChange}
        value={name}
      />
      <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
        <button
          onClick={() => onAction(name)}
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-teal-500 text-base font-medium text-white hover:bg-teal-600 focus:outline-none focus:ring-0 sm:col-start-2 sm:text-sm"
        >
          {onActionText}
        </button>

        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-0 sm:mt-0 sm:col-start-1 sm:text-sm"
          onClick={() => setIsModalOpen(false)}
          ref={cancelButtonRef}
        >
          Cancel
        </button>
      </div>
    </>
  );
};

export default CloneJobForm;
