import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import { useEffect, useState } from "react";
import { getMyAssignmentsReport } from "../../services/analytics";
import { useNavigate } from "react-router-dom";

const MyAssignmentsReportPage = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [report, setReport] = useState({});

    const navigate = useNavigate();

    useEffect(() => {
        const getMyAssignments = async () => {
            const response = await getMyAssignmentsReport();
            console.log(response);
            setReport(response.data);
        };
        getMyAssignments();
    }, []);

    const onClickCard = (filterName) => {
        console.log(filterName);
        navigate(`/my-assignments/${filterName}`);
    };

    return (
        <section>
            <div className="flex h-screen overflow-hidden">
                {/* Sidebar */}
                <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                {/* Content area */}
                <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                    {/*  Site header */}
                    <Header
                        sidebarOpen={sidebarOpen}
                        setSidebarOpen={setSidebarOpen}
                    />

                    <main>
                        <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                            <div className="flex flex-col items-start max-w-2xl">
                                <h1 className="text-2xl md:text-3xl text-slate-800 font-bold p-4">
                                    My Assignments Report
                                </h1>
                            </div>

                            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mt-6">
                                <div
                                    className="bg-teal-100 shadow-lg rounded-lg p-6 flex flex-col items-center cursor-pointer"
                                    onClick={() => onClickCard("services")}
                                >
                                    <h2 className="text-xl font-semibold text-gray-800">
                                        TOTAL SERVICES
                                    </h2>
                                    <p className="mt-6 text-4xl font-bold text-slate-500 transition-transform transform-gpu duration-500 ease-in-out">
                                        {report.totalServices}
                                    </p>
                                    <h2 className="mt-6 text-xl font-semibold text-gray-800">
                                        NEW SERVICES
                                    </h2>
                                    <p className="mt-6 text-4xl font-bold text-green-500 transition-transform transform-gpu duration-500 ease-in-out">
                                        {report.newServices}
                                    </p>
                                </div>
                                <div
                                    className="bg-teal-100 shadow-lg rounded-lg p-6 flex flex-col items-center cursor-pointer"
                                    onClick={() => onClickCard("jobs")}
                                >
                                    <h2 className="text-xl font-semibold text-gray-800">
                                        TOTAL JOBS
                                    </h2>
                                    <p className="mt-6 text-4xl font-bold text-slate-500 transition-transform transform-gpu duration-500 ease-in-out">
                                        {report.totalJobs}
                                    </p>
                                    <h2 className="mt-6 text-xl font-semibold text-gray-800">
                                        NEW JOBS
                                    </h2>
                                    <p className="mt-6 text-4xl font-bold text-green-500 transition-transform transform-gpu duration-500 ease-in-out">
                                        {report.newJobs}
                                    </p>
                                </div>
                                <div
                                    className="bg-teal-100 shadow-lg rounded-lg p-6 flex flex-col items-center cursor-pointer"
                                    onClick={() => onClickCard("checklist-items")}
                                >
                                    <h2 className="text-xl font-semibold text-gray-800">
                                        TOTAL CHECKLIST ITEMS
                                    </h2>
                                    <p className="mt-6 text-4xl font-bold text-slate-500 transition-transform transform-gpu duration-500 ease-in-out">
                                        {report.totalChecklistItems}
                                    </p>
                                    <h2 className="mt-6 text-xl font-semibold text-gray-800">
                                        NEW CHECKLIST ITEMS
                                    </h2>
                                    <p className="mt-6 text-4xl font-bold text-green-500 transition-transform transform-gpu duration-500 ease-in-out">
                                        {report.newChecklistItems}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </section>
    );
};

export default MyAssignmentsReportPage;
