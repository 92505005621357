const ViewNotes = ({ label, notes, setEditNotes }) => {
  return (
    <div className="w-full mt-8 mb-8">
      <label htmlFor="notes" className="block text-sm font-medium text-gray-500">
        {label}
      </label>
      {notes ? (
        <ul className="max-w-md space-y-1 text-black-500 list-disc list-inside dark:text-black-400 mb-8">
          {notes.length > 0 && notes}
        </ul>
      ) : (
        <p className="block text-sm font-medium text-gray-500 my-4">
          The notes are empty.
        </p>
      )}

      <button
        onClick={() => setEditNotes(true)}
        className="btn bg-teal-500 hover:bg-teal-600 text-white h-10"
      >
        Edit
      </button>
    </div>
  );
};

export default ViewNotes;
