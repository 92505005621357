const ChecklistItemsList = ({ links }) => {
  return (
    <div className="flex items-center">
      <ul className="max-w-md space-y-1 text-black-500 list-disc list-inside dark:text-black-400 mb-4">
        {links.map((link) => (
          <li key={link.name}>
            <a
              target="_blank"
              className="font-normal text-blue-600 dark:text-blue-500 hover:underline"
              rel="noreferrer"
              href={link.url}
            >
              {link.name}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ChecklistItemsList;
