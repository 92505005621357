import { PlusIcon } from "@heroicons/react/24/solid";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { Toaster, toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import { createChecklistItem } from "../../services/createChecklistItem";
import { deleteChecklistItem } from "../../services/deleteChecklistItem";
import { GetChecklist } from "../../services/getChecklist";
import { getTask } from "../../services/getTask";
import { updateChecklistItem } from "../../services/updateChecklistItem";
import { updateTask } from "../../services/updateTask";
import ChecklistFormSection from "./ChecklistFormSection";

function EditTaskForm() {
  const params = useParams();

  const [task, setTask] = useState({ name: "" });
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [checklist, setCheckList] = useState([]);
  const [inputCheckList, setInputCheckList] = useState([]);
  const [loading, setLoading] = useState(true);
 /*  const [serviceStatuses, setServiceStatuses] = useState([]); */

  /* const getServiceStatuses = async () => {
    const response = await getStates(params.id);
    setServiceStatuses(response.data.serviceStatuses);
  }; */

  /* const onHandleChangeStatus = (newStatus) => {
    //status 2 is complete
    setTask({ ...task, serviceState: newStatus });
    console.log(newStatus);
  }; */

  useEffect(() => {
    async function runOnLoad() {
      const task = await getTask({
        id: params.id,
        task_id: params.task_id,
      });

      retrieveChecklist();
      setTask({
        name: task.data.name,
        serviceState: task.data.serviceStateItem,
      });
      setLoading(false);
    }
    runOnLoad();
    /* getServiceStatuses(); */
  }, []);

  const retrieveChecklist = async () => {
    const checklist = await GetChecklist(params.task_id);
    if (!checklist.success) alert(checklist.message);
    const arr = [];
    checklist.data.map((value) => {
      arr.push({
        id: value.id,
        title: value.description,
        links: value.links,
        isChecked: false,
        editing: false,
        updated: false,
      });
    });
    setCheckList(arr);
  };

  const removeChecklistItem = async (checklist_id) => {
    const res = await deleteChecklistItem(params.task_id, checklist_id);
    if (res.success) {
      retrieveChecklist();
    } else {
      alert(res.message);
    }
  };

  const saveDetails = async () => {
    const res = await updateTask({
      id: params.id,
      task_id: params.task_id,
      name: task.name,
      serviceStateId: task.serviceState.id,
    });
    console.log(res);
    if (res.data.success) {
      setTask({ name: task.name, serviceState: task.serviceState });
      toast.success(res.data.message);
      return;
    }
    toast.error(res.data.error);
  };

  return (
    <section>
      <Toaster />
      <div className="flex h-screen overflow-hidden">
        {/* Sidebar */}
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        {/* Content area */}
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          {/*  Site header */}
          <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

          {!loading ? (
            <main>
              <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                <div className="flex flex-col items-start max-w-2xl">
                  <Breadcrumbs
                    home="/services"
                    pages={[
                      {
                        name: "Service",
                        href: `/services/${params.id}`,
                        current: true,
                      },
                      {
                        name: "Task",
                        href: `/services/${params.id}/task/${params.task_id}`,
                        current: true,
                      },
                    ]}
                  />

                  {/* Page header */}
                  <div className="sm:flex sm:justify-between sm:items-center mb-8 w-full mt-12">
                    {/* Left: Title */}
                    <div className="mb-4 sm:mb-0">
                      <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">
                        Edit Jobs
                        <span className="text-slate-400 font-normal">
                          {" "}
                          {task.name}{" "}
                        </span>
                      </h1>
                    </div>
                  </div>

                  <div className="bg-white shadow-lg rounded-sm border border-slate-200 relative w-full">
                    <header className="px-5 py-4 border-b border-slate-200">
                      <h2 className="font-semibold text-slate-800">Details</h2>
                    </header>

                    <div className="space-y-5 px-5 py-4">
                      <div>
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Name
                        </label>
                        <div className="mt-1">
                          <input
                            type="text"
                            onChange={(e) =>
                              setTask({ ...task, name: e.target.value })
                            }
                            value={task.name}
                            className="shadow-sm border border-slate-200 rounded-sm p-1 px-2 w-full max-w-sm focus:ring-0 focus:outline-none"
                          />
                        </div>
                      </div>

                     {/*  <ServiceStatusesListBox
                        label="Service status *"
                        serviceStatuses={serviceStatuses}
                        selectedServiceStatus={task.serviceState}
                        onChange={onHandleChangeStatus}
                      /> */}
                    </div>
                  </div>

                  <div className="w-full flex justify-end">
                    <button
                      onClick={() => saveDetails()}
                      className="z-50 mt-4 ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-sm text-white bg-teal-500 hover:bg-teal-600 focus:outline-none focus:ring-0"
                    >
                      Save
                    </button>
                  </div>

                  <div className="bg-white shadow-lg rounded-sm border border-slate-200 relative mt-12 w-full">
                    <header className="flex w-full justify-between items-center px-5 py-4 border-b border-slate-200">
                      <h2 className="font-semibold text-slate-800">
                        Checklist{" "}
                        <span className="font-light text-sm text-slate-700">
                          click & drag to re order
                        </span>
                      </h2>
                      <button
                        id="add-btn"
                        onClick={() => {
                          if (inputCheckList.length === 0) {
                            setInputCheckList([
                              ...inputCheckList,
                              { title: "" },
                            ]);
                          }
                        }}
                        type="button"
                        className="inline-flex items-center p-1 border border-transparent rounded-sm shadow-sm text-white bg-teal-500 hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
                      >
                        <PlusIcon className="w-5 h-5" />
                      </button>
                    </header>

                    <ChecklistFormSection
                      taskId={params.task_id}
                      checklist={checklist}
                      inputCheckList={inputCheckList}
                      retrieveChecklist={retrieveChecklist}
                      setCheckList={setCheckList}
                      setInputCheckList={setInputCheckList}
                      updateChecklistItem={updateChecklistItem}
                      removeChecklistItem={removeChecklistItem}
                      createChecklistItem={createChecklistItem}
                    />
                  </div>
                </div>
              </div>
            </main>
          ) : (
            <div className="w-full mt-64 flex justify-center items-center max-w-9xl">
              <motion.div
                className="border border-t-gray-700 border-t-[4px] rounded-full border-gray-200 border-[4px] w-12 h-12"
                initial={{ rotateZ: 0 }}
                animate={{ rotateZ: 360 }}
                exit={{ rotateZ: 0 }}
                transition={{ repeat: Infinity, ease: "linear", duration: 1 }}
              ></motion.div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}
export default EditTaskForm;
