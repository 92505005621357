import axios from "axios"
import Cookies from "js-cookie"
import { FIRMFLOWURL } from "./url";
export const saveSubscriptionNotes = (sub_id, notes) => {

    console.log(notes);

    return new Promise(async resolve => {
        try {
            const token = Cookies.get('Bearer');
            const response = await axios.put(`${FIRMFLOWURL}api/ClientSubscription/${sub_id}/notes?notes=${notes}`, null,
                {
                    headers: { 'Authorization': token }
                }
            )
            resolve({ ...response, success: true });
        } catch (err) {
            console.log(err)
            if (err.message.includes(401)) {
                Cookies.remove('Bearer');
                window.location.replace('/');
                return;
            }
            resolve({ success: false, message: err.response.data.message });
        }
    })
}