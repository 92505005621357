import { PencilIcon } from "@heroicons/react/24/outline";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import { getNewLoggedInEmployeeFunction } from "../../helpers/getLoggedInEmployeeFunction";
import { getJob } from "../../services/jobs/getJob";
import { updateJob } from "../../services/jobs/updateJob";
import EditTasksFromJob from "./EditTasksFromJob";

function EditJob() {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const jobId = useParams().jobId;
    const navigate = useNavigate();
    /* const [job, setJob] = useState({
        name: "",
    }); */
    const [form, setForm] = useState({
        name: "",
    });
    const [loggedInEmployee, setLoggedInEmployee] = useState(null);
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        async function runOnLoad() {
            if (typeof jobId === "undefined") return;

            const job = await getJob(jobId);
            if (job.success === false) return navigate("/templates/jobs");
            console.log(job);
            setForm({
                name: job.data.job.name,
            });

            /* setJob({
                name: job.data.job.name,
            }); */

            setLoading(false);
        }
        runOnLoad();

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        getNewLoggedInEmployeeFunction(setLoggedInEmployee);
    }, []);

    const saveDetails = async () => {
        if (loggedInEmployee.role === 0) {
            toast.error("You don't have permission to do this");
            return;
        }

        if (form.name == "") {
            toast.error("Name can't be empty");
            return;
        }
        const response = await updateJob(jobId, form.name);
        console.log(response);
        if (response.success) {
            /* setJob({ name: form.name }); */
            toast.success(response.data.message);
        } else {
            toast.error(response.data.message);
        }
        setIsEditing(false);
        /*  const res = await updateService({
      id: serviceId,
      name: form.name,
      isEfileStatusActive: form.isEfileStatusActive,
      isEveryItemRenewable: form.isEveryItemRenewable,
      departmentId: selectedDepartment.id,
    });

    if (res.data.success) {
      setService({ name: form.name });
      toast.success(res.data.message);
    } else { c z jnj hhhhhhhhhhhhhhhhhbgvgbggggggggggg     /
      toast.error(res.data.message);
    } */
    };

    const onHandleChangeInput = (e) => {
        const { name, value } = e.target;
        setForm({ ...form, [name]: value });
    };

    const toggleEdit = () => {
        setIsEditing(!isEditing);
    };

    return (
        <section>
            <Toaster />
            <div className="flex h-screen overflow-hidden">
                {/* Sidebar */}
                <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                {/* Content area */}
                <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                    {/*  Site header */}
                    <Header
                        sidebarOpen={sidebarOpen}
                        setSidebarOpen={setSidebarOpen}
                    />

                    {!loading ? (
                        <main>
                            <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                                <div className="flex flex-col items-start max-w-2xl">
                                    <Breadcrumbs
                                        home="/templates/jobs"
                                        pages={[
                                            {
                                                name: "Job template setup",
                                                href: `/templates/jobs`,
                                                current: true,
                                            },
                                        ]}
                                    />

                                    {/* Page header */}
                                    {/* <div className="sm:flex sm:justify-between sm:items-center mb-8 w-full mt-12">

                                        <div className="mb-4 sm:mb-0">
                                            <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">
                                                Edit Job
                                                <span className="text-slate-400 font-normal">
                                                    {" "}
                                                    {job.name}{" "}
                                                </span>
                                            </h1>
                                        </div>
                                    </div> */}
                                    <h1 className="text-2xl md:text-3xl text-slate-800 font-bold p-4">
                                        Job template setup
                                        {/* <span className="text-slate-400 font-normal">
                                                    {" "}
                                                    {job.name}{" "}
                                                </span> */}
                                    </h1>
                                    <div className="bg-white shadow-lg rounded-sm border border-slate-200 relative w-full mt-4 pb-4">
                                        {/* <header className="px-5 py-4 border-slate-200">
                                             <h2 className="font-semibold text-slate-800">
                                                Details
                                            </h2>
                                        </header> */}

                                        <div className="space-y-5 px-5 py-4 w-full">
                                            <div className="font-medium flex justify-end">
                                                <button
                                                    onClick={() => toggleEdit()}
                                                    type="submit"
                                                    className="w-fit z-20 inline-flex border border-transparent shadow-sm text-sm font-medium rounded-sm outline-none"
                                                >
                                                    {isEditing ? (
                                                        <>Cancel</>
                                                    ) : (
                                                        <>
                                                            <PencilIcon className="h-4 w-4 mr-2" />
                                                            Edit Name
                                                        </>
                                                    )}
                                                </button>
                                            </div>

                                            <div>
                                                <label
                                                    htmlFor="name"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Name
                                                </label>
                                                <div className="mt-1">
                                                    <input
                                                        name="name"
                                                        disabled={!isEditing}
                                                        type="text"
                                                        value={form.name}
                                                        onChange={(e) =>
                                                            onHandleChangeInput(e)
                                                        }
                                                        className={`w-full shadow-sm border border-slate-300 rounded-sm p-2 ${
                                                            !isEditing &&
                                                            "focus:outline-none focus:ring-0 bg-gray-200"
                                                        }`}
                                                    />
                                                </div>
                                            </div>
                                            {isEditing && (
                                                <div className="w-full flex justify-start">
                                                    <button
                                                        onClick={() => saveDetails()}
                                                        type="submit"
                                                        className="z-20 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-sm text-white bg-teal-500 hover:bg-teal-600 focus:outline-none focus:ring-0"
                                                    >
                                                        Save
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <EditTasksFromJob title={"Jobs"} jobId={jobId} />
                                </div>
                            </div>
                        </main>
                    ) : (
                        <div className="w-full mt-64 flex justify-center items-center max-w-9xl">
                            <motion.div
                                className="border-t-gray-700 border-t-[4px] rounded-full border-gray-200 border-[4px] w-12 h-12"
                                initial={{ rotateZ: 0 }}
                                animate={{ rotateZ: 360 }}
                                exit={{ rotateZ: 0 }}
                                transition={{
                                    repeat: Infinity,
                                    ease: "linear",
                                    duration: 1,
                                }}
                            ></motion.div>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
}
export default EditJob;
