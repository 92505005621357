import { Menu, Transition } from "@headlessui/react";
import { AnimatePresence, motion } from "framer-motion";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useParams } from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs";
import Header from "../../components/Header";
import AddCustomChecklistItem from "../../components/Modals/AddCustomChecklistItem";
import AddCustomTask from "../../components/Modals/AddCustomTask";
import EditSubscriptionChecklistItem from "../../components/Modals/EditSubscriptionChecklistItem";
import InfoModal from "../../components/Modals/InfoModal";
import UpdateTaskModal from "../../components/Modals/UpdateTaskModal";
import Sidebar from "../../components/Sidebar";
import EditLinks from "../../components/clientServiceSubscription/links/EditLinks";
import ViewLinks from "../../components/clientServiceSubscription/links/ViewLinks";
import EditNotes from "../../components/clientServiceSubscription/notes/EditNotes";
import ViewNotes from "../../components/clientServiceSubscription/notes/ViewNotes";
import ChecklistItemsList from "../../components/clientServiceSubscription/tasks/checklistItems/ChecklistItemsList";
import { intervalTypeMap } from "../../helpers/intervalTypesMap";
import { updateAllTaskSubscriptionCheckListItems } from "../../services/UpdateAllTaskSubscriptionCheckListItems";
import { getClient } from "../../services/getClient";
import { getClientSubscription } from "../../services/getClientSubscription";
import { getEmployees } from "../../services/getEmployees";
import { getLoggedInEmployee } from "../../services/getLoggedInEmployee";
import { renewSubscriptionTask } from "../../services/renewSubscriptionTask";
import { saveSubscriptionLinks } from "../../services/saveSubscriptionLinks";
import { saveSubscriptionNotes } from "../../services/saveSubscriptionNotes";
import { updateClientsubscriptionChecklistItem } from "../../services/updateClientsubscriptionChecklistItem";
import { updateSubscriptionState } from "../../services/updateSubscriptionState";
import { updateSubscriptionTask } from "../../services/updateSubscriptionTask";
import { validateJSONString } from "../../helpers/validateJSONString";
import { FIRMFLOW_NAMES } from "../../helpers/firmFlowNames";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

function EditTaskForm() {
    const params = useParams();
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [tasks, setTasks] = useState([]);
    const [open, setOpen] = useState(false);
    const [updateTaskModal, setUpdateTaskModal] = useState(false);
    const [customChecklistModalOpen, setCustomChecklistModalOpen] = useState(false);
    const [customTaskModalOpen, setCustomTaskModalOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [infoModal, setInfoModal] = useState(open);
    const [employees, setEmployees] = useState([]);
    const [loggedInEmployee, setLoggedInEmployee] = useState(null);
    const [editNotes, setEditNotes] = useState(false);
    const [editLinks, setEditLinks] = useState(false);

    const [form, setForm] = useState({
        id: "",
        task_id: "",
        description: "",
        notes: "",
        links: [],
        assignedToEmployeeId: "",
        assignedByEmployeeId: "",
        assignedDate: new Date().toISOString(),
        completedDate: new Date().toISOString(),
        dueDate: new Date().toISOString(),
    });

    const status_map = ["In progress", "Blocked", "Complete"];
    const renewalState = ["None", "Renew", "Renewed"];

    const [updateTaskForm, setUpdateTaskForm] = useState({});
    const [subscription, setSubscription] = useState({
        notes: "",
        links: [],
        states: [],
    });

    const calculateNewRenewalDate = (date) => {
        const regex = new RegExp(`^${subscription.intervalType}$`);
        const daysToAdd = intervalTypeMap.filter((interval) =>
            regex.test(interval.name)
        )[0].days;
        const newDate = new Date(date);
        newDate.setDate(newDate.getDate() + daysToAdd);
        return newDate;
    };

    const updateAllCheckListItems = async (
        clientServiceSubscriptionTaskId,
        isChecked
    ) => {
        const response = await updateAllTaskSubscriptionCheckListItems(
            params.id,
            clientServiceSubscriptionTaskId,
            isChecked
        );
        if (!response.data.success) {
            toast.error("Error while updating the checklist");
            return;
        }
        const updatedTasks = tasks.map((task) => {
            if (
                task.clientServiceSubscriptionTask.id ===
                clientServiceSubscriptionTaskId
            ) {
                return {
                    ...task,
                    clientServiceSubscriptionTaskChecklistItems:
                        task.clientServiceSubscriptionTaskChecklistItems.map(
                            (checkListItem) => ({
                                ...checkListItem,
                                isChecked: isChecked,
                            })
                        ),
                };
            } else {
                return task;
            }
        });
        setTasks(updatedTasks);
        toast.success("Checklist updated successfully!");
        return;
    };

    async function tickChecklistItem(task_id, item) {
        const _tasks = [...tasks];
        for (var i = 0; i < _tasks.length; i++) {
            for (
                var j = 0;
                j < _tasks[i].clientServiceSubscriptionTaskChecklistItems.length;
                j++
            ) {
                if (
                    _tasks[i].clientServiceSubscriptionTaskChecklistItems[j].id ===
                    item.id
                ) {
                    console.log(
                        _tasks[i].clientServiceSubscriptionTaskChecklistItems[j]
                    );
                    _tasks[i].clientServiceSubscriptionTaskChecklistItems[
                        j
                    ].isChecked =
                        !_tasks[i].clientServiceSubscriptionTaskChecklistItems[j]
                            .isChecked;
                }
            }
        }
        setTasks(_tasks);

        let form = {
            id: item.id,
            clientServiceSubscriptionTaskId: task_id,
            description: item.description,
            isChecked: item.isChecked,
            notes: item.notes,
            links: validateJSONString(item.links),
            assignedByEmployeeId: item.assignedByEmployeeId,
            assignedToEmployeeId: item.assignedToEmployeeId,
            assignedEmployeesId: item.assignedEmployeesId, //Added recently
            completedByEmployeeId: item.completedByEmployeeId,
            assignedDate: item.assignedDate,
            dueDate: item.dueDate,
            completedDate: item.completedDate,
            updated: item.updated,
        };
        const res = await updateClientsubscriptionChecklistItem(params.id, form);
        console.log(res);
        if (!res.data.success) {
            alert("Error");
            return;
        }
        setInfoModal(true);
    }

    async function reInitTasks() {
        setLoading(true);
        const old_tasks = [...tasks];
        const sub = await getClientSubscription(params.client_id, params.id);
        console.log(sub);
        if (!sub.success) {
            setLoading(false);
            toast.error("Error fetching client subscriptions, please try later.");
            return alert("Error fetching client subscriptions.");
        }

        const _tasks = [];

        // eslint-disable-next-line
        sub.data.clientServiceSubscriptionTaskDetail.map((value, index) => {
            _tasks.push({
                ...value,
                isOpen:
                    typeof old_tasks[index] === "undefined"
                        ? false
                        : old_tasks[index].isOpen,
                clientServiceSubscriptionTaskChecklistItems:
                    value.clientServiceSubscriptionTaskChecklistItems.map(
                        (checkListItem) => ({
                            ...checkListItem,
                            links: validateJSONString(checkListItem.links),
                        })
                    ),
            });
        });

        if (_tasks.length > 0) _tasks[0].isOpen = true;
        console.log(_tasks);
        setTasks(_tasks);
        setLoading(false);
        toast.success("Tasks successfully updated!");
    }

    async function saveServiceItem() {
        console.log("save service item", form);

        let data = {
            id: form.id,
            clientServiceSubscriptionTaskId: form.task_id,
            description: form.description,
            isChecked: form.isChecked,
            notes: form.notes,
            links: JSON.stringify(form.links),
            assignedToEmployeeId:
                typeof form.assignedToEmployeeId == "undefined" ||
                form.assignedToEmployeeId === false
                    ? null
                    : form.assignedToEmployeeId,
            assignedByEmployeeId:
                typeof form.assignedByEmployeeId == "undefined" ||
                form.assignedByEmployeeId === false
                    ? null
                    : form.assignedByEmployeeId,
            assignedDate: form.assignedDate,
            completedDate: form.completedDate,
            dueDate: form.dueDate,
            updated: moment().toISOString(),
        };

        let count = 0;

        if (
            typeof data.assignedToEmployeeId == "undefined" ||
            data.assignedToEmployeeId === null ||
            data.assignedToEmployeeId === false
        ) {
            count += 1;
            delete data.assignedToEmployeeId;
        }

        if (
            typeof data.assignedByEmployeeId == "undefined" ||
            data.assignedByEmployeeId === null ||
            data.assignedByEmployeeId === false
        ) {
            count += 1;
            delete data.assignedByEmployeeId;
        }

        if (
            typeof data.assignedByEmployeeId == "undefined" ||
            typeof data.assignedToEmployeeId == "undefined" ||
            data.assignedByEmployeeId === false ||
            data.assignedToEmployeeId === false
        ) {
            delete data.assignedDate;
        }

        console.log(count);

        if (count === 1) {
            alert("Please select both: Assigned by and Assigned to");
            return;
        }

        const res = await updateClientsubscriptionChecklistItem(params.id, data);
        console.log(res);
        if (res.data.success) {
            reInitTasks();
            setOpen(false);
        }
    }

    function editService(service) {
        setForm({
            id: service.id,
            task_id: service.clientServiceSubscriptionTask.id,
            description: service.description,
            notes: service.notes,
            links: service.links,
            isChecked: service.isChecked,
            completedByEmployeeId:
                service.completedByEmployee != null &&
                service.completedByEmployee.id,
            dueDate: service.dueDate,
            completedDate: service.completedDate,
            assignedDate: service.assignedDate,
            assignedToEmployeeId:
                service.assignedToEmployee != null && service.assignedToEmployee.id,
            assignedByEmployeeId:
                service.assignedByEmployee != null && service.assignedByEmployee.id,
        });
        setOpen(true);
    }

    async function saveNotes(newNotes) {
        const response = await saveSubscriptionNotes(params.id, newNotes);
        toast.success(response.data.message);
        setSubscription({ ...subscription, notes: newNotes });
    }

    async function saveLinks(newLinks) {
        const response = await saveSubscriptionLinks(params.id, newLinks);
        toast.success(response.data.message);
        setSubscription({ ...subscription, links: validateJSONString(newLinks) });
    }

    async function updateTask() {
        console.log("updateTaskForm: ", updateTaskForm);
        let data = {
            assignedEmployeesId: Array.from(
                updateTaskForm.assignedEmployees,
                (employee) => employee.employeeId
            ),
            taskId: updateTaskForm.id,
            name: updateTaskForm.name,
            notes: updateTaskForm.notes,
            assignedToEmployeeId:
                typeof updateTaskForm.assignedToEmployee == "undefined" ||
                updateTaskForm.assignedToEmployee === false ||
                updateTaskForm.assignedToEmployee === null
                    ? null
                    : typeof updateTaskForm.assignedToEmployee.id != "undefined"
                    ? updateTaskForm.assignedToEmployee.id
                    : updateTaskForm.assignedToEmployee.employeeId,
            assignedByEmployeeId:
                typeof updateTaskForm.assignedByEmployee == "undefined" ||
                updateTaskForm.assignedByEmployee === false ||
                updateTaskForm.assignedByEmployee === null
                    ? null
                    : typeof updateTaskForm.assignedByEmployee.id != "undefined"
                    ? updateTaskForm.assignedByEmployee.id
                    : updateTaskForm.assignedByEmployee.employeeId,
            completedByEmployeeId:
                typeof updateTaskForm.completedBy == "undefined" ||
                updateTaskForm.completedBy === false ||
                updateTaskForm.completedBy === null
                    ? null
                    : typeof updateTaskForm.completedBy.id != "undefined"
                    ? updateTaskForm.completedBy.id
                    : updateTaskForm.completedBy.employeeId,
            assignedDate: updateTaskForm.assignedDate,
            completedDate: updateTaskForm.completedDate,
            dueDate: updateTaskForm.dueDate,
            updated: moment().toISOString(),
            status: updateTaskForm.status,
            taskRenewalState: updateTaskForm.setTaskRenewalState === true ? 1 : 0,
        };
        //Removing from data being sent if they are null
        if (data.assignedToEmployeeId == null) delete data.assignedToEmployeeId;
        if (data.assignedByEmployeeId == null) delete data.assignedByEmployeeId;
        if (data.completedByEmployeeId == null) delete data.completedByEmployeeId;

        console.log("subscription", subscription);
        const res = await updateSubscriptionTask(
            updateTaskForm.clientServiceSubscriptionId,
            data
        );
        console.log(res);

        if (res.success) {
            setUpdateTaskModal(false);

            reInitTasks();
        } else {
            alert(res.message);
        }
    }

    const isTaskValidToRenew = (clientServiceSubscriptionTaskId) => {
        const checklistItems = tasks.filter(
            (task) =>
                task.clientServiceSubscriptionTask.id ==
                clientServiceSubscriptionTaskId
        )[0].clientServiceSubscriptionTaskChecklistItems;
        const numberOfUncheckedItems = checklistItems.filter(
            (item) => item.isChecked === false
        ).length;
        if (numberOfUncheckedItems > 0) {
            return false;
        }
        return true;
    };

    async function renewTask(subscriptionId, taskId, assignedDate) {
        if (!isTaskValidToRenew(taskId)) {
            toast.error("Check all the checklist items before renewing.");
            return;
        }
        let payload = {
            subscriptionId: subscriptionId,
            taskId: taskId,
            assignedDate: calculateNewRenewalDate(assignedDate),
            dueDate: calculateNewRenewalDate(assignedDate),
        };
        const res = await renewSubscriptionTask(payload);
        if (res.success) {
            reInitTasks();
        } else {
            alert(res.message);
        }
    }

    const [client, setClient] = useState(null);

    useEffect(() => {
        async function runOnLoad() {
            const sub = await getClientSubscription(params.client_id, params.id);
            const client = await getClient(params.client_id);
            console.log("sub: ", sub);
            setClient(client.data);
            setSubscription({
                ...subscription,
                notes: sub.data.notes,
                links: validateJSONString(sub.data.links),
                states: sub.data.clientServiceSubscriptionStateItems,
                currentState: sub.data.currentState,
                serviceName: sub.data.serviceName,
                startDate: sub.data.startDate,
                endDate: sub.data.endDate,
                intervalType: sub.data.intervalType,
            });
            const employees = await getEmployees();
            if (employees.success) {
                setEmployees(employees.data.employees);
            }
            const employee = await getLoggedInEmployee();
            if (employee.success) {
                setLoggedInEmployee(employee.data.employeeResponse);
            }
            if (!sub.success) return alert("Error fetching client subscriptions.");

            const tasks = [];

            // eslint-disable-next-line
            sub.data.clientServiceSubscriptionTaskDetail.map((value) => {
                value.clientServiceSubscriptionTaskChecklistItems =
                    value.clientServiceSubscriptionTaskChecklistItems.map(
                        (task) => ({
                            ...task,
                            links: validateJSONString(task.links),
                        })
                    );
                tasks.push(value);
            });

            if (tasks.length > 0) tasks[0].isOpen = true;
            setTasks(tasks);
            setLoading(false);
        }
        runOnLoad();
        // eslint-disable-next-line
    }, []);

    /*useEffect(() => {
    if (completedByEmployee == null || loggedInEmployee == null) {
      return;
    }
    let completedDate = moment().format("YYYY-MM-DDTHH:mm:ss");
    // console.log(updateTaskForm);
    console.log({
      ...updateTaskForm,
      completedBy: completedByEmployee,
      completedDate: completedDate,
    });
    setUpdateTaskForm({
      ...updateTaskForm,
      completedBy: completedByEmployee,
      completedDate: completedDate,
    });
  }, [completedByEmployee]);*/
    return (
        <section>
            <Toaster />

            <EditSubscriptionChecklistItem
                open={open}
                setOpen={setOpen}
                form={form}
                setForm={setForm}
                saveServiceItem={saveServiceItem}
                employees={employees}
                setEmployees={setEmployees}
            />
            <UpdateTaskModal
                open={updateTaskModal}
                setOpen={setUpdateTaskModal}
                form={updateTaskForm}
                setForm={setUpdateTaskForm}
                updateTask={updateTask}
                employees={employees}
                setEmployees={setEmployees}
                loggedInEmployee={loggedInEmployee}
            />
            {/*<RenewTaskModal
        open={renewTaskModal}
        setOpen={setRenewTaskModal}
        form={renewTaskForm}
        setForm={setRenewTaskForm}
        renewTask={renewTask}
  />*/}
            <AddCustomChecklistItem
                open={customChecklistModalOpen}
                setOpen={setCustomChecklistModalOpen}
                form={form}
                setForm={setForm}
                reInit={reInitTasks}
            />
            <AddCustomTask
                open={customTaskModalOpen}
                setOpen={setCustomTaskModalOpen}
                reInit={reInitTasks}
                subscription_id={params.id}
            />

            <InfoModal infoModal={infoModal} setInfoModal={setInfoModal} />
            <div className="flex h-screen overflow-hidden">
                {/* Sidebar */}
                <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                {/* Content area */}
                <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                    {/*  Site header */}
                    <Header
                        sidebarOpen={sidebarOpen}
                        setSidebarOpen={setSidebarOpen}
                    />

                    <main>
                        <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                            <div className="flex flex-col items-start">
                                {!loading ? (
                                    <>
                                        <Breadcrumbs
                                            home={`/clients`}
                                            pages={[
                                                {
                                                    name: `${client?.clientName}`,
                                                    href: `/clients/manage/${params.client_id}`,
                                                },
                                                {
                                                    name: `${subscription?.serviceName}`,
                                                    href: ``,
                                                    current: true,
                                                },
                                            ]}
                                        />

                                        <h1 className="text-2xl md:text-3xl text-slate-800 font-bold mt-12">
                                            {FIRMFLOW_NAMES.SUBSCRIPTION}{" "}
                                            <span className="text-slate-400 font-normal">
                                                {subscription.serviceName}
                                            </span>{" "}
                                        </h1>
                                        <h2 className="text-xl text-slate-800 font-bold">
                                            Client{" "}
                                            <span className="text-slate-400 font-normal">
                                                {client.clientName}
                                            </span>
                                        </h2>
                                        <p className="text-md text-slate-800 font-bold">
                                            Assigned Date{" "}
                                            <span className="text-slate-400 font-normal">
                                                {new Date(
                                                    subscription.startDate
                                                ).toDateString()}
                                            </span>{" "}
                                            Due Date{" "}
                                            <span className="text-slate-400 font-normal">
                                                {new Date(
                                                    subscription.endDate
                                                ).toDateString()}
                                            </span>
                                        </p>
                                        <p className="text-md text-slate-800 font-bold">
                                            Interval:{" "}
                                            <span className="text-slate-400 font-normal">
                                                {subscription.intervalType}
                                            </span>{" "}
                                        </p>

                                        <div className="mt-8">
                                            <label
                                                htmlFor="email"
                                                className="block text-sm font-medium text-gray-500 mb-0.5"
                                            >
                                                Current Status
                                            </label>
                                            <Menu
                                                as="div"
                                                className="relative inline-block text-left w-48"
                                            >
                                                <div>
                                                    <Menu.Button className="inline-flex w-full truncate justify-between rounded-sm border border-gray-200 bg-white pl-3 pr-2 py-2 text-sm font-normal text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-0">
                                                        <span>
                                                            {subscription.currentState ===
                                                            ""
                                                                ? "Select"
                                                                : subscription.currentState}
                                                        </span>
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 24 24"
                                                            fill="currentColor"
                                                            className="w-4 h-4 mt-0.5"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z"
                                                                clipRule="evenodd"
                                                            />
                                                        </svg>
                                                    </Menu.Button>
                                                </div>

                                                <Transition
                                                    as={Fragment}
                                                    enter="transition ease-out duration-100"
                                                    enterFrom="transform opacity-0 scale-95"
                                                    enterTo="transform opacity-100 scale-100"
                                                    leave="transition ease-in duration-75"
                                                    leaveFrom="transform opacity-100 scale-100"
                                                    leaveTo="transform opacity-0 scale-95"
                                                >
                                                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                        <div className="py-1">
                                                            {subscription.states.map(
                                                                (state, i) => {
                                                                    return (
                                                                        <Menu.Item
                                                                            key={i}
                                                                        >
                                                                            {({
                                                                                active,
                                                                            }) => (
                                                                                <a
                                                                                    onClick={async () => {
                                                                                        setSubscription(
                                                                                            {
                                                                                                ...subscription,
                                                                                                currentState:
                                                                                                    state.state,
                                                                                            }
                                                                                        );
                                                                                        const res =
                                                                                            await updateSubscriptionState(
                                                                                                params.id,
                                                                                                {
                                                                                                    state: state.state,
                                                                                                }
                                                                                            );
                                                                                        alert(
                                                                                            res
                                                                                                .data
                                                                                                .message
                                                                                        );
                                                                                    }}
                                                                                    className={classNames(
                                                                                        active
                                                                                            ? "bg-gray-100 text-gray-900"
                                                                                            : "text-gray-700",
                                                                                        "block px-4 py-2 text-sm cursor-pointer"
                                                                                    )}
                                                                                >
                                                                                    {
                                                                                        state.state
                                                                                    }
                                                                                </a>
                                                                            )}
                                                                        </Menu.Item>
                                                                    );
                                                                }
                                                            )}
                                                        </div>
                                                    </Menu.Items>
                                                </Transition>
                                            </Menu>
                                        </div>

                                        {editNotes ? (
                                            <EditNotes
                                                notes={subscription.notes}
                                                saveNotes={saveNotes}
                                                setEditNotes={setEditNotes}
                                            />
                                        ) : (
                                            <ViewNotes
                                                notes={subscription.notes}
                                                setEditNotes={setEditNotes}
                                            />
                                        )}

                                        {editLinks ? (
                                            <EditLinks
                                                links={subscription.links}
                                                saveLinks={saveLinks}
                                                setEditLinks={setEditLinks}
                                            />
                                        ) : (
                                            <ViewLinks
                                                links={subscription.links}
                                                setEditLinks={setEditLinks}
                                            />
                                        )}

                                        {/* Page header */}
                                        <div className="sm:flex sm:justify-between sm:items-center mb-4 w-full mt-12">
                                            {/* Left: Title */}
                                            <div className="flex items-center justify-between w-full mb-4 sm:mb-0">
                                                <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">
                                                    {
                                                        FIRMFLOW_NAMES.CLIENT_SERVICE_SUBSCRIPTON_TASKS
                                                    }
                                                </h1>
                                                <button
                                                    onClick={() =>
                                                        setCustomTaskModalOpen(true)
                                                    }
                                                    className="btn bg-teal-500 hover:bg-teal-600 text-white h-10"
                                                >
                                                    <svg
                                                        className="w-4 h-4 fill-current opacity-50 shrink-0"
                                                        viewBox="0 0 16 16"
                                                    >
                                                        <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                                                    </svg>
                                                    <span className="xs:block ml-2">
                                                        Add Custom{" "}
                                                        {
                                                            FIRMFLOW_NAMES.CLIENT_SERVICE_SUBSCRIPTON_TASK
                                                        }
                                                    </span>
                                                </button>
                                            </div>
                                        </div>

                                        {tasks.length === 0 && (
                                            <h1 className="text-md font-normal -mt-4">
                                                This service does not have any tasks.
                                            </h1>
                                        )}

                                        {/*  <Paginator
                      totalPages={totalPages}
                      currentPage={page}
                      onChange={(newPage) => setPage(newPage)}
                    />
 */}
                                        <div className="flex flex-col space-y-8 w-full">
                                            {tasks.map((task, index) => {
                                                return (
                                                    <div
                                                        key={task + index}
                                                        className="bg-white shadow-lg rounded-sm border border-slate-200 relative w-full"
                                                    >
                                                        <motion.div
                                                            initial={{ height: 0 }}
                                                            animate={{
                                                                height: "auto",
                                                            }}
                                                            exit={{ height: 0 }}
                                                            transition={{
                                                                type: "linear",
                                                                height: {
                                                                    duration: 0.2,
                                                                },
                                                            }}
                                                            className={
                                                                "overflow-x-auto overflow-y-hidden"
                                                            }
                                                        >
                                                            <table className="table-auto w-full">
                                                                {/* Table header */}
                                                                <thead>
                                                                    <tr>
                                                                        <th className="first:pl-5 py-3 whitespace-nowrap">
                                                                            <div className="flex items-center space-x-3">
                                                                                <svg
                                                                                    onClick={() => {
                                                                                        const arr =
                                                                                            [
                                                                                                ...tasks,
                                                                                            ];
                                                                                        arr[
                                                                                            index
                                                                                        ].isOpen =
                                                                                            !arr[
                                                                                                index
                                                                                            ]
                                                                                                .isOpen;
                                                                                        setTasks(
                                                                                            arr
                                                                                        );
                                                                                    }}
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    className={
                                                                                        "h-5 w-5 cursor-pointer transition-all " +
                                                                                        (task.isOpen &&
                                                                                            "rotate-180")
                                                                                    }
                                                                                    viewBox="0 0 20 20"
                                                                                    fill="currentColor"
                                                                                >
                                                                                    <path
                                                                                        fillRule="evenodd"
                                                                                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                                                                        clipRule="evenodd"
                                                                                    />
                                                                                </svg>
                                                                                <h2 className="font-semibold text-slate-800">
                                                                                    {
                                                                                        task
                                                                                            .clientServiceSubscriptionTask
                                                                                            .name
                                                                                    }
                                                                                </h2>

                                                                                <span className="inline-flex items-center rounded-full bg-gray-200 px-2.5 py-0.5 text-xs font-medium text-gray-800">
                                                                                    {
                                                                                        status_map[
                                                                                            task
                                                                                                .clientServiceSubscriptionTask
                                                                                                .status
                                                                                        ]
                                                                                    }
                                                                                </span>
                                                                                {renewalState[
                                                                                    task
                                                                                        .clientServiceSubscriptionTask
                                                                                        .taskRenewalState
                                                                                ] ===
                                                                                    "Renew" && (
                                                                                    <button
                                                                                        onClick={() => {
                                                                                            /*console.log({
                                                ...task.clientServiceSubscriptionTask,
                                              });
                                              console.log(
                                                "subscription",
                                                subscription.intervalType
                                              );
                                              setRenewTaskForm({
                                                subscription_id: params.id,
                                                task_id:
                                                  task
                                                    .clientServiceSubscriptionTask
                                                    .id,
                                                assignedDate:
                                                  calculateNewRenewalDate(
                                                    task
                                                      .clientServiceSubscriptionTask
                                                      .assignedDate
                                                  ),
                                                dueDate:
                                                  calculateNewRenewalDate(
                                                    task
                                                      .clientServiceSubscriptionTask
                                                      .assignedDate
                                                  ),
                                                interval:
                                                  subscription.intervalType,
                                              });*/
                                                                                            renewTask(
                                                                                                params.id,
                                                                                                task
                                                                                                    .clientServiceSubscriptionTask
                                                                                                    .id,
                                                                                                task
                                                                                                    .clientServiceSubscriptionTask
                                                                                                    .assignedDate
                                                                                            );
                                                                                        }}
                                                                                        className="flex items-center space-x-1 mr-4 border border-gray-200 px-3 py-2 rounded-md"
                                                                                    >
                                                                                        {
                                                                                            renewalState[
                                                                                                task
                                                                                                    .clientServiceSubscriptionTask
                                                                                                    .taskRenewalState
                                                                                            ]
                                                                                        }
                                                                                    </button>
                                                                                )}
                                                                                <h2 className="font-semibold text-slate-800">
                                                                                    <span>
                                                                                        Notes:{" "}
                                                                                    </span>
                                                                                    {task
                                                                                        .clientServiceSubscriptionTask
                                                                                        .notes &&
                                                                                        task
                                                                                            .clientServiceSubscriptionTask
                                                                                            .notes}
                                                                                </h2>
                                                                            </div>
                                                                        </th>
                                                                        <th className="whitespace-nowrap"></th>
                                                                        <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap max-w-sm min-w-[240px]"></th>
                                                                        <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                                                            <div className="text-left font-medium text-sm">
                                                                                Assigned{" "}
                                                                                <span className="font-medium text-sky-500">
                                                                                    {new Date(
                                                                                        task.clientServiceSubscriptionTask.assignedDate
                                                                                    )
                                                                                        .toDateString()
                                                                                        .substring(
                                                                                            4
                                                                                        )}
                                                                                </span>
                                                                            </div>
                                                                            <div className="text-left font-medium text-sm">
                                                                                Completed{" "}
                                                                                <span className="font-medium text-sky-500">
                                                                                    {task
                                                                                        .clientServiceSubscriptionTask
                                                                                        .completedDate !==
                                                                                    "0001-01-01T00:00:00"
                                                                                        ? new Date(
                                                                                              task.clientServiceSubscriptionTask.completedDate
                                                                                          )
                                                                                              .toDateString()
                                                                                              .substring(
                                                                                                  4
                                                                                              )
                                                                                        : "Not assigned"}
                                                                                </span>
                                                                            </div>
                                                                            <div className="text-left font-medium text-sm">
                                                                                Due{" "}
                                                                                <span className="font-medium text-sky-500">
                                                                                    {new Date(
                                                                                        task.clientServiceSubscriptionTask.dueDate
                                                                                    )
                                                                                        .toDateString()
                                                                                        .substring(
                                                                                            4
                                                                                        )}
                                                                                </span>
                                                                            </div>
                                                                        </th>
                                                                        <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                                                            <div className="text-left font-medium text-sm">
                                                                                Assigned
                                                                                to{" "}
                                                                                <span className="font-medium text-sky-500">
                                                                                    {task
                                                                                        .clientServiceSubscriptionTask
                                                                                        .assignedEmployees &&
                                                                                        task.clientServiceSubscriptionTask.assignedEmployees
                                                                                            .map(
                                                                                                (
                                                                                                    employee
                                                                                                ) =>
                                                                                                    employee.firstName +
                                                                                                    " " +
                                                                                                    employee.lastName
                                                                                            )
                                                                                            .join(
                                                                                                ", "
                                                                                            )}
                                                                                    {/*task.clientServiceSubscriptionTask
                                            .assignedToEmployee != null &&
                                            task.clientServiceSubscriptionTask
                                              .assignedToEmployee.firstName +
                                              " " +
                                              task.clientServiceSubscriptionTask
                                          .assignedToEmployee.lastName*/}
                                                                                </span>
                                                                            </div>
                                                                            <div className="text-left font-medium text-sm">
                                                                                Assigned
                                                                                by{" "}
                                                                                <span className="font-medium text-sky-500">
                                                                                    {task
                                                                                        .clientServiceSubscriptionTask
                                                                                        .assignedByEmployee !=
                                                                                        null &&
                                                                                        task
                                                                                            .clientServiceSubscriptionTask
                                                                                            .assignedByEmployee
                                                                                            .firstName +
                                                                                            " " +
                                                                                            task
                                                                                                .clientServiceSubscriptionTask
                                                                                                .assignedByEmployee
                                                                                                .lastName}
                                                                                </span>
                                                                            </div>
                                                                            <div className="text-left font-medium text-sm">
                                                                                Completed
                                                                                by{" "}
                                                                                <span className="font-medium text-sky-500">
                                                                                    {task
                                                                                        .clientServiceSubscriptionTask
                                                                                        .completedBy !=
                                                                                        null &&
                                                                                        task
                                                                                            .clientServiceSubscriptionTask
                                                                                            .completedBy
                                                                                            .firstName +
                                                                                            " " +
                                                                                            task
                                                                                                .clientServiceSubscriptionTask
                                                                                                .completedBy
                                                                                                .lastName}
                                                                                </span>
                                                                            </div>
                                                                        </th>
                                                                        <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                                                            <div className="flex items-center justify-end">
                                                                                <button
                                                                                    onClick={() => {
                                                                                        console.log(
                                                                                            task
                                                                                        );
                                                                                        console.log(
                                                                                            {
                                                                                                ...task.clientServiceSubscriptionTask,
                                                                                            }
                                                                                        );
                                                                                        setUpdateTaskForm(
                                                                                            {
                                                                                                ...task.clientServiceSubscriptionTask,
                                                                                            }
                                                                                        );
                                                                                        setUpdateTaskModal(
                                                                                            true
                                                                                        );
                                                                                    }}
                                                                                    className="flex items-center space-x-1 mr-4 border border-gray-200 px-3 py-2 rounded-md"
                                                                                >
                                                                                    <svg
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                        viewBox="0 0 24 24"
                                                                                        fill="currentColor"
                                                                                        className="w-4 h-4"
                                                                                    >
                                                                                        <path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-12.15 12.15a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32L19.513 8.2z" />
                                                                                    </svg>
                                                                                    <h1 className="text-sm font-medium">
                                                                                        Update{" "}
                                                                                        {
                                                                                            FIRMFLOW_NAMES.CLIENT_SERVICE_SUBSCRIPTON_TASK
                                                                                        }
                                                                                    </h1>
                                                                                </button>
                                                                                <button
                                                                                    onClick={() => {
                                                                                        setForm(
                                                                                            {
                                                                                                subscription_id:
                                                                                                    params.id,
                                                                                                task_id:
                                                                                                    task
                                                                                                        .clientServiceSubscriptionTask
                                                                                                        .id,
                                                                                                description:
                                                                                                    "",
                                                                                            }
                                                                                        );
                                                                                        setCustomChecklistModalOpen(
                                                                                            true
                                                                                        );
                                                                                    }}
                                                                                    className="flex items-center space-x-1"
                                                                                >
                                                                                    <svg
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                        viewBox="0 0 24 24"
                                                                                        fill="currentColor"
                                                                                        className="cursor-pointer w-5 h-5"
                                                                                    >
                                                                                        <path
                                                                                            fillRule="evenodd"
                                                                                            d="M12 3.75a.75.75 0 01.75.75v6.75h6.75a.75.75 0 010 1.5h-6.75v6.75a.75.75 0 01-1.5 0v-6.75H4.5a.75.75 0 010-1.5h6.75V4.5a.75.75 0 01.75-.75z"
                                                                                            clipRule="evenodd"
                                                                                        />
                                                                                    </svg>
                                                                                    <h1 className="text-sm font-medium">
                                                                                        Add
                                                                                        item
                                                                                    </h1>
                                                                                </button>
                                                                            </div>
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                {/* Table body */}
                                                                <AnimatePresence mode="wait">
                                                                    {task.isOpen && (
                                                                        <tbody className="text-sm divide-y divide-slate-200">
                                                                            <tr className="text-xs font-semibold uppercase text-slate-500 bg-slate-50 border-t border-b border-slate-200">
                                                                                <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                                                                    <div className="font-semibold text-left">
                                                                                        Status
                                                                                    </div>
                                                                                    <div className="text-left">
                                                                                        <button
                                                                                            onClick={() =>
                                                                                                updateAllCheckListItems(
                                                                                                    task
                                                                                                        .clientServiceSubscriptionTask
                                                                                                        .id,
                                                                                                    true
                                                                                                )
                                                                                            }
                                                                                            className="btn bg-teal-500 hover:bg-teal-600 text-white h-10 my-2 mr-4"
                                                                                        >
                                                                                            Check
                                                                                            All
                                                                                        </button>
                                                                                        <button
                                                                                            onClick={() =>
                                                                                                updateAllCheckListItems(
                                                                                                    task
                                                                                                        .clientServiceSubscriptionTask
                                                                                                        .id,
                                                                                                    false
                                                                                                )
                                                                                            }
                                                                                            className="btn bg-red-500 hover:bg-red-600 text-white h-10 my-2 mr-4"
                                                                                        >
                                                                                            Uncheck
                                                                                            All
                                                                                        </button>
                                                                                    </div>
                                                                                </td>
                                                                                <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                                                                    <div className="font-semibold text-left">
                                                                                        Service
                                                                                    </div>
                                                                                </td>
                                                                                <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                                                                    <div className="font-semibold text-left">
                                                                                        Notes
                                                                                    </div>
                                                                                </td>
                                                                                <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                                                                    <div className="font-semibold text-left">
                                                                                        Links
                                                                                    </div>
                                                                                </td>
                                                                                <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                                                                    <div className="font-semibold text-right">
                                                                                        Action
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            {task.clientServiceSubscriptionTaskChecklistItems.map(
                                                                                (
                                                                                    item,
                                                                                    index
                                                                                ) => {
                                                                                    return (
                                                                                        <tr
                                                                                            key={
                                                                                                index +
                                                                                                item.description
                                                                                            }
                                                                                        >
                                                                                            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                                                                                {item.isChecked ? (
                                                                                                    <svg
                                                                                                        onClick={() =>
                                                                                                            tickChecklistItem(
                                                                                                                item
                                                                                                                    .clientServiceSubscriptionTask
                                                                                                                    .id,
                                                                                                                item
                                                                                                            )
                                                                                                        }
                                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                                        className="cursor-pointer h-5 w-5 text-teal-500"
                                                                                                        viewBox="0 0 20 20"
                                                                                                        fill="currentColor"
                                                                                                    >
                                                                                                        <path
                                                                                                            fillRule="evenodd"
                                                                                                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                                                                            clipRule="evenodd"
                                                                                                        />
                                                                                                    </svg>
                                                                                                ) : (
                                                                                                    <button
                                                                                                        onClick={() =>
                                                                                                            tickChecklistItem(
                                                                                                                item
                                                                                                                    .clientServiceSubscriptionTask
                                                                                                                    .id,
                                                                                                                item
                                                                                                            )
                                                                                                        }
                                                                                                        className="w-4 h-4 border border-gray-300 rounded-sm"
                                                                                                    ></button>
                                                                                                )}
                                                                                            </td>
                                                                                            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                                                                                <div className="flex items-center">
                                                                                                    <div className="font-medium text-slate-800">
                                                                                                        {
                                                                                                            item.description
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                            </td>
                                                                                            <td className="px-2 first:pl-5 last:pr-5 py-3">
                                                                                                {item.notes &&
                                                                                                    item.notes}
                                                                                            </td>
                                                                                            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                                                                                <ChecklistItemsList
                                                                                                    links={
                                                                                                        item.links
                                                                                                    }
                                                                                                />
                                                                                            </td>

                                                                                            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
                                                                                                {/* Menu button */}
                                                                                                <div className="w-full flex justify-end">
                                                                                                    <button
                                                                                                        onClick={() =>
                                                                                                            editService(
                                                                                                                item
                                                                                                            )
                                                                                                        }
                                                                                                        className="text-slate-400 hover:text-slate-500 rounded-full"
                                                                                                    >
                                                                                                        <span className="sr-only">
                                                                                                            Menu
                                                                                                        </span>
                                                                                                        <svg
                                                                                                            className="w-8 h-8 fill-current"
                                                                                                            viewBox="0 0 32 32"
                                                                                                        >
                                                                                                            <circle
                                                                                                                cx="16"
                                                                                                                cy="16"
                                                                                                                r="2"
                                                                                                            />
                                                                                                            <circle
                                                                                                                cx="10"
                                                                                                                cy="16"
                                                                                                                r="2"
                                                                                                            />
                                                                                                            <circle
                                                                                                                cx="22"
                                                                                                                cy="16"
                                                                                                                r="2"
                                                                                                            />
                                                                                                        </svg>
                                                                                                    </button>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                    );
                                                                                }
                                                                            )}
                                                                        </tbody>
                                                                    )}
                                                                </AnimatePresence>
                                                            </table>
                                                        </motion.div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </>
                                ) : (
                                    <div className="w-full mt-64 flex justify-center items-center max-w-9xl">
                                        <p className="m-6">Loading please wait </p>
                                        <motion.div
                                            className="border border-t-gray-700 border-t-[4px] rounded-full border-gray-200  w-12 h-12"
                                            initial={{ rotateZ: 0 }}
                                            animate={{ rotateZ: 360 }}
                                            exit={{ rotateZ: 0 }}
                                            transition={{
                                                repeat: Infinity,
                                                ease: "linear",
                                                duration: 1,
                                            }}
                                        ></motion.div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </section>
    );
}
export default EditTaskForm;
